<div class="p-8 pt-16" fxLayout="row" fxLayoutAlign="end end">
  <button
    mat-fab
    class="mat-warn"
    style="border-radius: 50%; background-color: green;"
    (click)="addTenant()"
    aria-label="Add rule"
    *fuseIfOnDom
    [@animate]="{ value: '*', params: { delay: '300ms', scale: '0.2' } }"
  >
    <mat-icon class="add-btn-icon">add</mat-icon>
  </button>
</div>

<mat-table
  #table
  [dataSource]="dataSource"
  *ngIf="dataSource.data.length !== 0"
>
  <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
    <mat-cell *matCellDef="let tenant">{{ tenant.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="primaryColor">
    <mat-header-cell *matHeaderCellDef>Primary Color</mat-header-cell>
    <mat-cell *matCellDef="let tenant">{{ tenant.primaryColor }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="secondaryColor">
    <mat-header-cell *matHeaderCellDef>Secondary Color</mat-header-cell>
    <mat-cell *matCellDef="let tenant">{{ tenant.secondaryColor }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
    <mat-cell *matCellDef="let tenant">
      <mat-icon
        (click)="navigateToTenantForm(tenant.id)"
        style="cursor: pointer;"
      >
        edit</mat-icon
      >
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<div class="no-data" *ngIf="dataSource.data.length === 0">
  No Tenants found!
</div>
