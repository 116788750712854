<div *ngIf="loaded">

  <h2>Helpseekers</h2>


  <div *ngIf="dataSource.data.length <= 0">
    <div class="center-vh">Es wurden noch keine Helpseeker hinzugefügt</div>
  </div>


  <mat-table #table [dataSource]="dataSource" *ngIf="dataSource.data.length > 0">
    <ng-container matColumnDef="firstname">
      <mat-header-cell *matHeaderCellDef>Vorname</mat-header-cell>
      <mat-cell *matCellDef="let helpseeker">{{
              helpseeker.firstname
            }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="lastname">
      <mat-header-cell *matHeaderCellDef>Nachname</mat-header-cell>
      <mat-cell *matCellDef="let helpseeker">{{
              helpseeker.lastname
            }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="username">
      <mat-header-cell *matHeaderCellDef>Username</mat-header-cell>
      <mat-cell *matCellDef="let helpseeker">{{
              helpseeker.username
            }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
      <mat-cell *matCellDef="let helpseeker">
        <!-- <mat-icon (click)="navigateToHelpSeekerForm(helpseeker.id)" style="cursor: pointer;">
          edit
        </mat-icon> -->
        <mat-icon (click)="removeHelpseeker(helpseeker)" style="cursor: pointer;">
          clear
        </mat-icon>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>

  <button mat-stroked-button type="button" class="primary-button-dark" (click)="addHelpseeker()">Hinzufügen</button>


</div>