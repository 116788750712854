"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.navigation_volunteer = [
    // {
    //   'id': 'digi',
    //   'title': 'Digitaler Freiwilligenpass',
    //   'type': 'group',
    //   // TODO own task page!
    //   // 'url': '/main/tasks/all',
    // },
    {
        id: "dashboard",
        title: "Mein FreiwilligenPASS",
        type: "item",
        icon: "dashboard2",
        url: "/main/dashboard",
    },
    {
        id: "achievements-management-summary",
        title: "Mein FreiwilligenÜBERBLICK",
        icon: "home",
        type: "item",
        url: "main/achievements/overview",
    },
    {
        id: "achievements",
        title: "Mein FreiwilligenLEBEN",
        type: "item",
        icon: "award2",
        url: "main/achievements/details",
    },
    // {
    //   id: "get-engaged",
    //   title: "Meine FreiwilligenMÖGLICHKEITEN",
    //   type: "item",
    //   icon: "opportunities",
    //   url: "main/opportunities"
    // },
    {
        id: "get-connected",
        title: "Mein FreiwilligenNETZWERK",
        type: "item",
        icon: "people",
        url: "/main/get-connected",
    },
];
