"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var login_service_1 = require("../_service/login.service");
var user_1 = require("../_model/user");
var i0 = require("@angular/core");
var i1 = require("../_service/login.service");
var RecruiterGuard = /** @class */ (function () {
    function RecruiterGuard(loginService) {
        this.loginService = loginService;
    }
    RecruiterGuard.prototype.canActivate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.loginService
                .getLoggedInUserRole()
                .toPromise()
                .then(function (role) { return resolve(role == user_1.UserRole.RECRUITER); });
        });
    };
    RecruiterGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RecruiterGuard_Factory() { return new RecruiterGuard(i0.ɵɵinject(i1.LoginService)); }, token: RecruiterGuard, providedIn: "root" });
    return RecruiterGuard;
}());
exports.RecruiterGuard = RecruiterGuard;
