"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".sunburstParent[_ngcontent-%COMP%]{width:67%}.tasksTable[_ngcontent-%COMP%]{width:33%}.right[_ngcontent-%COMP%]{text-align:right}.mat-column-name[_ngcontent-%COMP%]{word-wrap:break-word!important;white-space:unset!important;flex:0 0 50%!important;width:50%!important;overflow-wrap:break-word;word-wrap:break-word;word-break:break-word;-ms-hyphens:auto;-webkit-hyphens:auto;hyphens:auto}.mat-column-dateFrom[_ngcontent-%COMP%]{word-wrap:break-word!important;white-space:unset!important;flex:0 0 30%!important;width:30%!important;overflow-wrap:break-word;word-wrap:break-word;word-break:break-word;-ms-hyphens:auto;-webkit-hyphens:auto;hyphens:auto}.mat-column-duration[_ngcontent-%COMP%]{word-wrap:break-word!important;white-space:unset!important;flex:0 0 20%!important;width:20%!important;overflow-wrap:break-word;word-wrap:break-word;word-break:break-word;-ms-hyphens:auto;-webkit-hyphens:auto;hyphens:auto}mat-row[_ngcontent-%COMP%]{cursor:pointer}mat-row[_ngcontent-%COMP%]:hover{opacity:.8}mat-header-cell[_ngcontent-%COMP%]{font-size:18px}mat-cell[_ngcontent-%COMP%]{font-size:14px}  .highcharts-drillup-button{display:none!important}"];
exports.styles = styles;
