"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dialog_1 = require("@angular/material/dialog");
var FuseConfirmDialogComponent = /** @class */ (function () {
    function FuseConfirmDialogComponent(dialogRef) {
        this.dialogRef = dialogRef;
    }
    return FuseConfirmDialogComponent;
}());
exports.FuseConfirmDialogComponent = FuseConfirmDialogComponent;
