"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var login_service_1 = require("app/main/content/_service/login.service");
var forms_1 = require("@angular/forms");
var class_definition_service_1 = require("app/main/content/_service/meta/core/class/class-definition.service");
var user_1 = require("app/main/content/_model/user");
var class_instance_service_1 = require("app/main/content/_service/meta/core/class/class-instance.service");
var core_tenant_service_1 = require("app/main/content/_service/core-tenant.service");
var core_user_service_1 = require("app/main/content/_service/core-user.service");
var ImportComponent = /** @class */ (function () {
    function ImportComponent(loginService, formBuilder, coreUserService, classInstanceService, classDefinitionService, tenantService) {
        this.loginService = loginService;
        this.formBuilder = formBuilder;
        this.coreUserService = coreUserService;
        this.classInstanceService = classInstanceService;
        this.classDefinitionService = classDefinitionService;
        this.tenantService = tenantService;
        this.classDefinitions = [];
        this.volunteers = [];
        this.inputMissingError = false;
        this.displaySuccessMessage = false;
        this.importForm = formBuilder.group({
            volunteer: new forms_1.FormControl(undefined, forms_1.Validators.required),
            file: new forms_1.FormControl(undefined, forms_1.Validators.required),
        });
    }
    ImportComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var globalInfo, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.loginService.getGlobalInfo().toPromise()];
                    case 1:
                        globalInfo = (_c.sent());
                        this.marketplace = globalInfo.marketplace;
                        this.user = globalInfo.user;
                        this.tenant = globalInfo.tenants[0];
                        _a = this;
                        return [4 /*yield*/, this.classDefinitionService
                                .getAllClassDefinitions(this.marketplace, this.tenant.id)
                                .toPromise()];
                    case 2:
                        _a.classDefinitions = (_c.sent());
                        _b = this;
                        return [4 /*yield*/, this.coreUserService
                                .findAllByRoleAndTenantId(this.tenant.id, user_1.UserRole.VOLUNTEER)
                                .toPromise()];
                    case 3:
                        _b.volunteers = (_c.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    ImportComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var fileReader_1;
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.importForm.valid) {
                    this.inputMissingError = true;
                }
                else {
                    this.inputMissingError = false;
                    fileReader_1 = new FileReader();
                    fileReader_1.onload = function (e) { return __awaiter(_this, void 0, void 0, function () {
                        var contentObject, cd;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    contentObject = JSON.parse(fileReader_1.result);
                                    return [4 /*yield*/, this.classDefinitionService
                                            .getClassDefinitionById(this.marketplace, contentObject.classDefinitionId, contentObject.tenantId)
                                            .toPromise()];
                                case 1:
                                    cd = (_a.sent());
                                    if (cd) {
                                        this.import(contentObject);
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    fileReader_1.readAsText(this.importForm.value.file.files[0]);
                }
                return [2 /*return*/];
            });
        });
    };
    ImportComponent.prototype.import = function (contentObject) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, entry, e_1_1;
            var e_1, _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        this.successImportCount = contentObject.properties.length;
                        this.displaySuccessMessage = true;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 6, 7, 8]);
                        _a = __values(contentObject.properties), _b = _a.next();
                        _d.label = 2;
                    case 2:
                        if (!!_b.done) return [3 /*break*/, 5];
                        entry = _b.value;
                        return [4 /*yield*/, this.classInstanceService
                                .createClassInstanceByClassDefinitionId(this.marketplace, contentObject.classDefinitionId, this.importForm.value.volunteer.id, contentObject.tenantId, entry)
                                .toPromise()];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4:
                        _b = _a.next();
                        return [3 /*break*/, 2];
                    case 5: return [3 /*break*/, 8];
                    case 6:
                        e_1_1 = _d.sent();
                        e_1 = { error: e_1_1 };
                        return [3 /*break*/, 8];
                    case 7:
                        try {
                            if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                        }
                        finally { if (e_1) throw e_1.error; }
                        return [7 /*endfinally*/];
                    case 8:
                        setTimeout(function () { return (_this.displaySuccessMessage = false); }, 5000);
                        return [2 /*return*/];
                }
            });
        });
    };
    return ImportComponent;
}());
exports.ImportComponent = ImportComponent;
