"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var config_service_1 = require("@fuse/services/config.service");
var router_1 = require("@angular/router");
var activation_service_1 = require("app/main/content/_service/activation.service");
var util_1 = require("util");
var activation_texts_1 = require("./activation-texts");
var user_1 = require("app/main/content/_model/user");
var ActivationComponent = /** @class */ (function () {
    function ActivationComponent(fuseConfig, router, route, activationService) {
        this.fuseConfig = fuseConfig;
        this.router = router;
        this.route = route;
        this.activationService = activationService;
    }
    ActivationComponent.prototype.ngOnInit = function () {
        var _this = this;
        var layout = {
            navigation: 'none',
            toolbar: 'none',
            footer: 'none',
        };
        this.fuseConfig.setConfig({ layout: layout });
        this.route.params.subscribe(function (param) {
            _this.activationId = param['activationId'];
        });
        this.route.queryParams.subscribe(function (param) {
            _this.username = param['username'];
            _this.emailAddress = param['email'];
            _this.accountType = param['type'];
            console.log(param);
        });
        if (util_1.isNullOrUndefined(this.accountType)) {
            this.accountType = user_1.AccountType.ORGANIZATION;
        }
        this.activationTexts = this.accountType === user_1.AccountType.PERSON ? activation_texts_1.personTexts : activation_texts_1.organizationTexts;
        if (!util_1.isNullOrUndefined(this.activationId)) {
            this.activationService.activate(this.activationId).toPromise().then(function (ret) {
                if (!util_1.isNullOrUndefined(ret)) {
                    _this.response = ret;
                    _this.loaded = true;
                }
            });
        }
    };
    ActivationComponent.prototype.handleBackClick = function () {
        window.history.back();
    };
    return ActivationComponent;
}());
exports.ActivationComponent = ActivationComponent;
