"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var StoredChartService = /** @class */ (function () {
    function StoredChartService(http) {
        this.http = http;
    }
    StoredChartService.prototype.findById = function (marketplace, id) {
        return this.http.get(marketplace.url + "/chart/id/" + id);
    };
    StoredChartService.prototype.findByTitle = function (marketplace, title) {
        return this.http.get(marketplace.url + "/chart/title/" + title);
    };
    StoredChartService.prototype.findAll = function (marketplace) {
        return this.http.get(marketplace.url + "/chart");
    };
    StoredChartService.prototype.save = function (marketplace, storedChart) {
        return this.http.post(marketplace.url + "/chart", storedChart);
    };
    StoredChartService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StoredChartService_Factory() { return new StoredChartService(i0.ɵɵinject(i1.HttpClient)); }, token: StoredChartService, providedIn: "root" });
    return StoredChartService;
}());
exports.StoredChartService = StoredChartService;
