"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var core_tenant_service_1 = require("app/main/content/_service/core-tenant.service");
var tenant_1 = require("app/main/content/_model/tenant");
var util_1 = require("util");
var login_service_1 = require("app/main/content/_service/login.service");
var role_change_service_1 = require("app/main/content/_service/role-change.service");
var http_1 = require("@angular/common/http");
var file_service_1 = require("app/main/content/_service/file.service");
var TenantFormContentComponent = /** @class */ (function () {
    function TenantFormContentComponent(formBuilder, tenantService, loginService, fileService, roleChangeService) {
        this.formBuilder = formBuilder;
        this.tenantService = tenantService;
        this.loginService = loginService;
        this.fileService = fileService;
        this.roleChangeService = roleChangeService;
        this.tenantSaved = new core_1.EventEmitter();
    }
    TenantFormContentComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loaded = false;
                        this.previewProfileImageDirty = false;
                        this.landingPageImageDirty = false;
                        this.showProfileImageForm = false;
                        this.showLandingPageImageForm = false;
                        this.addedTags = [];
                        _a = this;
                        return [4 /*yield*/, this.loginService.getGlobalInfo().toPromise()];
                    case 1:
                        _a.globalInfo = (_b.sent());
                        return [4 /*yield*/, this.initializeTenantForm(this.tenant)];
                    case 2:
                        _b.sent();
                        this.loaded = true;
                        setTimeout(function () {
                            _this.showProfileImageForm = true;
                            _this.showLandingPageImageForm = true;
                        }, 100);
                        return [2 /*return*/];
                }
            });
        });
    };
    TenantFormContentComponent.prototype.initializeTenantForm = function (tenant) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, key;
            var e_1, _c;
            return __generator(this, function (_d) {
                if (util_1.isNullOrUndefined(tenant)) {
                    return [2 /*return*/];
                }
                tenant.landingpageMessage = this.patchLandingPageMessage(tenant.landingpageMessage);
                this.tenantForm = this.formBuilder.group({
                    name: new forms_1.FormControl("", forms_1.Validators.required),
                    description: new forms_1.FormControl("", forms_1.Validators.required),
                    homepage: new forms_1.FormControl(""),
                    primaryColor: new forms_1.FormControl("", forms_1.Validators.required),
                    secondaryColor: new forms_1.FormControl("", forms_1.Validators.required),
                    landingpageMessage: new forms_1.FormControl(""),
                    landingpageTitle: new forms_1.FormControl(""),
                    landingpageText: new forms_1.FormControl("")
                });
                try {
                    for (_a = __values(Object.keys(tenant)), _b = _a.next(); !_b.done; _b = _a.next()) {
                        key = _b.value;
                        if (!util_1.isNullOrUndefined(this.tenantForm.controls[key])) {
                            this.tenantForm.controls[key].setValue(tenant[key]);
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
                console.log(this.tenantForm.value);
                this.previewProfileImage = this.tenant.imagePath;
                this.addedTags = this.tenant.tags;
                return [2 /*return*/];
            });
        });
    };
    TenantFormContentComponent.prototype.patchLandingPageMessage = function (message) {
        if (util_1.isNullOrUndefined(message)) {
            message = "Herzlich Willkommen bei iVolunteer";
        }
        return message;
    };
    TenantFormContentComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tenantId, profileImage, landingPageImage;
            var _this = this;
            return __generator(this, function (_a) {
                if (!this.tenantForm.valid) {
                    return [2 /*return*/];
                }
                this.tenantForm.disable();
                tenantId = this.tenant.id;
                this.tenant = new tenant_1.Tenant(this.tenantForm.value);
                this.tenant.id = tenantId;
                this.tenant.marketplaceId = this.globalInfo.marketplace.id;
                this.tenant.landingpageMessage = this.patchLandingPageMessage(this.tenant.landingpageMessage);
                profileImage = this.assignCurrentImage(this.previewProfileImage, this.previewProfileImageDirty, 
                // oldProfileImage
                "");
                this.tenant.imagePath = profileImage;
                landingPageImage = this.assignCurrentImage(this.landingPageImage, this.landingPageImageDirty, 
                // oldLandingPageImage
                "");
                this.tenant.landingpageImagePath = landingPageImage;
                this.tenant.tags = this.addedTags;
                console.log(this.tenant);
                if (util_1.isNullOrUndefined(this.tenant.id)) {
                    this.tenantService
                        .createTenant(this.tenant)
                        .toPromise()
                        .then(function (response) {
                        _this.tenant.id = response.id;
                        _this.loginService
                            .generateGlobalInfo(_this.globalInfo.userRole, _this.globalInfo.tenants.map(function (t) { return t.id; }))
                            .then(function () {
                            _this.tenantForm.enable();
                            _this.roleChangeService.update();
                            _this.tenantSaved.emit(_this.tenant);
                            _this.ngOnInit();
                        });
                    })
                        .catch(function (error) {
                        console.error(error);
                    });
                }
                else {
                    this.tenantService
                        .updateTenant(this.tenant)
                        .toPromise()
                        .then(function () {
                        _this.loginService
                            .generateGlobalInfo(_this.globalInfo.userRole, _this.globalInfo.tenants.map(function (t) { return t.id; }))
                            .then(function () {
                            _this.tenantForm.enable();
                            _this.roleChangeService.update();
                            _this.tenantSaved.emit(_this.tenant);
                            _this.ngOnInit();
                        });
                    })
                        .catch(function (error) {
                        console.error(error);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    TenantFormContentComponent.prototype.assignCurrentImage = function (newImage, imageDirty, originalImage) {
        if (!imageDirty) {
            return originalImage;
        }
        return newImage;
    };
    TenantFormContentComponent.prototype.handleProfileImageUploadEvent = function (event) {
        this.previewProfileImageDirty = true;
        this.previewProfileImage = event.url;
    };
    TenantFormContentComponent.prototype.handleLandingPageImageUploadEvent = function (event) {
        this.landingPageImageDirty = true;
        this.landingPageImage = event.url;
    };
    return TenantFormContentComponent;
}());
exports.TenantFormContentComponent = TenantFormContentComponent;
