"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".org-card[_ngcontent-%COMP%]{width:300px!important;max-width:300px;margin:10px}.org-card-img[_ngcontent-%COMP%]{max-height:150px;margin:auto;padding:10px 0}.org-name[_ngcontent-%COMP%]{text-align:center;font-size:16px!important}.subscribeBtn[_ngcontent-%COMP%]{margin-top:5px;display:block;width:212px}"];
exports.styles = styles;
