"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var login_service_1 = require("../_service/login.service");
var user_1 = require("../_model/user");
var i0 = require("@angular/core");
var i1 = require("../_service/login.service");
var VolunteerGuard = /** @class */ (function () {
    function VolunteerGuard(loginService) {
        this.loginService = loginService;
    }
    VolunteerGuard.prototype.canActivate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.loginService
                .getLoggedInUserRole()
                .toPromise()
                .then(function (role) { return resolve(role === user_1.UserRole.VOLUNTEER); });
        });
    };
    VolunteerGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VolunteerGuard_Factory() { return new VolunteerGuard(i0.ɵɵinject(i1.LoginService)); }, token: VolunteerGuard, providedIn: "root" });
    return VolunteerGuard;
}());
exports.VolunteerGuard = VolunteerGuard;
