"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var login_service_1 = require("../_service/login.service");
var user_1 = require("../_model/user");
var i0 = require("@angular/core");
var i1 = require("../_service/login.service");
var FlexProdOrTenantAdminGuard = /** @class */ (function () {
    function FlexProdOrTenantAdminGuard(loginService) {
        this.loginService = loginService;
    }
    FlexProdOrTenantAdminGuard.prototype.canActivate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.loginService
                .getLoggedInUserRole()
                .toPromise()
                .then(function (role) {
                return resolve(role == user_1.UserRole.FLEXPROD || role == user_1.UserRole.TENANT_ADMIN);
            });
        });
    };
    FlexProdOrTenantAdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FlexProdOrTenantAdminGuard_Factory() { return new FlexProdOrTenantAdminGuard(i0.ɵɵinject(i1.LoginService)); }, token: FlexProdOrTenantAdminGuard, providedIn: "root" });
    return FlexProdOrTenantAdminGuard;
}());
exports.FlexProdOrTenantAdminGuard = FlexProdOrTenantAdminGuard;
