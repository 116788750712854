"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var i0 = require("@angular/core");
var MessageService = /** @class */ (function () {
    function MessageService() {
        this.handler = new rxjs_1.Subject();
    }
    MessageService.prototype.broadcast = function (type, payload) {
        this.handler.next({ type: type, payload: payload });
    };
    MessageService.prototype.subscribe = function (type, callback) {
        return this.handler.pipe(operators_1.filter(function (message) { return message.type === type; }), operators_1.map(function (message) { return message.payload; })).subscribe(callback);
    };
    MessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MessageService_Factory() { return new MessageService(); }, token: MessageService, providedIn: "root" });
    return MessageService;
}());
exports.MessageService = MessageService;
