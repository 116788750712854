"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var LocalRepositoryService = /** @class */ (function () {
    function LocalRepositoryService() {
    }
    LocalRepositoryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalRepositoryService_Factory() { return new LocalRepositoryService(); }, token: LocalRepositoryService, providedIn: "root" });
    return LocalRepositoryService;
}());
exports.LocalRepositoryService = LocalRepositoryService;
