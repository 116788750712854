<mat-card style="margin: 20px" *ngIf="loaded">
  <mat-card-title> Stammdaten </mat-card-title>
  <mat-card-content>
    <form [formGroup]="profileForm" novalidate fxLayout="column" style="width: 100%">
      <div class="address-label">Zu deiner Person</div>
      <fieldset class="section-border">
        <div fxLayout="row wrap">
          <mat-form-field fxFlex style="width: 90px">
            <mat-select placeholder="Anrede" formControlName="formOfAddress">
              <mat-option value="Frau">Frau</mat-option>
              <mat-option value="Herr">Herr</mat-option>
            </mat-select>

            <mat-error *ngIf="profileForm.get('formOfAddress').invalid">
              {{ showErrorMessage(profileForm.get("formOfAddress"), "formOfAddress") }}
            </mat-error>
          </mat-form-field>

          <div fxFlex></div>
        </div>

        <div fxLayout="row wrap">
          <mat-form-field fxFlex style="width: 100px">
            <input matInput placeholder="Vorgestellter Titel" formControlName="titleBefore" />
            <mat-error *ngIf="profileForm.get('titleBefore').invalid">
              {{ showErrorMessage(profileForm.get("titleBefore"), "titleBefore") }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex style="width: 100px" class="ml-4">
            <input matInput placeholder="Nachgestellter Titel" formControlName="titleAfter" />
            <mat-error *ngIf="profileForm.get('titleAfter').invalid">
              {{ showErrorMessage(profileForm.get("titleAfter"), "titleAfter") }}
            </mat-error>
          </mat-form-field>

          <div fxFlex></div>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field fxFlex>
            <input matInput placeholder="Vorname" formControlName="firstname" />
            <mat-error *ngIf="profileForm.get('firstname').invalid">
              {{ showErrorMessage(profileForm.get("firstname"), "firstname") }}
            </mat-error>
          </mat-form-field>

          <mat-form-field fxFlex class="ml-4">
            <input matInput placeholder="Nachname" formControlName="lastname" />
            <mat-error *ngIf="profileForm.get('lastname').invalid">
              {{ showErrorMessage(profileForm.get("lastname"), "lastname") }}
            </mat-error>
          </mat-form-field>
        </div>

        <mat-form-field fxFlex>
          <input matInput placeholder="Spitzname" formControlName="nickname" />
          <mat-error *ngIf="profileForm.get('nickname').invalid">
            {{ showErrorMessage(profileForm.get("nickname"), "nickname") }}
          </mat-error>
        </mat-form-field>

        <mat-form-field fxFlex style="max-width: 200px">
          <mat-label>Geburtsdatum</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="birthday" [max]="today" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="profileForm.get('birthday').invalid">
            {{ showErrorMessage(profileForm.get("birthday"), "birthday") }}
          </mat-error>
        </mat-form-field>

        <div fxFlex>
          <mat-form-field fxFlex>
            <textarea matInput placeholder="Erzähle über dich" formControlName="about" matTextareaAutosize
              matAutosizeMinRows=5 matAutosizeMaxRows=10></textarea>
          </mat-form-field>
        </div>
      </fieldset>

      <div class="address-label">Adresse</div>
      <fieldset formGroupName="address" class="section-border">
        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field fxFlex class="ml-4">
            <input matInput placeholder="Straße" formControlName="street" />
          </mat-form-field>
          <mat-form-field class="ml-4" style="width: 50px">
            <input matInput placeholder="Nr" formControlName="houseNumber" />
          </mat-form-field>
        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center">
          <mat-form-field class="ml-4" style="width: 100px">
            <input matInput placeholder="PLZ" formControlName="postcode" />
          </mat-form-field>
          <mat-form-field fxFlex class="ml-4">
            <input matInput placeholder="Stadt" formControlName="city" />
          </mat-form-field>
        </div>

        <mat-form-field fxFlex class="ml-4">
          <input matInput placeholder="Land" formControlName="country" value="Österreich" />
        </mat-form-field>
      </fieldset>

      <mat-accordion multi="true">

        <mat-expansion-panel>
          <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
            <mat-panel-title>Kontaktmöglichkeiten</mat-panel-title>
          </mat-expansion-panel-header>

          <div class="m-12 mt-0">
            <div class="address-label">Telefon</div>
            <fieldset class="section-border">
              <mat-form-field fxFlex>
                <input matInput placeholder="Telefonnummer" formControlName="phoneNumber1" />
              </mat-form-field>
              <mat-form-field fxFlex>
                <input matInput placeholder="Weitere Telefonnummer" formControlName="phoneNumber2" />
              </mat-form-field>
              <mat-form-field fxFlex>
                <input matInput placeholder="Weitere Telefonnummer" formControlName="phoneNumber3" />
              </mat-form-field>
            </fieldset>

            <div class="address-label">Webseiten</div>
            <fieldset class="section-border">
              <mat-form-field fxFlex>
                <input matInput placeholder="Webseite" formControlName="website1" />
              </mat-form-field>
              <mat-form-field fxFlex>
                <input matInput placeholder="Weitere Webseite" formControlName="website2" />
              </mat-form-field>
              <mat-form-field fxFlex>
                <input matInput placeholder="Weitere Webseite" formControlName="website3" />
              </mat-form-field>
            </fieldset>

            <div class="address-label">E-Mail-Adressen</div>
            <fieldset class="section-border">
              <mat-form-field fxFlex>
                <input matInput placeholder="Primäre E-Mail-Adresse" formControlName="loginEmail" />
              </mat-form-field>

              <mat-form-field fxFlex>
                <input matInput placeholder="Weitere E-Mail-Adresse" formControlName="email2" />
              </mat-form-field>

              <mat-form-field fxFlex>
                <input matInput placeholder="Weitere E-Mail-Adresse" formControlName="email3" />
              </mat-form-field>
            </fieldset>


          </div>
        </mat-expansion-panel>

        <mat-expansion-panel class="mt-12">
          <mat-expansion-panel-header expandedHeight="40px" collapsedHeight="40px">
            <mat-panel-title> Timeslots </mat-panel-title>
          </mat-expansion-panel-header>

          <fieldset class="section-border">
            <div formArrayName="timeslots" *ngFor=" let control of profileForm.get('timeslots').controls; let i = index" class="m-20">
              <mat-error *ngIf="control.invalid">
                Bitte korrektes Uhrzeitformat verwenden: 24h (H)H:MM
              </mat-error>
              <div [formGroupName]="i">
                <div class="allowed-value-row" fxLayout="row wrap" fxLayoutAlign="start center">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="handleTimeslotCheckboxClicked(i, 'active')" [checked]="control.value.active" class="m-4">
                  </mat-checkbox>

                  <div class="weekday-label">
                    {{ getWeekdayLabel(control.value.weekday) }}
                  </div>

                  <div class="time-container">
                    <mat-form-field class="time-field ml-4">
                      <input matInput placeholder="Von" formControlName="from1" />
                      <mat-error *ngIf="control.get('from1').invalid">
                        {{ showErrorMessage(control.get("from1"), "from1") }}
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="time-field ml-4">
                      <input matInput placeholder="Bis" formControlName="to1" />
                      <mat-error *ngIf="control.get('to1').invalid">
                        {{ showErrorMessage(control.get("to1"), "to1") }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="time-mid-label">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="
                        handleTimeslotCheckboxClicked(i, 'secondActive')
                      " [checked]="control.value.secondActive" labelPosition="before" [disabled]="!control.value.active">
                      <div *ngIf="control.value.secondActive" class="und-label">
                        und
                      </div>
                      <div *ngIf="!control.value.secondActive" class="und-label">
                        und?
                      </div>
                    </mat-checkbox>
                  </div>

                  <div class="time-container" *ngIf="control.value.secondActive">
                    <mat-form-field class="time-field ml-4">
                      <input matInput placeholder="Von" formControlName="from2" />
                      <mat-error *ngIf="control.get('from2').invalid">
                        {{ showErrorMessage(control.get("from2"), "from2") }}
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field class="time-field ml-4">
                      <input matInput placeholder="Bis" formControlName="to2" />
                      <mat-error *ngIf="control.get('to2').invalid">
                        {{ showErrorMessage(control.get("to2"), "to2") }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div *ngIf="i < 6" class="pt-12 divider-100-20"></div>
                </div>
              </div>
            </div>
          </fieldset>
        </mat-expansion-panel>
      </mat-accordion>

      <button type="button" mat-stroked-button mat-raised-button color="primary" (click)="save()" aria-label="Speichern" [disabled]="profileForm.invalid">
        <div *ngIf="!saveSuccessful">Speichern</div>
        <div *ngIf="saveSuccessful" fxLayout="row" fxLayoutAlign="center center">
          <mat-icon>done</mat-icon>
          <div>Gespeichert</div>
        </div>
      </button>
    </form>
  </mat-card-content>
</mat-card>