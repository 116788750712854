<div *ngIf="user">
  <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
    <div fxLayout="row" fxLayoutAlign="center center">
      <span class="username mr-12" fxHide fxShow.gt-sm>{{
        getUserNameString()
      }}</span>
      <img class="avatar" [src]="getImage()" />
      <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
    </div>
  </button>

  <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
    <button *ngIf="role == 'VOLUNTEER' || role == 'HELP_SEEKER'" mat-menu-item routerLink="/main/dashboard/tenants">
      <mat-icon>account_balance</mat-icon>
      <span>Organisationen verwalten</span>
    </button>

    <button mat-menu-item routerLink="/main/profile">
      <mat-icon>account_circle</mat-icon>
      <span>Mein Profil</span>
    </button>

    <button *ngIf="role == 'TENANT_ADMIN'" mat-menu-item (click)="navigateToTenantEditForm()">
      <mat-icon>account_circle</mat-icon>
      <span>Organisation bearbeiten</span>
    </button>

    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Abmelden</span>
    </button>
  </mat-menu>
</div>