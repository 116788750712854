"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".timeline[_ngcontent-%COMP%]{height:550px;padding:1rem}.filterButton[_ngcontent-%COMP%]{width:180px;font-size:20px;margin:5px 10px 20px 70px}.filterChip[_ngcontent-%COMP%]{margin-top:8px;font-size:20px}.yearForm[_ngcontent-%COMP%]{min-width:90px}"];
exports.styles = styles;
