<mat-sidenav-container>
  <div id="fuse-main-content">
    <!-- TOOLBAR: Above -->
    <ng-container *ngIf="fuseSettings.layout.toolbar === 'above'">
      <fuse-toolbar class="above" [ngClass]="fuseSettings.colorClasses.toolbar"></fuse-toolbar>
    </ng-container>
    <!-- / TOOLBAR: Above -->

    <!-- NAVBAR: Top -->
    <fuse-navbar layout="horizontal" class="top-navbar" fxHide fxShow.gt-md [ngClass]="fuseSettings.colorClasses.navbar"
      *ngIf="fuseSettings.layout.navigation === 'top'">
    </fuse-navbar>
    <!-- / NAVBAR: Top -->

    <div id="wrapper">
      <!-- NAVBAR: Left -->
      <fuse-sidebar [name]="'navbar'" [folded]="fuseSettings.layout.navigationFolded" [lockedOpen]="'gt-md'"
        class="left-navbar" [ngClass]="fuseSettings.colorClasses.navbar" *ngIf="
          (fuseSettings.layout.navigation === 'left' ||
            fuseSettings.layout.navigation === 'top') &&
          role !== 'NONE'
        ">
        <fuse-navbar layout="vertical"></fuse-navbar>
      </fuse-sidebar>
      <!-- / NAVBAR: Left -->

      <div class="content-wrapper">
        <!-- TOOLBAR: Below -->
        <ng-container *ngIf="fuseSettings.layout.toolbar === 'below'">
          <fuse-toolbar class="below" [ngClass]="fuseSettings.colorClasses.toolbar"></fuse-toolbar>
        </ng-container>
        <!-- / TOOLBAR: Below -->

        <fuse-content></fuse-content>

        <!-- FOOTER: Below -->
        <ng-container *ngIf="fuseSettings.layout.footer === 'below'">
          <fuse-footer class="below" [ngClass]="fuseSettings.colorClasses.footer"></fuse-footer>
        </ng-container>
        <!-- / FOOTER: Below -->
      </div>

      <!-- NAVBAR: Right -->
      <fuse-sidebar [name]="'navbar'" [align]="'right'" [folded]="fuseSettings.layout.navigationFolded"
        [lockedOpen]="'gt-md'" class="right-navbar" [ngClass]="fuseSettings.colorClasses.navbar"
        *ngIf="fuseSettings.layout.navigation === 'right'">
        <fuse-navbar layout="vertical"></fuse-navbar>
      </fuse-sidebar>
      <!-- / NAVBAR: Right -->
    </div>

    <!-- FOOTER: Above -->
    <ng-container *ngIf="fuseSettings.layout.footer === 'above'">
      <fuse-footer class="above" [ngClass]="fuseSettings.colorClasses.footer"></fuse-footer>
    </ng-container>
    <!-- FOOTER: Above -->
  </div>
</mat-sidenav-container>