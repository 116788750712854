"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var login_service_1 = require("../../content/_service/login.service");
var router_1 = require("@angular/router");
var role_change_service_1 = require("app/main/content/_service/role-change.service");
var core_user_service_1 = require("app/main/content/_service/core-user.service");
var FuseUserMenuComponent = /** @class */ (function () {
    function FuseUserMenuComponent(router, loginService, roleChangeService, userService) {
        var _this = this;
        this.router = router;
        this.loginService = loginService;
        this.roleChangeService = roleChangeService;
        this.userService = userService;
        this.onRoleChanged = this.roleChangeService.onRoleChanged.subscribe(function () {
            _this.ngOnInit();
        });
    }
    FuseUserMenuComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loginService.getGlobalInfo().toPromise()];
                    case 1:
                        (_a.globalInfo = (_e.sent())),
                            (this.user = this.globalInfo.user);
                        _c = (_b = Promise).all;
                        _d = this;
                        return [4 /*yield*/, this.loginService.getLoggedInUserRole().toPromise()];
                    case 2: return [4 /*yield*/, _c.apply(_b, [[
                                (_d.role = (_e.sent()))
                            ]])];
                    case 3:
                        _e.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    FuseUserMenuComponent.prototype.ngOnDestroy = function () {
        this.onRoleChanged.unsubscribe();
    };
    FuseUserMenuComponent.prototype.logout = function () {
        this.loginService.logout();
    };
    FuseUserMenuComponent.prototype.getImage = function () {
        return this.userService.getUserProfileImage(this.user);
    };
    FuseUserMenuComponent.prototype.getUserNameString = function () {
        var ret = "";
        if (this.user && this.user.firstname && this.user.lastname) {
            ret += this.user.firstname + " " + this.user.lastname;
        }
        else {
            ret += this.user.username;
        }
        return ret;
    };
    FuseUserMenuComponent.prototype.navigateToTenantEditForm = function () {
        this.router.navigate(["/main/edit-tenant/" + this.globalInfo.tenants[0].id], {
            queryParams: { marketplaceId: this.globalInfo.marketplace.id }
        });
    };
    return FuseUserMenuComponent;
}());
exports.FuseUserMenuComponent = FuseUserMenuComponent;
