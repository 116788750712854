"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var user_1 = require("../_model/user");
var i0 = require("@angular/core");
var RoleChangeService = /** @class */ (function () {
    function RoleChangeService() {
        this.onRoleChanged = new rxjs_1.BehaviorSubject(user_1.UserRole.NONE);
        this.onUpdate = new rxjs_1.Subject();
    }
    RoleChangeService.prototype.changeRole = function (role) {
        this.onRoleChanged.next(role);
    };
    RoleChangeService.prototype.update = function () {
        this.onUpdate.next();
    };
    RoleChangeService.prototype.getRoleTenantMappings = function (user) {
        var map = [];
        var volunteerSubs = user.subscribedTenants.filter(function (s) {
            return s.role === user_1.UserRole.VOLUNTEER;
        });
        if (volunteerSubs.length > 0) {
            var data = new user_1.RoleTenantMapping();
            data.role = volunteerSubs[0].role;
            data.tenantIds = volunteerSubs.map(function (s) { return s.tenantId; });
            map.push(data);
        }
        user.subscribedTenants
            .filter(function (s) {
            return s.role != user_1.UserRole.VOLUNTEER;
        })
            .forEach(function (s) {
            var data = new user_1.RoleTenantMapping();
            data.role = s.role;
            data.tenantIds = Array.of(s.tenantId);
            map.push(data);
        });
        return map;
    };
    RoleChangeService.prototype.getRoleNameString = function (role) {
        switch (role) {
            case user_1.UserRole.VOLUNTEER:
                return "Freiwillige(r)";
            case user_1.UserRole.HELP_SEEKER:
                return "Hilfesuchende(r)";
            case user_1.UserRole.RECRUITER:
                return "Recruiter";
            case user_1.UserRole.FLEXPROD:
                return "Flexprod";
            case user_1.UserRole.ADMIN:
                return "Admin";
            case user_1.UserRole.TENANT_ADMIN:
                return "Organisations-Admin";
        }
    };
    RoleChangeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RoleChangeService_Factory() { return new RoleChangeService(); }, token: RoleChangeService, providedIn: "root" });
    return RoleChangeService;
}());
exports.RoleChangeService = RoleChangeService;
