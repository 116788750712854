<div *ngIf="isLoaded" class="page-layout blank p-24" fusePerfectScrollbar style="overflow: scroll;">
  <div style="padding-top: 12px;"></div>

  <div class="header mat-accent-bg p-16 p-sm-24" fxLayout="row" fxLayoutAlign="space-between center">
    <mat-icon *ngIf="user.subscribedTenants.length != 0" class="iconButton" (click)="navigateBack()" style="margin-left: 20px; color: white;">keyboard_return
    </mat-icon>

    <h1>Organisationen</h1>
  </div>

  <p *ngIf="user.subscribedTenants.length == 0" class="alert alert-info">
    <i class="fas fa-exclamation-circle"></i> &nbsp; Du bist noch keiner Organisation beigetreten!
  </p>

  <div fxLayout="row wrap" fxLayoutAlign="space-around stretch">
    <mat-card *ngFor="let t of allTenants" class="org-card p-10">
      <div style="height: 160px;">
        <img mat-card-image [src]="t.imagePath" class="org-card-img" style="object-fit: contain; max-width: 100%; max-height: 100%;" />
      </div>
      <div class="mat-card-content">
        <p class="org-name">
          <strong>{{ t.name | truncate: [20] }}</strong>
        </p>
      </div>
      <div *ngIf="isSubscribed(t, 'VOLUNTEER')" fxLayout="column" fxLayoutAlign="space-around center">
        <button class="subscribeBtn" mat-raised-button color="warn" (click)="unsubscribe(t, 'VOLUNTEER')">
          Als Freiwilliger abmelden
        </button>
      </div>
      <div *ngIf="!isSubscribed(t, 'VOLUNTEER')" fxLayout="column" fxLayoutAlign="space-around center">
        <button class="subscribeBtn" mat-raised-button color="primary" (click)="subscribe(t, 'VOLUNTEER')">
          Als Freiwilliger beitreten
        </button>
      </div>
      <div *ngIf="isSubscribed(t, 'HELP_SEEKER')" fxLayout="column" fxLayoutAlign="space-around center">
        <button class="subscribeBtn" mat-raised-button color="warn" (click)="unsubscribe(t, 'HELP_SEEKER')">
          Als Hilfesuchender abmelden
        </button>
      </div>
      <div *ngIf="!isSubscribed(t, 'HELP_SEEKER')" fxLayout="column" fxLayoutAlign="space-around center">
        <button class="subscribeBtn" mat-raised-button color="primary" (click)="subscribe(t, 'HELP_SEEKER')">
          Als Hilfesuchender beitreten
        </button>
      </div>
    </mat-card>
  </div>
  <!-- todo selection -->
  <div style="padding-top: 40px;"></div>
</div>