<link type="text/css" rel="stylesheet" href="https://fonts.googleapis.com/css?family=Muli|Google+Sans">

<div #titleDiv class="title-section" style="background-color: rgb(62, 125, 219); color: white; line-height: 1.5; margin: -2px; font-size: 20px">
  <!-- <div style="padding-left: 12px" *ngIf="dataSource.data.length <= 0">Inbox - Keine Eingänge</div>
    <div style="padding-left: 12px" *ngIf="dataSource.data.length == 1">Inbox - Letzter Eingang</div>
    <div style="padding-left: 12px" *ngIf="dataSource.data.length > 1">Inbox - Letzte {{dataSource.data.length}}
        Eingänge</div>-->

  <div style="padding-left: 12px">Neu eingegangenen Einträge</div>
</div>

<div style="padding-top: 1px">

</div>

<div style="margin: 2px">
  <!-- <div style="border-color: black; border-style: solid; border-width: 1px; border-radius: 5px;"> -->
  <div>
    <div #innerDiv style="overflow: scroll">
      <div class="content-section" *ngIf="dataSource.data.length > 0">
        <mat-table #inboxTable [dataSource]="dataSource">

          <ng-container matColumnDef="archetype">
            <mat-header-cell *matHeaderCellDef>Archetype</mat-header-cell>
            <mat-cell *matCellDef="let entry">

              <div style=" z-index: 255; position: relative; width: 25px; height: 25px;">
                <img style="object-fit: contain; max-width: 100%; width: 100%; max-height: 100%; height: 100%;" [src]="getArchetypeIcon(entry)">
              </div>
              <!-- <div *ngIf="entry.classArchetype=='COMPETENCE'"
                                style=" z-index: 255; position: relative; width: 20px; height: 20px;"
                                mat-tooltip="Competence">
                                <img style="object-fit: contain; max-width: 100%; width: 100%; max-height: 100%; height: 100%;"
                                    src="/assets/competence.jpg">
                            </div>

                            <div *ngIf="entry.classArchetype=='FUNCTION'"
                                style=" z-index: 255; position: relative; width: 20px; height: 20px;"
                                mat-tooltip="Competence">
                                <img style="object-fit: contain; max-width: 100%; width: 100%; max-height: 100%; height: 100%;"
                                    src="/assets/TODO">
                            </div>

                            <div *ngIf="entry.classArchetype=='TASK'"
                                style=" z-index: 255; position: relative; width: 20px; height: 20px;"
                                mat-tooltip="Competence">
                                <img style="object-fit: contain; max-width: 100%; width: 100%; max-height: 100%; height: 100%;"
                                    src="/assets/cog.png">
                            </div>



                            <div *ngIf="entry.classArchetype=='ACHIEVEMENT'">

                                <div *ngIf="entry.feedbackType==null"
                                    style=" z-index: 255; position: relative; width: 20px; height: 20px;"
                                    mat-tooltip="Competence">
                                    <img style="object-fit: contain; max-width: 100%; width: 100%; max-height: 100%; height: 100%;"
                                        src="/assets/icons/achievements_black.png">
                                </div>


                                <div *ngIf="entry.feedbackType!=null"
                                    style=" z-index: 255; position: relative; width: 20px; height: 20px;"
                                    mat-tooltip="Competence">
                                    <mat-icon inline="true" style="font-size: 20px; line-height: normal">feedback
                                    </mat-icon>
                                </div>

                            </div> -->
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="label">
            <mat-header-cell *matHeaderCellDef>Label</mat-header-cell>
            <mat-cell *matCellDef="let entry">
              <div fxLayout="row" fxLayoutAlign="start start">
                <div>{{entry.name}}</div>
                <div class="pl-4 pr-4">-</div>
                <div>{{findNameProperty(entry)}}</div>
              </div>
            </mat-cell>
          </ng-container>

          <!-- <ng-container matColumnDef="date">
                        <mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
                        <mat-cell *matCellDef="let entry">
                            {{getDateString(entry.timestamp)}}
                        </mat-cell>
                    </ng-container> -->


          <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row> -->
          <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'highlighted_new': row.status == 'new' }" class="table-row"></mat-row>
        </mat-table>
      </div>

      <div *ngIf="classInstanceDTOs.length <= 0" class="content-section">
        <div *ngIf="isLoaded" style="text-align: center; padding-top: 12px">keine Einträge vorhanden</div>
      </div>

    </div>

    <div #actionDiv fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="isLoaded" class="preview-button" mat-stroked-button color="accent" (click)="showInboxClicked()">Inbox
                anzeigen</button>
    </div>
  </div>

</div>