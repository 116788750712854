"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var NextcloudCredentials = /** @class */ (function () {
    function NextcloudCredentials(domain, username, password) {
        this.domain = domain;
        this.username = username;
        this.password = password;
    }
    return NextcloudCredentials;
}());
exports.NextcloudCredentials = NextcloudCredentials;
