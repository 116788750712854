"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["#profile[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]{height:230px;min-height:230px;max-height:230px;background:url(/assets/images/backgrounds/dark-material-bg.jpg) 0 45%/cover no-repeat}#profile[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]   .profile-image-container[_ngcontent-%COMP%]{cursor:pointer;position:relative}#profile[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]   .profile-image-container[_ngcontent-%COMP%]   .profile-image[_ngcontent-%COMP%]{margin-right:24px;width:100%;height:100%;position:absolute;top:0;left:0}#profile[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]   .profile-image-container[_ngcontent-%COMP%]   .profile-image-overlay[_ngcontent-%COMP%]{display:none;width:100%;height:100%;position:absolute;top:0;left:0;z-index:10}#profile[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]   .profile-image-container[_ngcontent-%COMP%]:hover   .profile-image[_ngcontent-%COMP%]{border:4px solid #fff}#profile[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]   .profile-image-container[_ngcontent-%COMP%]:hover   .profile-image-overlay[_ngcontent-%COMP%]{display:block}#profile[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]   .name[_ngcontent-%COMP%]{font-size:34px;color:#fff}@media screen and (min-width:600px) and (max-width:959px){#profile[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]   .profile-image-container[_ngcontent-%COMP%]   .profile-image[_ngcontent-%COMP%]{margin:0 0 16px}#profile[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]   .name[_ngcontent-%COMP%]{margin-bottom:32px}}#profile[_ngcontent-%COMP%]   .header[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{text-transform:none;padding:0 16px;margin:0 0 0 8px}mat-divider[_ngcontent-%COMP%]{margin-top:25px;margin-bottom:25px}"];
exports.styles = styles;
