"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var util_1 = require("util");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var MarketplaceService = /** @class */ (function () {
    function MarketplaceService(http) {
        this.http = http;
    }
    MarketplaceService.prototype.findAll = function () {
        return this.http.get(environment_1.environment.CORE_URL + "/marketplace");
    };
    MarketplaceService.prototype.findById = function (marketplaceId) {
        return this.http.get(environment_1.environment.CORE_URL + "/marketplace/" + marketplaceId);
    };
    MarketplaceService.prototype.save = function (marketplace) {
        if (util_1.isNullOrUndefined(marketplace.id)) {
            return this.http.post(environment_1.environment.CORE_URL + "/marketplace", marketplace);
        }
        return this.http.put(environment_1.environment.CORE_URL + "/marketplace/" + marketplace.id, marketplace);
    };
    MarketplaceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MarketplaceService_Factory() { return new MarketplaceService(i0.ɵɵinject(i1.HttpClient)); }, token: MarketplaceService, providedIn: "root" });
    return MarketplaceService;
}());
exports.MarketplaceService = MarketplaceService;
