<div *ngIf="isLoaded">
  <mat-card style="margin: 20px">
    <mat-card-title>
      Speicherort des lokalen Freiwilligenpasses
    </mat-card-title>
    <mat-card-content>
      <!-- local repo location radio buttons -->
      <form [formGroup]="radioButtonForm">
        <mat-radio-group
          class="radioButtons"
          (change)="localRepositoryLocationChange($event.value)"
          formControlName="radioButtonValue"
        >
          <div
            matTooltip="Nicht verknüpft"
            [matTooltipDisabled]="isJsonServerConnected"
          >
            <mat-radio-button
              #local
              value="LOCAL"
              [disabled]="!isJsonServerConnected"
              [checked]="getChecked('LOCAL')"
              >Lokal (json-server)</mat-radio-button
            >
          </div>
          <div
            matTooltip="Nicht verknüpft"
            [matTooltipDisabled]="isDropboxConnected"
          >
            <mat-radio-button
              #dropbox
              value="DROPBOX"
              [disabled]="!isDropboxConnected"
              [checked]="getChecked('DROPBOX')"
              >Dropbox</mat-radio-button
            >
          </div>
          <div
            matTooltip="Nicht verknüpft"
            [matTooltipDisabled]="isNextcloudConnected"
          >
            <mat-radio-button
              #nextcloud
              value="NEXTCLOUD"
              [disabled]="!isNextcloudConnected"
              [checked]="getChecked('NEXTCLOUD')"
              >Nextcloud</mat-radio-button
            >
          </div>
        </mat-radio-group>
      </form>

      <div style="height: 30px"></div>

      <div fxLayout="row" fxLayoutAlign="space-around start">
        <!-- Nextcloud -->
        <div
          class="login"
          fxLayout="column"
          fxLayoutAlign="space-between center"
        >
          <img class="logo" src="./assets/images/logos/Nextcloud_Logo.svg" />
          <form
            *ngIf="!isNextcloudConnected"
            fxLayout="column"
            name="nextcloudLoginForm"
            (submit)="loginNextcloud()"
            [formGroup]="nextcloudLoginForm"
            novalidate
          >
            <mat-form-field class="input">
              <input
                matInput
                placeholder="WebDAV Domain"
                formControlName="domain"
              />
            </mat-form-field>

            <mat-form-field class="input">
              <input
                matInput
                placeholder="Benutzername"
                formControlName="username"
              />
            </mat-form-field>

            <mat-form-field class="input">
              <input
                matInput
                type="password"
                placeholder="Passwort"
                formControlName="password"
              />
            </mat-form-field>
            <p *ngIf="nextcloudLoginError" class="text-align-center error">
              Anmeldedaten nicht korrekt!
            </p>

            <button
              class="input"
              type="submit"
              mat-raised-button
              color="primary"
              class="submit-button"
              aria-label="Nextcloud verknüpfen"
              [disabled]="nextcloudLoginForm.invalid"
            >
              Nextcloud Konto verknüpfen
              <mat-icon *ngIf="isNextcloudLoginInProgress"
                ><mat-spinner color="accent" diameter="20"> </mat-spinner
              ></mat-icon>
            </button>
          </form>
          <div
            matTooltip="Nicht möglich, da gerade als Speicherort ausgewählt!"
            [matTooltipDisabled]="user.localRepositoryLocation != 'NEXTCLOUD'"
          >
            <button
              class="input"
              mat-raised-button
              *ngIf="isNextcloudConnected"
              [disabled]="user.localRepositoryLocation == 'NEXTCLOUD'"
              color="primary"
              (click)="removeNextcloud()"
            >
              Verbindung zu Nextcloud aufheben
            </button>
          </div>
        </div>

        <!-- Dropbox -->
        <div
          class="login"
          fxLayout="column"
          fxLayoutAlign="space-between center"
        >
          <img class="logo" src="./assets/images/logos/Dropbox_logo.svg" />

          <div class="input">
            <a
              mat-raised-button
              style="width: 100%"
              *ngIf="!isDropboxConnected"
              color="primary"
              href="{{ dropboxAuthUrl }}"
            >
              Dropbox Konto verknüpfen
            </a>
          </div>

          <div
            matTooltip="Nicht möglich, da gerade als Speicherort ausgewählt!"
            [matTooltipDisabled]="user.localRepositoryLocation != 'DROPBOX'"
          >
            <button
              class="input"
              mat-raised-button
              *ngIf="isDropboxConnected"
              [disabled]="user.localRepositoryLocation == 'DROPBOX'"
              color="primary"
              (click)="removeDropbox()"
            >
              Verbindung zu Dropbox aufheben
            </button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
