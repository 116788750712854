"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var management_summary_component_1 = require("./management-summary.component");
var routes = [{ path: "", component: management_summary_component_1.ManagementSummaryComponent }];
var ManagementSummary = /** @class */ (function () {
    function ManagementSummary() {
    }
    return ManagementSummary;
}());
exports.ManagementSummary = ManagementSummary;
