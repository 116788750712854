"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./navbar.component.scss.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i3 = require("@angular/material/button");
var i4 = require("@angular/cdk/a11y");
var i5 = require("@angular/platform-browser/animations");
var i6 = require("@angular/flex-layout/extended");
var i7 = require("@angular/flex-layout/core");
var i8 = require("../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i9 = require("@angular/material/icon");
var i10 = require("../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive");
var i11 = require("../../../@fuse/services/config.service");
var i12 = require("@angular/cdk/platform");
var i13 = require("../../../@fuse/components/navigation/navigation.component.ngfactory");
var i14 = require("../../../@fuse/components/navigation/navigation.component");
var i15 = require("@angular/common");
var i16 = require("../content/_service/login.service");
var i17 = require("@angular/common/http");
var i18 = require("@angular/router");
var i19 = require("../content/_service/local-repository-dropbox.service");
var i20 = require("../content/_service/local-repository-jsonServer.service");
var i21 = require("../content/_service/local-repository-nextcloud.service");
var i22 = require("./navbar.component");
var i23 = require("../content/_service/message.service");
var i24 = require("../../../@fuse/components/sidebar/sidebar.service");
var i25 = require("../../../@fuse/components/navigation/navigation.service");
var i26 = require("../content/_service/role-change.service");
var styles_FuseNavbarComponent = [i0.styles];
var RenderType_FuseNavbarComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FuseNavbarComponent, data: {} });
exports.RenderType_FuseNavbarComponent = RenderType_FuseNavbarComponent;
function View_FuseNavbarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "navbar-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 16, "div", [["class", "navbar-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "logo-icon"], ["src", "assets/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "logo-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["iVolunteer"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "button", [["class", "toggle-button-navbar"], ["fxHide.lt-lg", ""], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebarFolded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(9, 4866048, null, 0, i6.DefaultShowHideDirective, [i1.ElementRef, i6.ShowHideStyleBuilder, i7.StyleUtils, i7.MediaMarshaller, i7.LAYOUT_CONFIG, i1.PLATFORM_ID, [2, i7.SERVER_TOKEN]], { "fxHide.lt-lg": [0, "fxHide.lt-lg"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["menu"])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "button", [["class", "toggle-button-navbar"], ["fxHide.gt-md", ""], ["mat-icon-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebarOpened() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(15, 4866048, null, 0, i6.DefaultShowHideDirective, [i1.ElementRef, i6.ShowHideStyleBuilder, i7.StyleUtils, i7.MediaMarshaller, i7.LAYOUT_CONFIG, i1.PLATFORM_ID, [2, i7.SERVER_TOKEN]], { "fxHide.gt-md": [0, "fxHide.gt-md"] }, null), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i8.View_MatIcon_0, i8.RenderType_MatIcon)), i1.ɵdid(17, 9158656, null, 0, i9.MatIcon, [i1.ElementRef, i9.MatIconRegistry, [8, null], [2, i9.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_back"])), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "navbar-content"], ["fusePerfectScrollbar", ""]], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 20).documentClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 4407296, [[1, 4]], 0, i10.FusePerfectScrollbarDirective, [i1.ElementRef, i11.FuseConfigService, i12.Platform], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 1, "fuse-navigation", [["layout", "vertical"]], null, null, null, i13.View_FuseNavigationComponent_0, i13.RenderType_FuseNavigationComponent)), i1.ɵdid(22, 49152, null, 0, i14.FuseNavigationComponent, [], { layout: [0, "layout"], navigation: [1, "navigation"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = ""; _ck(_v, 9, 0, currVal_2); _ck(_v, 11, 0); var currVal_7 = ""; _ck(_v, 15, 0, currVal_7); _ck(_v, 17, 0); _ck(_v, 20, 0); var currVal_10 = "vertical"; var currVal_11 = _co.navigation; _ck(_v, 22, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 8).disabled || null); var currVal_1 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 11).inline; var currVal_4 = (((i1.ɵnov(_v, 11).color !== "primary") && (i1.ɵnov(_v, 11).color !== "accent")) && (i1.ɵnov(_v, 11).color !== "warn")); _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = (i1.ɵnov(_v, 14).disabled || null); var currVal_6 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_5, currVal_6); var currVal_8 = i1.ɵnov(_v, 17).inline; var currVal_9 = (((i1.ɵnov(_v, 17).color !== "primary") && (i1.ɵnov(_v, 17).color !== "accent")) && (i1.ɵnov(_v, 17).color !== "warn")); _ck(_v, 16, 0, currVal_8, currVal_9); }); }
function View_FuseNavbarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "navbar-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fuse-navigation", [["layout", "horizontal"]], null, null, null, i13.View_FuseNavigationComponent_0, i13.RenderType_FuseNavigationComponent)), i1.ɵdid(3, 49152, null, 0, i14.FuseNavigationComponent, [], { layout: [0, "layout"], navigation: [1, "navigation"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "horizontal"; var currVal_1 = _co.navigation; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_FuseNavbarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { directive: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavbarComponent_1)), i1.ɵdid(2, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseNavbarComponent_2)), i1.ɵdid(4, 16384, null, 0, i15.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.layout == "vertical"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.layout == "horizontal"); _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_FuseNavbarComponent_0 = View_FuseNavbarComponent_0;
function View_FuseNavbarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "fuse-navbar", [], null, null, null, View_FuseNavbarComponent_0, RenderType_FuseNavbarComponent)), i1.ɵprd(512, null, i16.LoginService, i16.LoginService, [i17.HttpClient, i17.HttpClient, i18.Router, i19.LocalRepositoryDropboxService, i20.LocalRepositoryJsonServerService, i21.LocalRepositoryNextcloudService]), i1.ɵdid(2, 245760, null, 0, i22.FuseNavbarComponent, [i23.MessageService, i24.FuseSidebarService, i16.LoginService, i25.FuseNavigationService, i18.Router, i26.RoleChangeService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
exports.View_FuseNavbarComponent_Host_0 = View_FuseNavbarComponent_Host_0;
var FuseNavbarComponentNgFactory = i1.ɵccf("fuse-navbar", i22.FuseNavbarComponent, View_FuseNavbarComponent_Host_0, { layout: "layout" }, {}, []);
exports.FuseNavbarComponentNgFactory = FuseNavbarComponentNgFactory;
