"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./donut.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../../../../../node_modules/@swimlane/ngx-charts/swimlane-ngx-charts.ngfactory");
var i3 = require("@swimlane/ngx-charts");
var i4 = require("@angular/flex-layout/flex");
var i5 = require("@angular/flex-layout/core");
var i6 = require("../../share-menu/share-menu.component.ngfactory");
var i7 = require("../../share-menu/share-menu.component");
var i8 = require("@angular/common");
var i9 = require("./donut.component");
var i10 = require("../../../../../../_service/stored-chart.service");
var styles_DonutComponent = [i0.styles];
var RenderType_DonutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DonutComponent, data: {} });
exports.RenderType_DonutComponent = RenderType_DonutComponent;
function View_DonutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ngx-charts-pie-chart", [], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.onDonutSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_PieChartComponent_0, i2.RenderType_PieChartComponent)), i1.ɵdid(1, 4898816, null, 1, i3.PieChartComponent, [i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef], { results: [0, "results"], scheme: [1, "scheme"], animations: [2, "animations"], labels: [3, "labels"], legend: [4, "legend"], explodeSlices: [5, "explodeSlices"], doughnut: [6, "doughnut"], arcWidth: [7, "arcWidth"], tooltipDisabled: [8, "tooltipDisabled"] }, { select: "select" }), i1.ɵqud(335544320, 1, { tooltipTemplate: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.donutData; var currVal_1 = _co.scheme; var currVal_2 = _co.animations; var currVal_3 = _co.labels; var currVal_4 = _co.legend; var currVal_5 = _co.explodeSlices; var currVal_6 = _co.doughnut; var currVal_7 = _co.arcWidth; var currVal_8 = _co.tooltipDisabled; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
function View_DonutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["fxLayout", "row"], ["fxLayoutAlign", "space-between center"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i4.DefaultLayoutDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutStyleBuilder], i5.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(4, 671744, null, 0, i4.DefaultLayoutAlignDirective, [i1.ElementRef, i5.StyleUtils, [2, i4.LayoutAlignStyleBuilder], i5.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ":"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-share-menu", [], null, [[null, "export"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("export" === en)) {
        var pd_0 = (_co.exportChart() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_ShareMenuComponent_0, i6.RenderType_ShareMenuComponent)), i1.ɵdid(8, 114688, null, 0, i7.ShareMenuComponent, [], { label: [0, "label"] }, { export: "export" }), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "donut-parent"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DonutComponent_1)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "row"; _ck(_v, 3, 0, currVal_1); var currVal_2 = "space-between center"; _ck(_v, 4, 0, currVal_2); var currVal_4 = _co.type; _ck(_v, 8, 0, currVal_4); var currVal_5 = _co.donutData; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.type; _ck(_v, 6, 0, currVal_3); }); }
exports.View_DonutComponent_0 = View_DonutComponent_0;
function View_DonutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-donut", [], null, null, null, View_DonutComponent_0, RenderType_DonutComponent)), i1.ɵdid(1, 638976, null, 0, i9.DonutComponent, [i10.StoredChartService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DonutComponent_Host_0 = View_DonutComponent_Host_0;
var DonutComponentNgFactory = i1.ɵccf("app-donut", i9.DonutComponent, View_DonutComponent_Host_0, { type: "type", classInstanceDTOs: "classInstanceDTOs", selectedYaxis: "selectedYaxis", selectedYear: "selectedYear", selectedTaskType: "selectedTaskType", timelineFilter: "timelineFilter", marketplace: "marketplace", volunteer: "volunteer" }, {}, []);
exports.DonutComponentNgFactory = DonutComponentNgFactory;
