"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var create_tenant_component_1 = require("./create-tenant.component");
var routes = [
    {
        path: '',
        component: create_tenant_component_1.CreateTenantComponent,
    }
];
var CreateTenantModule = /** @class */ (function () {
    function CreateTenantModule() {
    }
    return CreateTenantModule;
}());
exports.CreateTenantModule = CreateTenantModule;
