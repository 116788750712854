"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var fuse_perfect_scrollbar_directive_1 = require("@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive");
var sidebar_service_1 = require("@fuse/components/sidebar/sidebar.service");
var navigation_volunteer_1 = require("app/navigation/navigation_volunteer");
var navigation_service_1 = require("@fuse/components/navigation/navigation.service");
var navigation_helpseeker_1 = require("../../navigation/navigation_helpseeker");
var navigation_flexprod_1 = require("../../navigation/navigation_flexprod");
var login_service_1 = require("../content/_service/login.service");
var user_1 = require("../content/_model/user");
var message_service_1 = require("../content/_service/message.service");
var navigation_recruiter_1 = require("app/navigation/navigation_recruiter");
var navigation_admin_1 = require("app/navigation/navigation_admin");
var role_change_service_1 = require("../content/_service/role-change.service");
var navigation_tenant_admin_1 = require("app/navigation/navigation_tenant-admin");
var FuseNavbarComponent = /** @class */ (function () {
    function FuseNavbarComponent(messageService, sidebarService, loginService, navigationService, router, roleChangeService) {
        // Navigation data
        var _this = this;
        this.messageService = messageService;
        this.sidebarService = sidebarService;
        this.loginService = loginService;
        this.navigationService = navigationService;
        this.router = router;
        this.roleChangeService = roleChangeService;
        this.loginService
            .getLoggedInUserRole()
            .toPromise()
            .then(function (role) {
            switch (role) {
                case user_1.UserRole.HELP_SEEKER:
                    _this.navigation = navigation_helpseeker_1.navigation_helpseeker;
                    break;
                case user_1.UserRole.VOLUNTEER:
                    _this.navigation = navigation_volunteer_1.navigation_volunteer;
                    break;
                case user_1.UserRole.FLEXPROD:
                    _this.navigation = navigation_flexprod_1.navigation_flexprod;
                    break;
                case user_1.UserRole.RECRUITER:
                    _this.navigation = navigation_recruiter_1.navigation_recruiter;
                    break;
                case user_1.UserRole.ADMIN:
                    _this.navigation = navigation_admin_1.navigation_admin;
                    break;
                case user_1.UserRole.TENANT_ADMIN:
                    _this.navigation = navigation_tenant_admin_1.navigation_tenantAdmin;
                    break;
            }
        })
            .catch(function (e) {
            console.warn("NAVBAR COMPONENT ERROR: " + JSON.stringify(e));
        });
        // Default layout
        this.layout = "vertical";
        this.onRoleChanged = this.roleChangeService.onRoleChanged.subscribe(function (role) {
            _this.changeNavigation(role);
        });
    }
    Object.defineProperty(FuseNavbarComponent.prototype, "directive", {
        set: function (theDirective) {
            if (!theDirective) {
                return;
            }
        },
        enumerable: true,
        configurable: true
    });
    FuseNavbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.navigationServiceWatcher = this.navigationService.onItemCollapseToggled.subscribe(function () {
            _this.fusePerfectScrollbarUpdateTimeout = setTimeout(function () {
                _this.fusePerfectScrollbar.update();
            }, 310);
        });
        this.router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationEnd) {
                if (_this.sidebarService.getSidebar("navbar")) {
                    _this.sidebarService.getSidebar("navbar").close();
                }
            }
        });
    };
    FuseNavbarComponent.prototype.ngOnDestroy = function () {
        if (this.fusePerfectScrollbarUpdateTimeout) {
            clearTimeout(this.fusePerfectScrollbarUpdateTimeout);
        }
        if (this.navigationServiceWatcher) {
            this.navigationServiceWatcher.unsubscribe();
        }
        this.onRoleChanged.unsubscribe();
    };
    FuseNavbarComponent.prototype.toggleSidebarOpened = function () {
        this.sidebarService.getSidebar("navbar").toggleOpen();
    };
    FuseNavbarComponent.prototype.toggleSidebarFolded = function () {
        this.sidebarService.getSidebar("navbar").toggleFold();
    };
    FuseNavbarComponent.prototype.changeNavigation = function (role) {
        switch (role) {
            case user_1.UserRole.TENANT_ADMIN:
                this.navigation = navigation_tenant_admin_1.navigation_tenantAdmin;
                break;
            case user_1.UserRole.HELP_SEEKER:
                this.navigation = navigation_helpseeker_1.navigation_helpseeker;
                break;
            case user_1.UserRole.VOLUNTEER:
                this.navigation = navigation_volunteer_1.navigation_volunteer;
                break;
            case user_1.UserRole.FLEXPROD:
                this.navigation = navigation_flexprod_1.navigation_flexprod;
                break;
            case user_1.UserRole.RECRUITER:
                this.navigation = navigation_recruiter_1.navigation_recruiter;
                break;
            case user_1.UserRole.ADMIN:
                this.navigation = navigation_admin_1.navigation_admin;
                break;
        }
    };
    return FuseNavbarComponent;
}());
exports.FuseNavbarComponent = FuseNavbarComponent;
