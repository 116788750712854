"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var group_detail_component_1 = require("./group-detail.component");
var routes = [
    { path: ':groupId', component: group_detail_component_1.FuseGroupDetailComponent }
];
var FuseGroupDetailModule = /** @class */ (function () {
    function FuseGroupDetailModule() {
    }
    return FuseGroupDetailModule;
}());
exports.FuseGroupDetailModule = FuseGroupDetailModule;
