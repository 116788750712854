"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var moment = require("moment");
var Highcharts = require("highcharts");
var sunburst_1 = require("highcharts/modules/sunburst");
var router_1 = require("@angular/router");
var class_instance_details_component_1 = require("app/main/content/_components/common/class-instance-details/class-instance-details.component");
sunburst_1.default(Highcharts);
var SunburstTableComponent = /** @class */ (function () {
    function SunburstTableComponent(router, dialog) {
        this.router = router;
        this.dialog = dialog;
        this.selectedTaskTypeChange = new core_1.EventEmitter();
        this.selectedTaskType = null;
        this.prevClicked = null;
        this.tableDataSource = new material_1.MatTableDataSource();
        this.displayedColumns = ["name", "dateFrom", "duration"];
        this.sunburstData = [];
        this.ngxColorsCool = [
            "#a8385d",
            "#7aa3e5",
            "#a27ea8",
            "#aae3f5",
            "#adcded",
            "#a95963",
            "#8796c0",
            "#7ed3ed",
            "#50abcc",
            "#ad6886"
        ];
        this.Highcharts = Highcharts;
        this.chartOptions = {
            chart: {
                height: 900,
                margin: [0, 0, 0, 0]
            },
            title: {
                text: undefined
            },
            tooltip: {
                valueDecimals: 2
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                series: {
                    dataLabels: {
                        enabled: true,
                        style: {
                            fontSize: "17px",
                            color: "#ffffff"
                        }
                    }
                }
            },
            series: [
                {
                    type: "sunburst",
                    data: this.sunburstData,
                    allowTraversingTree: true,
                    cursor: "pointer"
                }
            ]
        };
        this.sunburstCenterName = "Tätigkeiten";
        this.first = true;
    }
    SunburstTableComponent.prototype.ngOnInit = function () {
        this.updateSelectedTaskType();
    };
    SunburstTableComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.tableDataSource.paginator = this.paginator;
        this.tableDataSource.sort = this.sort;
        this.tableDataSource.data = this.filteredClassInstanceDTOs;
        Highcharts.getOptions().colors.splice(0, 0, "transparent");
        Highcharts.chart("sunburstChart", this.chartOptions).update({
            plotOptions: {
                series: {
                    events: {
                        click: function (event) {
                            _this.onSunburstChange(event);
                        }
                    }
                }
            }
        });
    };
    SunburstTableComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        // console.error('sunburst-table changes', changes);
        var changed = false;
        for (var propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case "classInstanceDTOs": {
                        if (typeof changes.classInstanceDTOs.currentValue != "undefined") {
                            this.classInstanceDTOs = changes.classInstanceDTOs.currentValue;
                            var list = this.classInstanceDTOs.map(function (ci) {
                                return {
                                    tt1: ci.taskType1,
                                    tt2: ci.taskType2,
                                    tt3: ci.taskType3
                                };
                            });
                            this.uniqueTt1 = __spread(new Set(list.map(function (item) { return item.tt1; })));
                            this.uniqueTt2 = __spread(new Set(list.map(function (item) { return item.tt2; })));
                            this.uniqueTt3 = __spread(new Set(list.map(function (item) { return item.tt2; })));
                            this.uniqueTt1.sort();
                            this.tt1ColorMap = new Map();
                            this.uniqueTt1.forEach(function (tt1, index) {
                                _this.tt1ColorMap.set(tt1, _this.ngxColorsCool[index % _this.ngxColorsCool.length]);
                            });
                            changed = true;
                        }
                        break;
                    }
                    case "selectedYaxis": {
                        if (typeof changes.selectedYaxis.currentValue != "undefined") {
                            this.selectedYaxis = changes.selectedYaxis.currentValue;
                            changed = true;
                        }
                        break;
                    }
                    case "timelineFilter": {
                        if (typeof changes.timelineFilter.currentValue != "undefined") {
                            this.timelineFilter = changes.timelineFilter.currentValue;
                            changed = true;
                        }
                        break;
                    }
                    case "selectedYear": {
                        if (typeof changes.selectedYear.currentValue != "undefined") {
                            this.selectedYear = changes.selectedYear.currentValue;
                            changed = true;
                        }
                        break;
                    }
                }
            }
        }
        if (changed) {
            this.generateSunburstData();
        }
    };
    SunburstTableComponent.prototype.generateSunburstData = function () {
        var _this = this;
        if (this.timelineFilter.from == null) {
            // filter by year
            if (this.selectedYear === "Gesamt") {
                this.filteredClassInstanceDTOs = __spread(this.classInstanceDTOs);
            }
            else {
                this.filteredClassInstanceDTOs = this.classInstanceDTOs.filter(function (c) {
                    return moment(c.dateFrom).isSame(moment(_this.selectedYear), "year");
                });
            }
        }
        else {
            // filter by timeline from to
            this.filteredClassInstanceDTOs = this.classInstanceDTOs.filter(function (c) {
                return (moment(c.dateFrom).isSameOrAfter(moment(_this.timelineFilter.from), "day") &&
                    moment(c.dateFrom).isSameOrBefore(moment(_this.timelineFilter.to), "day"));
            });
        }
        var list = this.filteredClassInstanceDTOs.map(function (ci) {
            var value;
            _this.selectedYaxis === "Anzahl" ? (value = 1) : (value = ci.duration);
            return {
                tt1: ci.taskType1,
                tt2: ci.taskType2,
                tt3: ci.taskType3,
                value: value
            };
        });
        var data = [];
        // insert 0 entry
        data.push({ id: "0", parent: "", name: this.sunburstCenterName });
        // insert tt1 entries
        this.uniqueTt1.forEach(function (tt1, index) {
            data.push({
                id: (index + 1).toString(),
                parent: "0",
                name: tt1,
                color: _this.ngxColorsCool[index]
            });
        });
        // insert tt2 entries (for each tt1 separetly)
        this.uniqueTt1.forEach(function (tt1) {
            var tt1List = list.filter(function (l) {
                return tt1 === l.tt1;
            });
            var tt2Map = new Map();
            tt1List.forEach(function (entry) {
                if (tt2Map.get(entry.tt2)) {
                    tt2Map.set(entry.tt2, Number(tt2Map.get(entry.tt2)) + Number(entry.value));
                }
                else {
                    tt2Map.set(entry.tt2, entry.value);
                }
            });
            var indexParent = _this.uniqueTt1.indexOf(tt1) + 1;
            Array.from(tt2Map.entries()).forEach(function (entry, index) {
                if (entry[0] != null && entry[1] != null && !isNaN(entry[1])) {
                    data.push({
                        id: indexParent + "-" + (index + 1).toString(),
                        parent: indexParent.toString(),
                        name: entry[0],
                        value: Number(entry[1])
                    });
                }
            });
        });
        // snapshot before any tt3 data is added
        var dataTt2 = __spread(data);
        // insert tt3 entries (for each tt2 separetly)
        this.uniqueTt2.forEach(function (tt2) {
            var tt2List = list.filter(function (l) {
                return tt2 === l.tt2;
            });
            var tt3Map = new Map();
            tt2List.forEach(function (entry) {
                if (tt3Map.get(entry.tt3)) {
                    tt3Map.set(entry.tt3, Number(tt3Map.get(entry.tt3)) + Number(entry.value));
                }
                else {
                    tt3Map.set(entry.tt3, entry.value);
                }
            });
            var indexParent = null;
            dataTt2.forEach(function (d) {
                if (d.name === tt2) {
                    indexParent = d.id;
                }
            });
            Array.from(tt3Map.entries()).forEach(function (entry, index) {
                if (entry[0] != null && entry[1] != null && !isNaN(entry[1])) {
                    data.push({
                        id: indexParent + "-" + (index + 1).toString(),
                        parent: indexParent.toString(),
                        name: entry[0],
                        value: Number(entry[1])
                    });
                }
            });
        });
        this.sunburstData = __spread(data);
        this.chartOptions.series = [
            {
                type: "sunburst",
                allowTraversingTree: true,
                cursor: "pointer",
                data: this.sunburstData
            }
        ];
        Highcharts.chart("sunburstChart", this.chartOptions);
        this.filteredClassInstanceDTOs.sort(function (a, b) {
            return new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime();
        });
        if (!this.first) {
            this.tableDataSource.data = this.filteredClassInstanceDTOs;
        }
        this.first = false;
    };
    SunburstTableComponent.prototype.onSunburstChange = function (event) {
        var _this = this;
        if (event.point.id === "0") {
            // Tätigkeitsart clicked
            this.selectedTaskType = null;
            this.filteredClassInstanceDTOs = __spread(this.classInstanceDTOs);
        }
        else {
            if (!event.point.node.isLeaf) {
                if (typeof event.point.node.sliced === "boolean" &&
                    event.point.node.sliced === false) {
                    // drillup
                    var parent_1 = null;
                    this.sunburstData.forEach(function (d) {
                        if (d.id === event.point.parent) {
                            parent_1 = d;
                        }
                    });
                    if (parent_1.name === this.sunburstCenterName) {
                        this.selectedTaskType = null;
                        this.filteredClassInstanceDTOs = __spread(this.classInstanceDTOs);
                    }
                    else {
                        this.selectedTaskType = parent_1.name;
                        this.filteredClassInstanceDTOs = this.classInstanceDTOs.filter(function (c) {
                            return c.taskType1 === parent_1.name;
                        });
                    }
                }
                else {
                    // drilldown
                    if (this.uniqueTt1.indexOf(event.point.name) > -1) {
                        // filter for tt1
                        this.selectedTaskType = event.point.name;
                        this.filteredClassInstanceDTOs = this.classInstanceDTOs.filter(function (c) {
                            return c.taskType1 === event.point.name;
                        });
                    }
                    else if (this.uniqueTt2.indexOf(event.point.name) > -1) {
                        // filter for tt2
                        this.filteredClassInstanceDTOs = this.classInstanceDTOs.filter(function (c) {
                            return c.taskType2 === event.point.name;
                        });
                        this.selectedTaskType = event.point.name;
                    }
                }
            }
        }
        this.prevClicked = event.point.node.name;
        // TIME FILTER
        if (this.timelineFilter.from === null) {
            // filter by year
            if (this.selectedYear === "Gesamt") {
                this.filteredClassInstanceDTOs = __spread(this.filteredClassInstanceDTOs);
            }
            else {
                this.filteredClassInstanceDTOs = this.filteredClassInstanceDTOs.filter(function (c) {
                    return moment(c.dateFrom).isSame(moment(_this.selectedYear), "year");
                });
            }
        }
        else {
            // filter by timeline from to
            this.filteredClassInstanceDTOs = this.filteredClassInstanceDTOs.filter(function (c) {
                return (moment(c.dateFrom).isSameOrAfter(moment(_this.timelineFilter.from), "day") &&
                    moment(c.dateFrom).isSameOrBefore(moment(_this.timelineFilter.to), "day"));
            });
        }
        this.filteredClassInstanceDTOs.sort(function (a, b) {
            return new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime();
        });
        this.updateSelectedTaskType();
        this.tableDataSource.data = this.filteredClassInstanceDTOs;
    };
    SunburstTableComponent.prototype.updateSelectedTaskType = function () {
        this.selectedTaskTypeChange.emit(this.selectedTaskType);
    };
    SunburstTableComponent.prototype.getStyle = function (tt1) {
        return {
            "background-color": this.tt1ColorMap.get(tt1) + "9B" // opacity
        };
    };
    SunburstTableComponent.prototype.navigateToClassInstanceDetails = function (row) {
        this.router.navigate(["main/details/" + row.id]);
    };
    SunburstTableComponent.prototype.openDialog = function (row) {
        var dialogRef = this.dialog.open(class_instance_details_component_1.ClassInstanceDetailsComponent, {
            width: "800px",
            height: "900px",
            data: { id: row.id }
        });
        dialogRef.afterClosed().subscribe(function (result) { });
    };
    return SunburstTableComponent;
}());
exports.SunburstTableComponent = SunburstTableComponent;
