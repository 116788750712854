<div id="achievements" class="page-layout blank p-24" fusePerfectScrollbar>
  <div
    class="header-achievements p-16 p-sm-24"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <h1 class="l-heading">Mein FreiwilligenLEBEN</h1>
  </div>

  <div *ngIf="marketplace !== undefined && subscribedTenants.length > 0">
    <mat-card>
      <organisation-filter
        (tenantSelectionChanged)="tenantSelectionChanged($event)"
      >
      </organisation-filter>
    </mat-card>

    <div class="content" *ngIf="isLoaded">
      <p
        *ngIf="isLoaded && !isLocalRepositoryConnected"
        class="alert alert-info"
      >
        <i class="fas fa-exclamation-circle"></i> &nbsp; Lokaler
        Freiwilligenpass nicht erreichbar, deshalb stammen die Daten vom
        Marktplatz!
      </p>

      <mat-tab-group
        dynamicHeight="true"
        (selectedTabChange)="tabChanged($event)"
      >
        <mat-tab label="Tätigkeiten">
          <!-- with ng-temp = lazy loading:  -->
          <!-- I would prefer not to lazy load, since db fetch takes foooor ever, each time tab is changed-->
          <!-- TODO PROBLEM: not showing line in timeline chart if not lazy loaded -->
          <ng-template matTabContent>
            <!--
              <div *ngIf="filteredClassInstanceDTOs.length > 0 && selectedTenants.length > 0">
                TODO: would be correct, because so no empty charts are shown if local repo is empty
                      BUT enormous performance problems (+ several seconds...)
            -->
            <fuse-tasks
              *ngIf="
                selectedTenants.length > 0 &&
                filteredClassInstanceDTOs.length > 0 &&
                classInstanceDTOs.length > 0
              "
              [classInstanceDTOs]="filteredClassInstanceDTOs"
              [selectedTenants]="selectedTenants"
            >
            </fuse-tasks>
          </ng-template>
        </mat-tab>

        <mat-tab label="Kompetenzen">
          <ng-template matTabContent>
            <fuse-competencies
              *ngIf="
                selectedTenants.length > 0 &&
                filteredClassInstanceDTOs.length > 0 &&
                classInstanceDTOs.length > 0
              "
              [classInstanceDTOs]="filteredClassInstanceDTOs"
              [selectedTenants]="selectedTenants"
            >
            </fuse-competencies>
          </ng-template>
        </mat-tab>

        <mat-tab label="Funktionen">
          <ng-template matTabContent>
            <!-- <fuse-functions></fuse-functions> -->
          </ng-template>
        </mat-tab>

        <mat-tab label="Verdienste">
          <ng-template matTabContent>
            <!-- <fuse-accomplishments></fuse-accomplishments> -->
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>

  <!-- <mat-card *ngIf="
  filteredClassInstanceDTOs.length === 0 && selectedTenants.length > 0" class="loadData">
      <ngx-spinner bdOpacity="0.1" bdColor="#FFFFFF" size="medium" color="#000000" type="ball-scale-ripple-multiple"
        [fullScreen]="false">
        <p class="loadingText">Loading Data...</p>
      </ngx-spinner>
    </mat-card> -->

  <div *ngIf="!isLoaded" class="center-spinner-wrapper">
    <mat-spinner></mat-spinner>
  </div>

  <p
    *ngIf="
      isLoaded && selectedTenants.length === 0 && subscribedTenants.length > 0;
      marketplace
    "
    class="alert alert-info"
  >
    <i class="fas fa-exclamation-circle"></i> &nbsp; Keine Organisation
    ausgewählt!
  </p>

  <p
    *ngIf="isLoaded && subscribedTenants.length === 0"
    class="alert alert-info"
  >
    <i class="fas fa-exclamation-circle"></i> &nbsp; Noch keiner Organisation
    beigetreten!
  </p>

  <p
    *ngIf="
      isLoaded &&
      classInstanceDTOs.length === 0 &&
      filteredClassInstanceDTOs.length === 0 &&
      isLocalRepositoryConnected &&
      selectedTenants.length > 0
    "
    class="alert alert-info"
  >
    <i class="fas fa-exclamation-circle"></i> &nbsp; Es befinden sich noch keine
    Daten in deinem lokalen Freiwilligenpass!
  </p>

  <p
    *ngIf="
      isLoaded &&
      classInstanceDTOs.length != 0 &&
      filteredClassInstanceDTOs.length === 0 &&
      isLocalRepositoryConnected &&
      selectedTenants.length > 0
    "
    class="alert alert-info"
  >
    <i class="fas fa-exclamation-circle"></i> &nbsp; Keine
    Freiwilligenpass-Einträge zur aktuellen Filterung gefunden
  </p>

  <p *ngIf="isLoaded && percentageFilteredOut > 0" style="margin-top: 20px;">
    {{ percentageFilteredOut | number: "1.0-2":"de-AT" }} % der Daten können
    nicht visualisiert werden, da essenzielle Felder fehlen (z.B. Datum, Dauer,
    oder Tasktyp)!
  </p>
</div>
