<div class="page-layout blank p-24" fusePerfectScrollbar *ngIf="selectedTenants.length > 0">
  <app-timeline-filter 
    [classInstanceDTOs]="classInstanceDTOs" 
    [selectedTaskType]="selectedTaskType"
    (timelineFilterChange)="timelineFilterChange($event)"
    (selectedYearChange)="selectedYearChange($event)" 
    (selectedYaxisChange)="selectedYaxisChange($event)">
  </app-timeline-filter>

  <mat-accordion multi="true">
    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h1>Tätigkeitsart</h1>
        </mat-panel-title>
        <mat-panel-description>
          Kategorisierung nach Art
        </mat-panel-description>
      </mat-expansion-panel-header>

      <ng-template matExpansionPanelContent>
        <app-sunburst-table
          [classInstanceDTOs]="classInstanceDTOs" 
          [timelineFilter]="timelineFilter"
          [selectedYear]="selectedYear" 
          [selectedYaxis]="selectedYaxis" 
          (selectedTaskTypeChange)="selectedTaskTypeChange($event)">
        </app-sunburst-table>
      </ng-template >

    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="false">
      <mat-expansion-panel-header>
        <mat-panel-title>
        <h1>Zeit, Ort und Rang</h1>
        </mat-panel-title>
        <mat-panel-description>
          Kategorisierung nach Zeit, Ort und Rang
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="secondRow" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
        <app-donut 
          [type]="'Wochentag'" 
          [classInstanceDTOs]="classInstanceDTOs" 
          [timelineFilter]="timelineFilter"
          [selectedYear]="selectedYear" 
          [selectedYaxis]="selectedYaxis" 
          [selectedTaskType]="selectedTaskType"
          [marketplace]="marketplace" 
          [volunteer]="volunteer">
        </app-donut>

        <app-donut [type]="'Tageszeit'" [classInstanceDTOs]="classInstanceDTOs" [timelineFilter]="timelineFilter"
          [selectedYear]="selectedYear" [selectedYaxis]="selectedYaxis" [selectedTaskType]="selectedTaskType"
          [marketplace]="marketplace" [volunteer]="volunteer">
        </app-donut>
      </div>

      <div class="secondRow" fxLayout="row wrap" fxLayoutAlign="space-evenly center">
        <app-donut [type]="'Orte'" [classInstanceDTOs]="classInstanceDTOs" [timelineFilter]="timelineFilter"
          [selectedYear]="selectedYear" [selectedYaxis]="selectedYaxis" [selectedTaskType]="selectedTaskType"
          [marketplace]="marketplace" [volunteer]="volunteer">
        </app-donut>

        <app-donut [type]="'Rang'" [classInstanceDTOs]="classInstanceDTOs" [timelineFilter]="timelineFilter"
          [selectedYear]="selectedYear" [selectedYaxis]="selectedYaxis" [selectedTaskType]="selectedTaskType"
          [marketplace]="marketplace" [volunteer]="volunteer">
        </app-donut>
      </div>

    </mat-expansion-panel>
  </mat-accordion>

</div>