"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var ShareMenuComponent = /** @class */ (function () {
    function ShareMenuComponent() {
        this.export = new core_1.EventEmitter();
    }
    ShareMenuComponent.prototype.ngOnInit = function () {
    };
    ShareMenuComponent.prototype.exportChart = function () {
        this.export.emit(this.label);
    };
    return ShareMenuComponent;
}());
exports.ShareMenuComponent = ShareMenuComponent;
