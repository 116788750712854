<link type="text/css" rel="stylesheet" href="https://fonts.googleapis.com/css?family=Muli|Roboto:300,400,500,700|Google+Sans" />

<div class="page-layout blank p-24" fusePerfectScrollbar>

  <customizable-header *ngIf="tenant" [tenant]="tenant" headerText="Einträge bestätigen"></customizable-header>

  <div class="mat-white-bg mat-elevation-z4 p-24 mr-24 mb-24 mt-12" *ngIf="isLoaded">
    <div class="pt-8 pb-12 fs-18">
      Freiwilligenpasseinträge für die Bestätigung auswählen
    </div>

    <div class="inbox-content">
      <mat-table #inboxTable class="inbox-table" [dataSource]="datasource" *ngIf="datasource.data.length > 0">

        <ng-container matColumnDef="checkboxes">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="label">
          <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
          <mat-cell *matCellDef="let entry">
            <div fxLayout="column" fxLayoutAlign="start start">
              <div>{{entry.name}}</div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="archetype">
          <mat-header-cell *matHeaderCellDef>Typ</mat-header-cell>
          <mat-cell *matCellDef="let entry">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div style=" z-index: 255; position: relative; width: 25px; height: 25px;">
                <img style="object-fit: contain; max-width: 100%; width: 100%; max-height: 100%; height: 100%;" [src]="getArchetypeIcon(entry)">
              </div>

              <div style="padding-left: 10px">
                {{getArchetypeName(entry)}}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="issuer">
          <mat-header-cell *matHeaderCellDef>Aussteller</mat-header-cell>
          <mat-cell *matCellDef="let entry" fxLayout="row" fxLayoutAlign="start center">

            <img class="avatar" [src]="getImagePathById(entry.issuerId)">
            <div fxLayout="column">
              <div>{{getNameForEntry(entry.issuerId,'issuer')}}</div>
              <div class="pl-8">{{getIssuerPositionForEntry(entry.issuerId)}}</div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="user">
          <mat-header-cell *matHeaderCellDef>Freiwilliger</mat-header-cell>
          <mat-cell *matCellDef="let entry" fxLayout="row" fxLayoutAlign="start center">

            <img class="avatar" [src]="getImagePathById(entry.userId)">
            <div>{{getNameForEntry(entry.userId,'volunteer')}}</div>

          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef>Datum</mat-header-cell>
          <mat-cell *matCellDef="let entry">
            {{getDateString(entry.timestamp)}}
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{ 'highlighted_new': row.status == 'new' }" class="table-row"></mat-row>
      </mat-table>

      <div *ngIf="datasource.data.length <= 0" class="inbox-empty-message">
        Keine unbestätigten Freiwilligenpasseinträge
      </div>
    </div>

    <div class="button-row" fxLayout="row" fxLayoutAlign="space-between center">
      <div></div>
      <button mat-raised-button color="primary" (click)="onAssetInboxSubmit()" [disabled]="selection.isEmpty()" matTooltip="Mit dieser Aktion wird der Eintrag in der Blockchain persistiert." aria-label="Button that displays a tooltip when focused or hovered over">
        Bestätigen</button>

    </div>
  </div>
</div>