"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var marketplace_form_component_1 = require("./marketplace-form.component");
var routes = [
    { path: "", component: marketplace_form_component_1.FuseMarketplaceFormComponent },
    { path: ":marketplaceId", component: marketplace_form_component_1.FuseMarketplaceFormComponent }
];
var FuseMarketplaceFormModule = /** @class */ (function () {
    function FuseMarketplaceFormModule() {
    }
    return FuseMarketplaceFormModule;
}());
exports.FuseMarketplaceFormModule = FuseMarketplaceFormModule;
