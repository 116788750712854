"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var UserService = /** @class */ (function () {
    function UserService(http) {
        this.http = http;
    }
    UserService.prototype.findAll = function (marketplace) {
        return this.http.get(marketplace.url + "/user/all");
    };
    UserService.prototype.findAllByRole = function (marketplace, role) {
        return this.http.get(marketplace.url + "/user/all/role/" + role);
    };
    UserService.prototype.findAllByTenantId = function (marketplace, tenantId) {
        return this.http.get(marketplace.url + "/user/all/tenant/" + tenantId);
    };
    UserService.prototype.findAllByRoleAndTenantId = function (marketplace, role, tenantId) {
        return this.http.get(marketplace.url + "/user/all/tenant/" + tenantId + "/role/" + role);
    };
    UserService.prototype.findById = function (marketplace, id) {
        return this.http.get(marketplace.url + "/user/" + id);
    };
    UserService.prototype.findByName = function (marketplace, name) {
        return this.http.get(marketplace.url + "/user/username/" + name);
    };
    UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}());
exports.UserService = UserService;
