"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var util_1 = require("util");
// validation function
function equals(control1, control2) {
    return function (control) {
        if (util_1.isNullOrUndefined(control1) || util_1.isNullOrUndefined(control2)) {
            return null;
        }
        var string1 = control1.value;
        var string2 = control2.value;
        if (string1 === '' || string2 === '') {
            return null;
        }
        if (util_1.isNullOrUndefined(string1) || util_1.isNullOrUndefined(string2)) {
            return null;
        }
        return string1 !== string2 ? { 'equals': { 'equals': false, 'string1': string1, 'string2': string2 } } : null;
    };
}
exports.equals = equals;
