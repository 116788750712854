"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./dashboard.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./dashboard-volunteer/dashboard-volunteer.component.ngfactory");
var i3 = require("../../_shared/dialogs/_dialog-factory/dialog-factory.component");
var i4 = require("@angular/material/dialog");
var i5 = require("./dashboard-volunteer/dashboard-volunteer.component");
var i6 = require("../../../_service/meta/core/class/class-instance.service");
var i7 = require("../../../_service/core-marketplace.service");
var i8 = require("../../../_service/core-tenant.service");
var i9 = require("@angular/platform-browser");
var i10 = require("@angular/router");
var i11 = require("@angular/material/icon");
var i12 = require("../../../_service/login.service");
var i13 = require("../../../_service/core-user.service");
var i14 = require("./dashboard-helpseeker-tenantAdmin/dashboard-helpseeker-tenantAdmin.component.ngfactory");
var i15 = require("./dashboard-helpseeker-tenantAdmin/dashboard-helpseeker-tenantAdmin.component");
var i16 = require("@angular/common");
var i17 = require("./dashboard.component");
var styles_DashboardComponent = [i0.styles];
var RenderType_DashboardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DashboardComponent, data: {} });
exports.RenderType_DashboardComponent = RenderType_DashboardComponent;
function View_DashboardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["style", "overflow: scroll; width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "dashboard-volunteer", [], null, null, null, i2.View_DashboardVolunteerComponent_0, i2.RenderType_DashboardVolunteerComponent)), i1.ɵprd(512, null, i3.DialogFactoryDirective, i3.DialogFactoryDirective, [i4.MatDialog]), i1.ɵdid(3, 114688, null, 0, i5.DashboardVolunteerComponent, [i4.MatDialog, i6.ClassInstanceService, i7.MarketplaceService, i8.TenantService, i9.DomSanitizer, i10.Router, i11.MatIconRegistry, i3.DialogFactoryDirective, i12.LoginService, i13.CoreUserService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_DashboardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "overflow: scroll; width: 100%;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dashboard-helpseeker-tenantAdmin", [], null, null, null, i14.View_DashboardHelpSeekerTenantAdminComponent_0, i14.RenderType_DashboardHelpSeekerTenantAdminComponent)), i1.ɵdid(2, 114688, null, 0, i15.DashboardHelpSeekerTenantAdminComponent, [i12.LoginService, i8.TenantService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_DashboardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_1)), i1.ɵdid(1, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DashboardComponent_2)), i1.ɵdid(3, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.role === "VOLUNTEER"); _ck(_v, 1, 0, currVal_0); var currVal_1 = ((_co.role === "HELP_SEEKER") || (_co.role === "TENANT_ADMIN")); _ck(_v, 3, 0, currVal_1); }, null); }
exports.View_DashboardComponent_0 = View_DashboardComponent_0;
function View_DashboardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dashboard", [], null, null, null, View_DashboardComponent_0, RenderType_DashboardComponent)), i1.ɵdid(1, 114688, null, 0, i17.DashboardComponent, [i12.LoginService, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_DashboardComponent_Host_0 = View_DashboardComponent_Host_0;
var DashboardComponentNgFactory = i1.ɵccf("dashboard", i17.DashboardComponent, View_DashboardComponent_Host_0, {}, {}, []);
exports.DashboardComponentNgFactory = DashboardComponentNgFactory;
