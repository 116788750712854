"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var import_component_1 = require("./import.component");
var routes = [{ path: "", component: import_component_1.ImportComponent }];
var ImportModule = /** @class */ (function () {
    function ImportModule() {
    }
    return ImportModule;
}());
exports.ImportModule = ImportModule;
