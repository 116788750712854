"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var local_repository_1 = require("../_model/local-repository");
var dropbox_1 = require("dropbox");
var local_repository_service_1 = require("./local-repository.service");
var i0 = require("@angular/core");
var LocalRepositoryDropboxService = /** @class */ (function (_super) {
    __extends(LocalRepositoryDropboxService, _super);
    function LocalRepositoryDropboxService() {
        var _this = _super.call(this) || this;
        _this.FILE_PATH = "/db.json";
        _this.localRepositorys = [];
        return _this;
    }
    LocalRepositoryDropboxService.prototype.findByVolunteer = function (volunteer) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var successFunction = function (localRepository) {
                subscriber.next(localRepository);
                subscriber.complete();
            };
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            try {
                if (volunteer.dropboxToken) {
                    var dbx_1 = new dropbox_1.Dropbox({ accessToken: volunteer.dropboxToken });
                    dbx_1.filesListFolder({ path: "" }).then(function (filesList) {
                        if (filesList.entries.findIndex(function (entry) { return entry.name === "db.json"; }) == -1) {
                            // create and read
                            dbx_1
                                .filesUpload({
                                contents: JSON.stringify({ repository: [] }, null, 2),
                                path: _this.FILE_PATH,
                                mode: { ".tag": "overwrite" },
                                autorename: false,
                                mute: false,
                            })
                                .then(function () {
                                dbx_1.filesDownload({ path: _this.FILE_PATH }).then(function (file) {
                                    var reader = new FileReader();
                                    var blob = file.fileBlob;
                                    reader.addEventListener("loadend", function () {
                                        _this.localRepositorys = [];
                                        if (_this.isValidJson(reader.result)) {
                                            var parsedJSON = JSON.parse(reader.result);
                                            parsedJSON.repository.forEach(function (lr) {
                                                _this.localRepositorys.push(lr);
                                            });
                                        }
                                        if (_this.localRepositorys.findIndex(function (l) { return l.id === volunteer.id; }) === -1) {
                                            var newRepo = new local_repository_1.LocalRepository(volunteer.id, volunteer.username);
                                            _this.localRepositorys.push(newRepo);
                                            _this.saveToDropbox(volunteer);
                                            successFunction(newRepo);
                                        }
                                        else {
                                            successFunction(_this.localRepositorys.find(function (localRepository) {
                                                return localRepository.id === volunteer.id;
                                            }));
                                        }
                                    });
                                    reader.readAsText(blob);
                                });
                            })
                                .catch(function (error) {
                                console.error(error);
                                alert("Failed to save to dropbox");
                            });
                        }
                        else {
                            // only read
                            dbx_1.filesDownload({ path: _this.FILE_PATH }).then(function (file) {
                                var reader = new FileReader();
                                var blob = file.fileBlob;
                                reader.addEventListener("loadend", function () {
                                    _this.localRepositorys = [];
                                    if (_this.isValidJson(reader.result)) {
                                        var parsedJSON = JSON.parse(reader.result);
                                        parsedJSON.repository.forEach(function (lr) {
                                            _this.localRepositorys.push(lr);
                                        });
                                    }
                                    if (_this.localRepositorys.findIndex(function (l) { return l.id === volunteer.id; }) === -1) {
                                        var newRepo = new local_repository_1.LocalRepository(volunteer.id, volunteer.username);
                                        _this.localRepositorys.push(newRepo);
                                        _this.saveToDropbox(volunteer);
                                        successFunction(newRepo);
                                    }
                                    else {
                                        successFunction(_this.localRepositorys.find(function (localRepository) {
                                            return localRepository.id === volunteer.id;
                                        }));
                                    }
                                });
                                reader.readAsText(blob);
                            });
                        }
                    });
                }
                else {
                    failureFunction(null);
                }
            }
            catch (error) {
                failureFunction(error);
            }
        });
        return observable;
    };
    LocalRepositoryDropboxService.prototype.findClassInstancesByVolunteer = function (volunteer) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            var successFunction = function (localRepository) {
                if (!localRepository) {
                    subscriber.next([]);
                }
                else {
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                return successFunction(localRepository);
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryDropboxService.prototype.synchronizeSingleClassInstance = function (volunteer, classInstance) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                try {
                    localRepository.classInstances.push(classInstance);
                    _this.localRepositorys.forEach(function (lr, index) {
                        if (lr.id == localRepository.id) {
                            _this.localRepositorys[index] = localRepository;
                        }
                    });
                    _this.saveToDropbox(volunteer);
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
                catch (error) {
                    failureFunction(error);
                }
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryDropboxService.prototype.getSingleClassInstance = function (volunteer, classInstanceId) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                var classInstance = localRepository.classInstances.find(function (ci) {
                    return ci.id === classInstanceId;
                });
                subscriber.next(classInstance);
                subscriber.complete();
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryDropboxService.prototype.synchronizeClassInstances = function (volunteer, classInstances) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                try {
                    localRepository.classInstances = __spread(localRepository.classInstances, classInstances);
                    _this.localRepositorys.forEach(function (lr, index) {
                        if (lr.id == localRepository.id) {
                            _this.localRepositorys[index] = localRepository;
                        }
                    });
                    _this.saveToDropbox(volunteer);
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
                catch (error) {
                    failureFunction(error);
                }
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryDropboxService.prototype.overrideClassInstances = function (volunteer, classInstances) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                try {
                    localRepository.classInstances = classInstances;
                    _this.localRepositorys.forEach(function (lr, index) {
                        if (lr.id == localRepository.id) {
                            _this.localRepositorys[index] = localRepository;
                        }
                    });
                    _this.saveToDropbox(volunteer);
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
                catch (error) {
                    failureFunction(error);
                }
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryDropboxService.prototype.removeSingleClassInstance = function (volunteer, classInstanceId) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                try {
                    localRepository.classInstances.forEach(function (ci, index, object) {
                        if (ci.id === classInstanceId) {
                            object.splice(index, 1);
                        }
                    });
                    _this.localRepositorys.forEach(function (lr, index) {
                        if (lr.id == localRepository.id) {
                            _this.localRepositorys[index] = localRepository;
                        }
                    });
                    _this.saveToDropbox(volunteer);
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
                catch (error) {
                    failureFunction(error);
                }
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryDropboxService.prototype.removeClassInstances = function (volunteer, classInstanceIds) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                try {
                    localRepository.classInstances = localRepository.classInstances.filter(function (c) { return classInstanceIds.indexOf(c.id) < 0; });
                    _this.localRepositorys.forEach(function (lr, index) {
                        if (lr.id == localRepository.id) {
                            _this.localRepositorys[index] = localRepository;
                        }
                    });
                    _this.saveToDropbox(volunteer);
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
                catch (error) {
                    failureFunction(error);
                }
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryDropboxService.prototype.saveToDropbox = function (volunteer) {
        var dbx = new dropbox_1.Dropbox({ accessToken: volunteer.dropboxToken });
        var content = { repository: this.localRepositorys };
        dbx
            .filesUpload({
            contents: JSON.stringify(content, null, 2),
            path: this.FILE_PATH,
            mode: { ".tag": "overwrite" },
            autorename: false,
            mute: false,
        })
            .then(function () {
            // console.error("successfully written to dropbox");
        })
            .catch(function (error) {
            console.error(error);
            alert("Failed to save to dropbox");
        });
    };
    LocalRepositoryDropboxService.prototype.isValidJson = function (str) {
        try {
            JSON.parse(str);
        }
        catch (e) {
            return false;
        }
        return true;
    };
    LocalRepositoryDropboxService.prototype.isConnected = function (credentials) {
        throw new Error("Method not implemented.");
    };
    LocalRepositoryDropboxService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalRepositoryDropboxService_Factory() { return new LocalRepositoryDropboxService(); }, token: LocalRepositoryDropboxService, providedIn: "root" });
    return LocalRepositoryDropboxService;
}(local_repository_service_1.LocalRepositoryService));
exports.LocalRepositoryDropboxService = LocalRepositoryDropboxService;
