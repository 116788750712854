<div
  class="page-layout blank p-24"
  fusePerfectScrollbar
  style="overflow: scroll"
>
  <div style="padding-top: 12px"></div>

  <customizable-header
    *ngIf="tenant && !isDialog"
    [tenant]="tenant"
    [displayNavigateBack]="true"
    headerText="Details"
  ></customizable-header>

  <customizable-header
    *ngIf="tenant && isDialog"
    [tenant]="tenant"
    [displayNavigateBack]="false"
    headerText="Details"
  ></customizable-header>

  <div *ngIf="classInstance != null">
    <table class="details">
      <tbody>
        <tr>
          <td>Asset:</td>
          <td class="alignRight">{{ getClassInstanceName() }}</td>
        </tr>
        <tr *ngIf="role === 'HELP_SEEKER' && volunteer != null">
          <td>Volunteer:</td>
          <td class="alignRight">
            {{ volunteer.firstname }} {{ volunteer.lastname }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- <h1 style="margin-left: 5px;">Asset: {{ getClassInstanceName() }}</h1>
    <h1
      *ngIf="role === 'HELP_SEEKER' && volunteer != null"
      style="margin-left: 5px;"
    >
      Volunteer: {{ volunteer.firstname }} {{ volunteer.lastname }}
    </h1> -->

    <mat-table
      matSort
      class="table"
      [dataSource]="tableDataSource"
      style="width: 100%"
      (matSortChange)="sortData($event)"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Name
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="values">
        <mat-header-cell *matHeaderCellDef> Wert </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span *ngIf="element.type === 'DATE'"
            >{{ element.values[0] | date: "dd.MM.yyyy HH:mm" }}
          </span>
          <span *ngIf="element.type != 'DATE'">{{ element.values }} </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef> Datentyp </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <span>{{ element.type }} </span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let element; let row; columns: displayedColumns"
      ></mat-row>
    </mat-table>
  </div>

  <p *ngIf="isLocal" style="margin-top: 20px">
    Die Informationen stammen aus dem lokalen Freiwilligenpass!
  </p>
</div>
