<link
  type="text/css"
  rel="stylesheet"
  href="https://fonts.googleapis.com/css?family=Muli|Roboto:300,400,500,700|Google+Sans"
/>

<div
  class="page-layout blank p-24"
  fusePerfectScrollbar
  style="overflow: scroll"
>
  <div style="padding-top: 12px"></div>

  <div
    class="header mat-accent-bg p-16 p-sm-24"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <h1>Mein FreiwilligenPASS</h1>
    <div
      style="position: relative; width: 120px; height: 120px; padding-top: 0px"
    >
      <img
        style="object-fit: contain; max-width: 100%; max-height: 100%"
        [src]="image"
      />
    </div>
  </div>

  <div style="padding-top: 20px"></div>

  <mat-card class="infoText">
    <h2 style="font-weight: 500">
      Verwalte deinen digitalen Freiwilligenpass und Organisationen.
    </h2>
    Synchronisiere Freiwilligenpass-Einträge in deinen digitalen
    Freiwilligenpass oder teile sie mit anderen Organisationen.
  </mat-card>

  <div style="padding-top: 10px"></div>

  <mat-card *ngIf="isLocalRepositoryConnected">
    <!-- Organisation Filter -->
    <div fxLayout="row" fxLayoutAlign="space-between start">
      <div
        fxLayout="column"
        fxLayoutAlign="space-between start"
        class="optionHeader"
      >
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <organisation-filter
            (tenantSelectionChanged)="tenantSelectionChanged($event)"
            *ngIf="subscribedTenants.length > 0"
          >
          </organisation-filter>

          <h2 *ngIf="subscribedTenants.length == 0">
            Du bist noch keiner Organisation beigetreten:
          </h2>
        </div>
        <div
          fxLayout="column"
          fxLayoutAlign="start   center"
          *ngIf="
            filteredClassInstanceDTOs.length > 0 &&
            isLocalRepositoryConnected &&
            selectedTenants.length > 0
          "
        >
          <div>FreiwilligenPass</div>
          <div fxLayout="row" fxLayoutAlign="end center">
            <button
              mat-raised-button
              class="syncButton"
              color="primary"
              (click)="syncAllToLocalRepository()"
            >
              Auswahl synchronisieren
              <mat-icon *ngIf="isAllSyncing">
                <mat-spinner color="accent" diameter="20"> </mat-spinner>
              </mat-icon>
            </button>

            <button
              mat-raised-button
              class="syncButton"
              color="primary"
              (click)="removeAllFromLocalRepository()"
              style="margin-left: 20px"
            >
              Auswahl desynchronisieren
              <mat-icon *ngIf="isAllDesyncing">
                <mat-spinner color="accent" diameter="20"> </mat-spinner>
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div fxLayout="column">
        <div class="vennParent optionHeader" id="container"></div>
      </div>
    </div>
  </mat-card>

  <div
    *ngIf="
      filteredClassInstanceDTOs.length > 0 &&
      isLocalRepositoryConnected &&
      selectedTenants.length > 0
    "
    class="mat-card p-20"
    fxLayout="column"
  >
    <div fxLayout="row" style="width: 100%" fxLayoutAlign="start center">
      <div class="pt-32"></div>

      <mat-table
        #table
        matSort
        class="localRepoTable"
        [dataSource]="dataSource"
        style="width: 100%"
        (matSortChange)="sortData($event)"
      >
        <ng-container matColumnDef="issuer">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Aussteller</mat-header-cell
          >
          <mat-cell *matCellDef="let entry">
            <div fxLayout="row" fxLayoutAligne="start center">
              <img class="avatar" [src]="getTenantImageById(entry.tenantId)" />
              <div style="align-self: center">
                {{ getIssuerName(entry.tenantId) }}
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="taskName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Name
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>{{ element.name }} </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="taskType1">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            Typ
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span>{{ element.taskType1 }} </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Datum</mat-header-cell
          >
          <mat-cell *matCellDef="let entry">
            {{ entry.dateFrom | date: "dd.MM.yyyy hh:mm" }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef mat-sort-header
            >Aktionen</mat-header-cell
          >
          <mat-cell *matCellDef="let entry">
            <mat-icon
              style="cursor: pointer"
              (click)="navigateToClassInstanceDetails(entry)"
              svgIcon="info"
              matTooltip="Details"
            ></mat-icon>

            <mat-icon
              *ngIf="!isInLocalRepository(entry)"
              (click)="syncOneToLocalRepository(entry)"
              svgIcon="plus"
              style="cursor: pointer; margin-left: 20px"
              matTooltip="Zum lokalen Freiwilligenpass hinzufügen"
            >
            </mat-icon>

            <mat-icon
              *ngIf="isInLocalRepository(entry)"
              (click)="removeOneFromLocalRepository(entry)"
              svgIcon="minus"
              style="cursor: pointer; margin-left: 20px"
              matTooltip="Vom lokalen Freiwilligenpass entfernen"
            >
            </mat-icon>

            <mat-icon
              *ngIf="isSynced(entry)"
              (click)="removeOneFromMarketplace(entry)"
              svgIcon="storeSlash"
              style="cursor: pointer; margin-left: 20px"
              matTooltip="Vom Marketplace entfernen"
            >
            </mat-icon>

            <div *ngIf="isInLocalRepository(entry)">
              <mat-icon
                svgIcon="share"
                style="cursor: pointer; margin-left: 20px"
                matTooltip="Teilen mit..."
                [matMenuTriggerFor]="shareMenu"
              >
              </mat-icon>
              <mat-menu
                #shareMenu="matMenu"
                xPosition="after"
                yPosition="below"
                [overlapTrigger]="false"
              >
                <ng-template matMenuContent>
                  <ng-container *ngIf="isSynced(entry)">
                    <button
                      *ngFor="let tenant of getShareableTenants(entry)"
                      mat-menu-item
                      (click)="shareClassInstanceToOthers(entry, tenant)"
                    >
                      <div fxLayout="row" fxLayoutAlign="start center">
                        <img class="avatar" [src]="tenant.imagePath" />
                        <div>{{ tenant.name }}</div>
                      </div>
                    </button>
                  </ng-container>
                  <button
                    *ngIf="
                      getShareableTenants(entry) &&
                      getShareableTenants(entry).length === 0
                    "
                    mat-menu-item
                  >
                    Bereits an alle geteilt!
                  </button>
                  <button
                    *ngIf="!isSynced(entry)"
                    mat-menu-item
                    (click)="shareClassInstanceToIssuer(entry)"
                  >
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <img
                        class="avatar"
                        [src]="getTenantImageById(entry.tenantId)"
                      />
                      <div>{{ getTenantName(entry.tenantId) }}</div>
                    </div>
                  </button>
                </ng-template>
              </mat-menu>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="share">
          <mat-header-cell *matHeaderCellDef> Geteilt mit </mat-header-cell>
          <mat-cell *matCellDef="let entry">
            <img
              *ngFor="let tenant of getSharedWithTenants(entry)"
              class="avatar"
              matTooltip="{{ tenant.name }}"
              [src]="getTenantImageById(tenant.id)"
              style="cursor: pointer"
              [matMenuTriggerFor]="revokeMenu"
              [matMenuTriggerData]="{ tenant: tenant }"
            />
            <mat-menu
              #revokeMenu="matMenu"
              xPosition="after"
              yPosition="below"
              [overlapTrigger]="false"
            >
              <ng-template matMenuContent let-tenant="tenant">
                <button
                  mat-menu-item
                  (click)="revokeSharedClassInstance(entry, tenant)"
                >
                  Zurückziehen
                </button>
              </ng-template>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row
          *matHeaderRowDef="displayedColumnsRepository"
        ></mat-header-row>
        <mat-row
          *matRowDef="let element; let row; columns: displayedColumnsRepository"
          [ngStyle]="getTableRowColor(element)"
        ></mat-row>
      </mat-table>
    </div>
  </div>

  <div *ngIf="!isLoaded" class="center-spinner-wrapper">
    <mat-spinner></mat-spinner>
  </div>

  <mat-paginator
    *ngIf="isLocalRepositoryConnected"
    [pageSize]="12"
    id="paginator"
    showFirstLastButtons
  >
  </mat-paginator>

  <div style="padding-top: 20px"></div>

  <p *ngIf="isLoaded && !isLocalRepositoryConnected" class="alert alert-info">
    <i class="fas fa-exclamation-circle"></i> &nbsp; Kein Speicherort für deinen
    Lokalen Freiwilligenpass ausgewählt, oder keine Verbindung möglich, siehe
    <a [routerLink]="['/main/profile']"> Profile</a>!
  </p>

  <p
    *ngIf="
      isLoaded &&
      selectedTenants.length === 0 &&
      isLocalRepositoryConnected &&
      subscribedTenants.length != 0
    "
    class="alert alert-info"
  >
    <i class="fas fa-exclamation-circle"></i> &nbsp; Keine Organisation
    ausgewählt!
  </p>

  <p
    *ngIf="
      isLoaded &&
      filteredClassInstanceDTOs.length === 0 &&
      selectedTenants.length > 0 &&
      isLocalRepositoryConnected
    "
    class="alert alert-info"
  >
    <i class="fas fa-exclamation-circle"></i> &nbsp; Es befinden sich noch keine
    Daten in deinem lokalen Freiwilligenpass!
  </p>
</div>
