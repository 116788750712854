"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var dialog_1 = require("@angular/material/dialog");
var ShareDialog = /** @class */ (function () {
    function ShareDialog(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.shareDone = false;
        this.duringShare = false;
        this.percentageValue = 0;
    }
    ShareDialog.prototype.onNoClick = function () {
        this.dialogRef.close();
    };
    ShareDialog.prototype.triggeredShare = function () {
        var _this = this;
        this.duringShare = true;
        setTimeout(function () {
            _this.duringShare = false;
            _this.shareDone = true;
        }, 2000);
    };
    return ShareDialog;
}());
exports.ShareDialog = ShareDialog;
