"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.environment = {
    production: true,
    hmr: false,
    REDIRECT_URI: "https://app-jku.ivolunteer.at/main/profile",
    JSON_SERVER_URL: "http://localhost:3000/repository",
    CORE_URL: "https://core.ivolunteer.at",
    CONFIGURATOR_URL: "https://config.ivolunteer.at"
};
