"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var task_list_component_1 = require("./task-list.component");
var routes = [{ path: "", component: task_list_component_1.FuseTaskListComponent }];
var FuseTaskListModule = /** @class */ (function () {
    function FuseTaskListModule() {
    }
    return FuseTaskListModule;
}());
exports.FuseTaskListModule = FuseTaskListModule;
