"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ActivationService = /** @class */ (function () {
    function ActivationService(http) {
        this.http = http;
    }
    ActivationService.prototype.activate = function (activationId) {
        return this.http.post(environment_1.environment.CORE_URL + "/auth/activation/" + activationId, "");
    };
    ActivationService.prototype.createActivationLink = function (username, type) {
        return this.http.post(environment_1.environment.CORE_URL + "/auth/activation/generate-link/" + username + "/user?type=" + type, "");
    };
    ActivationService.prototype.createActivationLinkViaEmail = function (email, type) {
        return this.http.post(environment_1.environment.CORE_URL + "/auth/activation/generate-link/email?type=" + type, email);
    };
    ActivationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ActivationService_Factory() { return new ActivationService(i0.ɵɵinject(i1.HttpClient)); }, token: ActivationService, providedIn: "root" });
    return ActivationService;
}());
exports.ActivationService = ActivationService;
