<!--Share Icon and Menu-->
<div>
    <button mat-mini-fab type="button" color="primary" [matMenuTriggerFor]="shareMenu">
      <mat-icon>share</mat-icon>
    </button>
    <mat-menu #shareMenu="matMenu" xPosition="after" yPosition="below" [overlapTrigger]="false">
      <mat-label mat-menu-item disabled style="color: black">Freigeben an...</mat-label>
      <button mat-menu-item (click)="exportChart()" matTooltip="Recruiter: 'Best Jobs'">
        <div fxLayout="row" fxLayoutAlign="start center">
          <img class="avatar" src="../../../../assets/images/logos/recruiter.jpg">
          <div>Recruiter: 'Best Jobs'</div>
        </div>
      </button>
      <button mat-menu-item>
        <div fxLayout="row" fxLayoutAlign="start center">
          <img class="avatar" src="../../../../assets/images/logos/f_logo_RGB-Blue_1024.png">
          <div>Facebook</div>
        </div>
      </button>
      <button mat-menu-item>
        <div fxLayout="row" fxLayoutAlign="start center">
          <img class="avatar" src="../../../../assets/images/logos/Twitter_Logo_WhiteOnBlue.svg">
          <div>Twitter</div>
        </div>
      </button>
    </mat-menu>
  </div>
  <!--END Share Icon and Menu-->