"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var file_service_1 = require("./file.service");
var util_1 = require("util");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./file.service");
var CoreUserService = /** @class */ (function () {
    function CoreUserService(http, fileService) {
        this.http = http;
        this.fileService = fileService;
    }
    CoreUserService.prototype.findAll = function () {
        return this.http.get(environment_1.environment.CORE_URL + "/user/all");
    };
    CoreUserService.prototype.findAllByTenantId = function (tenantId) {
        return this.http.get(environment_1.environment.CORE_URL + "/user/all/tenant/" + tenantId);
    };
    CoreUserService.prototype.findAllByRole = function (role) {
        return this.http.get(environment_1.environment.CORE_URL + "/user/all/role/" + role);
    };
    CoreUserService.prototype.findAllByRoles = function (roles, includeNoRole) {
        return this.http.put(environment_1.environment.CORE_URL + "/user/all/roles?includeNoRole=" + includeNoRole, roles);
    };
    CoreUserService.prototype.findAllByRoleAndTenantId = function (tenantId, role) {
        return this.http.get(environment_1.environment.CORE_URL + "/user/all/role/" + role + "/tenant/" + tenantId);
    };
    CoreUserService.prototype.findById = function (userId) {
        return this.http.get(environment_1.environment.CORE_URL + "/user/" + userId);
    };
    CoreUserService.prototype.findByUsername = function (username) {
        return this.http.get(environment_1.environment.CORE_URL + "/user/name/" + username);
    };
    CoreUserService.prototype.findByIds = function (userIds) {
        return this.http.put(environment_1.environment.CORE_URL + "/user/find-by-ids", userIds);
    };
    CoreUserService.prototype.findRegisteredMarketplaces = function (userId) {
        return this.http.get(environment_1.environment.CORE_URL + "/user/" + userId + "/marketplaces");
    };
    CoreUserService.prototype.getUserProfileImage = function (user) {
        if (util_1.isNullOrUndefined(user.profileImagePath)) {
            return "/assets/images/avatars/profile.jpg";
        }
        return user.profileImagePath;
    };
    CoreUserService.prototype.registerMarketplace = function (userId, marketplaceId) {
        return this.http.post(environment_1.environment.CORE_URL + "/user/" + userId + "/register/" + marketplaceId, {});
    };
    // createUser(user: User, updateMarketplaces: boolean) {
    //   return this.http.post(
    //     `${environment.CORE_URL}/user/new?updateMarketplaces=${updateMarketplaces}`,
    //     user
    //   );
    // }
    CoreUserService.prototype.updateUser = function (user, updateMarketplaces) {
        return this.http.put(environment_1.environment.CORE_URL + "/user/update?updateMarketplaces=" + updateMarketplaces, user);
    };
    CoreUserService.prototype.subscribeUserToTenant = function (tenantId, role) {
        return this.http.put(environment_1.environment.CORE_URL + "/user/subscribe/" + tenantId, role, {
            headers: new http_1.HttpHeaders({ "Content-Type": "application/json" })
        });
    };
    CoreUserService.prototype.unsubscribeUserFromTenant = function (tenantId, role) {
        return this.http.put(environment_1.environment.CORE_URL + "/user/unsubscribe/" + tenantId, role, {
            headers: new http_1.HttpHeaders({ "Content-Type": "application/json" })
        });
    };
    CoreUserService.prototype.subscribeOtherUserToTenant = function (tenantId, role, userId) {
        return this.http.put(environment_1.environment.CORE_URL + "/user/subscribe/" + tenantId + "/user/" + userId, role, { headers: new http_1.HttpHeaders({ "Content-Type": "application/json" }) });
    };
    CoreUserService.prototype.unsubscribeOtherUserFromTenant = function (tenantId, role, userId) {
        return this.http.put(environment_1.environment.CORE_URL + "/user/unsubscribe/" + tenantId + "/user/" + userId, role, { headers: new http_1.HttpHeaders({ "Content-Type": "application/json" }) });
    };
    CoreUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CoreUserService_Factory() { return new CoreUserService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FileService)); }, token: CoreUserService, providedIn: "root" });
    return CoreUserService;
}());
exports.CoreUserService = CoreUserService;
