"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var config_service_1 = require("@fuse/services/config.service");
var router_1 = require("@angular/router");
var registration_service_1 = require("app/main/content/_service/registration.service");
var user_1 = require("app/main/content/_model/user");
var equals_validator_1 = require("app/main/content/_validator/equals.validator");
var string_unique_validator_1 = require("app/main/content/_validator/string-unique.validator");
var OrganizationRegistrationComponent = /** @class */ (function () {
    function OrganizationRegistrationComponent(fuseConfig, formBuilder, router, registrationService) {
        this.fuseConfig = fuseConfig;
        this.formBuilder = formBuilder;
        this.router = router;
        this.registrationService = registrationService;
        this.today = new Date();
        var layout = {
            navigation: 'none',
            toolbar: 'none',
            footer: 'none',
        };
        this.fuseConfig.setConfig({ layout: layout });
        this.registrationFormErrors = {
            username: {},
            password: {},
            confirmPassword: {},
            email: {},
            organizationName: {},
            organizationPosition: {},
            formOfAddress: {},
            titleBefore: {},
            firstName: {},
            lastName: {},
            titleAfter: {},
        };
    }
    OrganizationRegistrationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.registrationForm = this.formBuilder.group({
            username: new forms_1.FormControl('', forms_1.Validators.required),
            password: new forms_1.FormControl('', forms_1.Validators.required),
            confirmPassword: new forms_1.FormControl(''),
            email: new forms_1.FormControl('', [forms_1.Validators.required, forms_1.Validators.pattern(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)]),
            organizationName: new forms_1.FormControl('', forms_1.Validators.required),
            organizationPosition: new forms_1.FormControl('', forms_1.Validators.required),
            formOfAddress: new forms_1.FormControl('', forms_1.Validators.required),
            titleBefore: new forms_1.FormControl(''),
            firstName: new forms_1.FormControl('', forms_1.Validators.required),
            lastName: new forms_1.FormControl('', forms_1.Validators.required),
            titleAfter: new forms_1.FormControl(''),
        });
        this.registrationForm.valueChanges.subscribe(function () {
            _this.onRegistrationFormValuesChanged();
        });
        this.registrationForm.controls['confirmPassword'].setValidators([forms_1.Validators.required, equals_validator_1.equals(this.registrationForm.controls['password'], this.registrationForm.controls['confirmPassword'])]);
    };
    OrganizationRegistrationComponent.prototype.onRegistrationFormValuesChanged = function () {
        for (var field in this.registrationFormErrors) {
            if (!this.registrationFormErrors.hasOwnProperty(field)) {
                continue;
            }
            // Clear previous errors
            this.registrationFormErrors[field] = {};
            // Get the control
            var control = this.registrationForm.get(field);
            if (field === 'confirmPassword' || field === 'password' || field === 'email' || field === 'username') {
                control.updateValueAndValidity({ onlySelf: true });
            }
            if (control && control.dirty && !control.valid) {
                this.registrationFormErrors[field] = control.errors;
            }
        }
    };
    OrganizationRegistrationComponent.prototype.register = function () {
        var _this = this;
        if (!this.registrationForm.valid) {
            return;
        }
        var tenantAdmin = new user_1.User();
        tenantAdmin.username = this.registrationForm.value.username;
        tenantAdmin.password = this.registrationForm.value.password;
        tenantAdmin.loginEmail = this.registrationForm.value.email;
        tenantAdmin.emails = [];
        tenantAdmin.emails.push(this.registrationForm.value.email);
        tenantAdmin.organizationName = this.registrationForm.value.organizationName;
        tenantAdmin.organizationPosition = this.registrationForm.value.organizationPosition;
        tenantAdmin.titleBefore = this.registrationForm.value.titleBefore;
        tenantAdmin.firstname = this.registrationForm.value.firstName;
        tenantAdmin.lastname = this.registrationForm.value.lastName;
        tenantAdmin.titleAfter = this.registrationForm.value.titleAfter;
        this.registrationService.registerUser(tenantAdmin, user_1.AccountType.ORGANIZATION)
            .toPromise().then(function (response) {
            _this.loginFormWrapper.nativeElement.scrollTo(0, 0);
            _this.displaySuccess = true;
        }).catch(function (response) {
            if (response.error.response === 'USERNAME') {
                _this.registrationForm.controls['username'].setValidators([forms_1.Validators.required, string_unique_validator_1.stringUniqueValidator([tenantAdmin.username])]);
            }
            if (response.error.response === 'EMAIL') {
                _this.registrationForm.controls['email'].setValidators([forms_1.Validators.required, string_unique_validator_1.stringUniqueValidator([tenantAdmin.loginEmail])]);
            }
            _this.onRegistrationFormValuesChanged();
        });
    };
    OrganizationRegistrationComponent.prototype.handleBackClick = function () {
        window.history.back();
    };
    OrganizationRegistrationComponent.prototype.handleBackToLoginClick = function () {
        this.router.navigate(['/login']);
    };
    return OrganizationRegistrationComponent;
}());
exports.OrganizationRegistrationComponent = OrganizationRegistrationComponent;
