"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var registration_component_1 = require("./registration.component");
var routes = [{ path: "", component: registration_component_1.OrganizationRegistrationComponent }];
var OrganizationRegistrationModule = /** @class */ (function () {
    function OrganizationRegistrationModule() {
    }
    return OrganizationRegistrationModule;
}());
exports.OrganizationRegistrationModule = OrganizationRegistrationModule;
