<link type="text/css" rel="stylesheet"
  href="https://fonts.googleapis.com/css?family=Muli|Roboto:300,400,500,700|Google+Sans" />

<div *ngIf="loaded" class="page-layout blank p-24" fusePerfectScrollbar>
  <customizable-header *ngIf="tenant" [tenant]="tenant" [displayNavigateBack]="false"
    [headerText]="tenant.landingpageMessage"></customizable-header>

  <!-- Landing Pages.. TODO Remove/Replace someday -->

  <section fxLayout="column" class="p-40">
    <h2 class="verein-heading">{{tenant.landingpageTitle}}</h2>

    <img *ngIf="image" [src]="image" alt="Landing Page Titelbild" />

    <p>{{tenant.landingpageText}}</p>
  </section>
</div>