"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var marketplace_1 = require("app/main/content/_model/marketplace");
var stored_chart_service_1 = require("app/main/content/_service/stored-chart.service");
var moment = require("moment");
var stored_chart_1 = require("app/main/content/_model/stored-chart");
var user_1 = require("app/main/content/_model/user");
var DonutComponent = /** @class */ (function () {
    function DonutComponent(storedChartService) {
        this.storedChartService = storedChartService;
        this.animation = true;
        this.labels = false;
        this.tooltipDisabled = false;
        this.legend = false;
        this.explodeSlices = false;
        this.doughnut = true;
        this.arcWidth = "0.6";
        this.scheme = "cool";
    }
    DonutComponent.prototype.ngOnInit = function () { };
    DonutComponent.prototype.ngOnChanges = function (changes) {
        // console.error('donut changes', changes);
        var changed = false;
        for (var propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case "classInstanceDTOs": {
                        if (typeof changes.classInstanceDTOs.currentValue != "undefined") {
                            this.classInstanceDTOs = changes.classInstanceDTOs.currentValue;
                            var list = this.classInstanceDTOs.map(function (ci) {
                                return {
                                    tt1: ci.taskType1,
                                    tt2: ci.taskType2,
                                    tt3: ci.taskType3,
                                };
                            });
                            this.uniqueTt1 = __spread(new Set(list.map(function (item) { return item.tt1; })));
                            this.uniqueTt2 = __spread(new Set(list.map(function (item) { return item.tt2; })));
                            // this.filteredClassInstanceDTOs = this.filteredClassInstanceDTOs.sort((a, b) => {
                            //   return new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime();
                            // });
                            changed = true;
                        }
                        break;
                    }
                    case "selectedYaxis": {
                        if (typeof this.selectedYaxis != "undefined") {
                            this.selectedYaxis = changes.selectedYaxis.currentValue;
                            changed = true;
                        }
                        break;
                    }
                    case "selectedYear": {
                        if (typeof changes.selectedYear.currentValue != "undefined") {
                            this.selectedYear = changes.selectedYear.currentValue;
                            changed = true;
                        }
                        break;
                    }
                    case "selectedTaskType": {
                        if (typeof changes.selectedTaskType.currentValue != "undefined") {
                            this.selectedTaskType = changes.selectedTaskType.currentValue;
                            changed = true;
                        }
                        break;
                    }
                    case "timelineFilter":
                        {
                            if (typeof changes.timelineFilter.currentValue != "undefined") {
                                this.timelineFilter = changes.timelineFilter.currentValue;
                                changed = true;
                            }
                        }
                        break;
                }
            }
            if (changed) {
                this.generateData();
            }
        }
    };
    DonutComponent.prototype.generateData = function () {
        var _this = this;
        // filter everything here
        if (this.timelineFilter.from == null) {
            if (this.selectedYear === "Gesamt") {
                this.filteredClassInstanceDTOs = __spread(this.classInstanceDTOs);
            }
            else {
                this.filteredClassInstanceDTOs = this.classInstanceDTOs.filter(function (c) {
                    return moment(c.dateFrom).isSame(moment(_this.selectedYear), "year");
                });
            }
        }
        else {
            this.filteredClassInstanceDTOs = this.classInstanceDTOs.filter(function (c) {
                return (moment(c.dateFrom).isSameOrAfter(moment(_this.timelineFilter.from), "day") &&
                    moment(c.dateFrom).isSameOrBefore(moment(_this.timelineFilter.to), "day"));
            });
        }
        if (this.selectedTaskType != null) {
            if (this.uniqueTt1.indexOf(this.selectedTaskType) > -1) {
                this.filteredClassInstanceDTOs = this.filteredClassInstanceDTOs.filter(function (c) {
                    return c.taskType1 === _this.selectedTaskType;
                });
            }
            else if (this.uniqueTt2.indexOf(this.selectedTaskType) > -1) {
                this.filteredClassInstanceDTOs = this.filteredClassInstanceDTOs.filter(function (c) {
                    return c.taskType2 === _this.selectedTaskType;
                });
            }
        }
        switch (this.type) {
            case "Wochentag":
                this.generateWeekdayData();
                break;
            case "Tageszeit":
                this.generateDayTimeData();
                break;
            case "Orte":
                this.generatePlacesData();
                break;
            case "Rang":
                this.generateRankData();
                break;
        }
    };
    DonutComponent.prototype.generateWeekdayData = function () {
        var _this = this;
        var weekdayList = this.filteredClassInstanceDTOs.map(function (ci) {
            var value;
            _this.selectedYaxis === "Anzahl" ? (value = 1) : (value = ci.duration);
            return {
                weekday: moment(ci.dateFrom).locale("de").format("dddd"),
                value: value,
            };
        });
        var weekdayMap = new Map();
        weekdayList.forEach(function (t) {
            if (weekdayMap.get(t.weekday)) {
                weekdayMap.set(t.weekday, Number(weekdayMap.get(t.weekday)) + Number(t.value));
            }
            else {
                weekdayMap.set(t.weekday, Number(t.value));
            }
        });
        var data = [];
        Array.from(weekdayMap.entries()).forEach(function (entry) {
            if (entry[0] != null && entry[1] != null && !isNaN(entry[1])) {
                data.push({ name: entry[0], value: entry[1] });
            }
        });
        this.donutData = __spread(data);
    };
    DonutComponent.prototype.generatePlacesData = function () {
        var _this = this;
        var placesList = this.filteredClassInstanceDTOs.map(function (ci) {
            var value;
            _this.selectedYaxis === "Anzahl" ? (value = 1) : (value = ci.duration);
            return { location: ci.location, value: value };
        });
        var locationMap = new Map();
        placesList.forEach(function (t) {
            if (locationMap.get(t.location)) {
                locationMap.set(t.location, Number(locationMap.get(t.location)) + Number(t.value));
            }
            else {
                locationMap.set(t.location, Number(t.value));
            }
        });
        var data = [];
        Array.from(locationMap.entries()).forEach(function (entry) {
            if (entry[0] != null && entry[1] != null && !isNaN(entry[1])) {
                if (entry[0] === "") {
                    data.push({ name: "keine Angabe", value: Number(entry[1]) });
                }
                else {
                    data.push({ name: entry[0], value: Number(entry[1]) });
                }
            }
        });
        data.sort(function (a, b) { return b.value - a.value; });
        var data2 = data.slice(0, 12);
        this.donutData = __spread(data2);
    };
    DonutComponent.prototype.generateDayTimeData = function () {
        var _this = this;
        var daytimeList = this.filteredClassInstanceDTOs.map(function (ci) {
            var value;
            _this.selectedYaxis === "Anzahl" ? (value = 1) : (value = ci.duration);
            var key;
            var hours = new Date(ci.dateFrom).getHours();
            hours >= 7 && hours <= 18 ? (key = "Tag") : (key = "Nacht");
            return { dayNight: key, value: value };
        });
        var dayNightMap = new Map();
        daytimeList.forEach(function (t) {
            if (dayNightMap.get(t.dayNight)) {
                dayNightMap.set(t.dayNight, Number(dayNightMap.get(t.dayNight)) + Number(t.value));
            }
            else {
                dayNightMap.set(t.dayNight, Number(t.value));
            }
        });
        var data = [];
        Array.from(dayNightMap.entries()).forEach(function (entry) {
            if (entry[0] != null && entry[1] != null && !isNaN(entry[1])) {
                data.push({ name: entry[0], value: entry[1] });
            }
        });
        this.donutData = __spread(data);
    };
    DonutComponent.prototype.generateRankData = function () {
        var _this = this;
        var rankList = this.filteredClassInstanceDTOs.map(function (ci) {
            var value;
            _this.selectedYaxis === "Anzahl" ? (value = 1) : (value = ci.duration);
            return { rang: ci.rank, value: value };
        });
        var rangMap = new Map();
        rankList.forEach(function (t) {
            if (rangMap.get(t.rang)) {
                rangMap.set(t.rang, Number(rangMap.get(t.rang)) + Number(t.value));
            }
            else {
                rangMap.set(t.rang, Number(t.value));
            }
        });
        var data = [];
        var empty = 0;
        Array.from(rangMap.entries()).forEach(function (entry) {
            if (entry[0] != null && entry[1] != null && !isNaN(entry[1])) {
                if (entry[0] === "") {
                    data.push({ name: "keine Angabe", value: Number(entry[1]) });
                }
                else {
                    data.push({ name: entry[0], value: Number(entry[1]) });
                }
            }
        });
        this.donutData = __spread(data);
    };
    DonutComponent.prototype.onDonutSelect = function (event) { };
    DonutComponent.prototype.exportChart = function () {
        var storedChart;
        storedChart = new stored_chart_1.StoredChart(this.type, "ngx-charts-pie-chart", JSON.stringify(this.donutData), this.volunteer.id);
        this.storedChartService.save(this.marketplace, storedChart).toPromise();
    };
    return DonutComponent;
}());
exports.DonutComponent = DonutComponent;
