"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var FuseWidgetToggleDirective = /** @class */ (function () {
    function FuseWidgetToggleDirective(el) {
        this.el = el;
    }
    return FuseWidgetToggleDirective;
}());
exports.FuseWidgetToggleDirective = FuseWidgetToggleDirective;
