"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".login[_ngcontent-%COMP%]{margin-top:20px}.input[_ngcontent-%COMP%]{min-width:450px}.logo[_ngcontent-%COMP%]{height:70px;margin-bottom:5px}.radioButtons[_ngcontent-%COMP%]{display:flex;flex-direction:row;margin:15px 0}mat-radio-button[_ngcontent-%COMP%]{margin-left:20px}"];
exports.styles = styles;
