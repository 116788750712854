"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var class_instance_service_1 = require("app/main/content/_service/meta/core/class/class-instance.service");
var login_service_1 = require("app/main/content/_service/login.service");
var user_1 = require("app/main/content/_model/user");
var material_1 = require("@angular/material");
var core_tenant_service_1 = require("app/main/content/_service/core-tenant.service");
var local_repository_jsonServer_service_1 = require("app/main/content/_service/local-repository-jsonServer.service");
var user_service_1 = require("app/main/content/_service/user.service");
var local_repository_dropbox_service_1 = require("app/main/content/_service/local-repository-dropbox.service");
var ClassInstanceDetailsComponent = /** @class */ (function () {
    function ClassInstanceDetailsComponent(route, classInstanceService, tenantService, userService, loginService, lrDropboxService, lrJsonServerService, data) {
        var _this = this;
        this.route = route;
        this.classInstanceService = classInstanceService;
        this.tenantService = tenantService;
        this.userService = userService;
        this.loginService = loginService;
        this.lrDropboxService = lrDropboxService;
        this.lrJsonServerService = lrJsonServerService;
        this.data = data;
        this.id = null;
        this.isDialog = false;
        this.isLocal = false;
        this.tableDataSource = new material_1.MatTableDataSource();
        this.displayedColumns = ["name", "values", "type"];
        this.route.params.subscribe(function (params) {
            _this.id = params.id;
        });
    }
    ClassInstanceDetailsComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var globalInfo, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!this.id) {
                            this.id = this.data.id;
                            this.isDialog = true;
                        }
                        return [4 /*yield*/, this.loginService.getGlobalInfo().toPromise()];
                    case 1:
                        globalInfo = (_d.sent());
                        this.user = globalInfo.user;
                        this.role = globalInfo.userRole;
                        this.marketplace = globalInfo.marketplace;
                        this.tenant = globalInfo.tenants[0];
                        _a = this;
                        return [4 /*yield*/, this.classInstanceService
                                .getClassInstanceById(this.marketplace, this.id)
                                .toPromise()];
                    case 2:
                        _a.classInstance = (_d.sent());
                        if (!(this.classInstance === null)) return [3 /*break*/, 4];
                        if (!(this.role === user_1.UserRole.VOLUNTEER)) return [3 /*break*/, 4];
                        this.localRepositoryService = this.loginService.getLocalRepositoryService(this.user);
                        _b = this;
                        return [4 /*yield*/, this.localRepositoryService
                                .getSingleClassInstance(this.user, this.id)
                                .toPromise()];
                    case 3:
                        _b.classInstance = (_d.sent());
                        this.isLocal = true;
                        _d.label = 4;
                    case 4:
                        if (!(this.role === user_1.UserRole.HELP_SEEKER || user_1.UserRole.TENANT_ADMIN)) return [3 /*break*/, 6];
                        _c = this;
                        return [4 /*yield*/, this.userService
                                .findById(this.marketplace, this.classInstance.userId)
                                .toPromise()];
                    case 5:
                        _c.volunteer = (_d.sent());
                        _d.label = 6;
                    case 6:
                        this.tableDataSource.data = this.classInstance.properties;
                        this.tenantService.initHeader(this.tenant);
                        return [2 /*return*/];
                }
            });
        });
    };
    ClassInstanceDetailsComponent.prototype.getClassInstanceName = function () {
        return this.classInstance.properties.find(function (p) { return p.name === "Name"; })
            .values[0];
    };
    ClassInstanceDetailsComponent.prototype.getVolunteerName = function (userId) { };
    ClassInstanceDetailsComponent.prototype.sortData = function (sort) {
        var _this = this;
        this.tableDataSource.data = this.tableDataSource.data.sort(function (a, b) {
            var isAsc = sort.direction === "asc";
            switch (sort.active) {
                case "name":
                    return _this.compare(a.name, b.name, isAsc);
                default:
                    return 0;
            }
        });
    };
    ClassInstanceDetailsComponent.prototype.compare = function (a, b, isAsc) {
        if (typeof a === "string" && typeof b === "string") {
            return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (isAsc ? 1 : -1);
        }
        else {
            return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
        }
    };
    return ClassInstanceDetailsComponent;
}());
exports.ClassInstanceDetailsComponent = ClassInstanceDetailsComponent;
