<div *ngIf="loaded">
  <form form class="tenant-form" fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto" [formGroup]="tenantForm">
    <div fxLayout="column">

      <h2 class="mt-0">Zur Organisation selbst</h2>

      <mat-form-field>
        <input matInput type="text" formControlName="name" placeholder="Name der Organisation" required />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <textarea matInput type="text" formControlName="description" placeholder="Kurzbeschreibung der Organisation" matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=10 required></textarea>
      </mat-form-field>

      <mat-form-field>
        <input matInput type="url" formControlName="homepage" placeholder="Homepage" />
      </mat-form-field>

      <h2>Gestaltungsoptionen</h2>

      <h3>Allgemein</h3>

      <div fxLayout="row" fxLayoutAlign="start center">
        <div class="color-field section-border">
          <div class="color-field-label">
            Farbe 1</div>
          <div class="color-input">
            <input type="color" formControlName="primaryColor" />
          </div>
        </div>
        <div class="color-field ml-8 section-border">
          <div class="color-field-label">
            Farbe 2</div>
          <div class="color-input">
            <input type="color" formControlName="secondaryColor" />
          </div>
        </div>
      </div>

      <tenant-profile-image-upload *ngIf="showProfileImageForm" [tenant]="tenant" (uploadedImage)="handleProfileImageUploadEvent($event)">
      </tenant-profile-image-upload>


      <h3>Landing Page</h3>


      <mat-form-field>
        <input matInput type="text" formControlName="landingpageMessage" placeholder="Begrüßungstext der im Header angezeigt werden soll" />
      </mat-form-field>

      <mat-form-field>
        <input matInput type="text" formControlName="landingpageTitle" placeholder="Titel im Content-Bereich" />
      </mat-form-field>


      <mat-form-field appearance="outline">
        <textarea matInput type="text" formControlName="landingpageText" placeholder="Kurzer Fließtext" matTextareaAutosize matAutosizeMinRows=5 matAutosizeMaxRows=10></textarea>
      </mat-form-field>


      <tenant-landing-page-image-upload *ngIf="showLandingPageImageForm" [tenant]="tenant" (uploadedImage)="handleLandingPageImageUploadEvent($event)">
      </tenant-landing-page-image-upload>


      <h2>Zuordnungsoptionen</h2>

      <tenant-tag-form [addedTags]="addedTags" (tags)="addedTags = $event"></tenant-tag-form>

      <button class="primary-button-dark mt-32" type="button" (click)="save()" mat-stroked-button [disabled]="tenantForm.disabled">Speichern</button>
    </div>
  </form>
</div>