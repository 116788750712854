"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var marketplace_list_component_1 = require("./marketplace-list.component");
var routes = [{ path: "", component: marketplace_list_component_1.FuseMarketplaceListComponent }];
var FuseMarketplaceListModule = /** @class */ (function () {
    function FuseMarketplaceListModule() {
    }
    return FuseMarketplaceListModule;
}());
exports.FuseMarketplaceListModule = FuseMarketplaceListModule;
