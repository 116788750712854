<div id="achievements" class="page-layout blank p-24" fusePerfectScrollbar>
  <div class="header-management-summary p-16 p-sm-24" fxLayout="row" fxLayoutAlign="center center">
    <h1>Mein FreiwilligenÜBERBLICK</h1>
  </div>
  <!-- HEADER END -->

  <div *ngIf="!isLoaded" class="center-spinner-wrapper">
    <mat-spinner></mat-spinner>
  </div>

  <div *ngIf="isLoaded">
    <div style="height: 20px;"></div>

    <div *ngIf="classInstanceDTOs.length != 0">
      <div fxLayout="row wrap" fxLayoutAlign="space-around">
        <div *ngFor="let tenant of uniqueTenants">
          <div fxLayout="column" style="width: 100%;">
            <h2 style="font-size: 30px;">{{ tenant.name }}</h2>
            <div fxLayout="row">
              <img [src]="tenant.imagePath" class="img" />
              <table class="table">
                <tbody>
                  <tr>
                    <td>Homepage:</td>
                    <td>{{ tenant.homepage }}</td>
                  </tr>
                  <tr>
                    <td>Daten seit:</td>
                    <td>{{ getMemberSince(tenant.id) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- LOGOS END -->

    <div style="height: 10px;"></div>
    <p *ngIf="isLoaded && !isLocalRepositoryConnected" class="alert alert-info">
      <i class="fas fa-exclamation-circle"></i> &nbsp; Lokaler Freiwilligenpass nicht erreichbar, deshalb stammen die Daten vom Marktplatz!
    </p>

    <!-- CHARTS BEGINN -->
    <div style="height: 30px;"></div>
    <div *ngIf="isLoaded && classInstanceDTOs.length > 0">
      <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
        <h1 class="engagement">Engagement gesamt</h1>
        <p class="engagementSince">{{ getEngagementSince() }}</p>
      </div>
      <div fxLayout="column" fxLayoutAlign="start center" style="width: 100%;">
        <div fxLayout="column" fxLayoutAlign="start center">
          <div>
            <div class="donut-parent-duration" *ngIf="durationTotal.length != 0">
              <ngx-charts-advanced-pie-chart class="chart-container" [results]="durationTotal" [animations]="animations" [tooltipDisabled]="false" [customColors]="colors" [valueFormatting]="valueFormattingDuration">
              </ngx-charts-advanced-pie-chart>
            </div>
          </div>
          <div>
            <div class="donut-parent-duration" *ngIf="numberTotal.length != 0">
              <ngx-charts-advanced-pie-chart class="chart-container" [results]="numberTotal" [animations]="animations" [tooltipDisabled]="false" [customColors]="colors" [valueFormatting]="valueFormattingNumber">
              </ngx-charts-advanced-pie-chart>
            </div>
          </div>
        </div>

        <div style="height: 50px;"></div>

        <div style="width: 100%;" fxLayout="row" fxLayoutAlign="start center">
          <h1 class="engagement">Engagement</h1>
          <mat-form-field>
            <mat-select class="formFieldEngament" [value]="engagementYear" (selectionChange)="generateEngagementYearData($event.value)">
              <mat-option *ngFor="let year of uniqueYears" [value]="year">{{ year }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="column" fxLayoutAlign="start center">
          <div>
            <div class="donut-parent-duration" *ngIf="durationYear.length != 0">
              <ngx-charts-advanced-pie-chart class="chart-container" [results]="durationYear" [animations]="animations" [tooltipDisabled]="false" [customColors]="colors" [valueFormatting]="valueFormattingDuration">
              </ngx-charts-advanced-pie-chart>
            </div>
          </div>
          <div>
            <div class="donut-parent-duration" *ngIf="numberYear.length != 0">
              <ngx-charts-advanced-pie-chart class="chart-container" [results]="numberYear" [animations]="animations" [tooltipDisabled]="false" [customColors]="colors" [valueFormatting]="valueFormattingNumber">
              </ngx-charts-advanced-pie-chart>
            </div>
          </div>
        </div>
      </div>

      <div style="height: 30px;"></div>

      <div style="width: 100%;">
        <h1 class="engagement">Jahresvergleich Tätigkeiten</h1>
        <div style="height: 10px;"></div>

        <div *ngIf="uniqueYears.length >= 2">
          <div fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field class="formFieldComparison">
              <mat-label>Vergleichsjahr</mat-label>

              <mat-select [value]="comparisonYear" (selectionChange)="generateComparisonChartData($event.value)">
                <mat-option *ngFor="let year of uniqueYears" [value]="year">{{ year }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div style="width: 50px;"></div>
            <app-share-menu class="shareMenu" [label]="'Vergleich'" (export)="exportChart($event)">
            </app-share-menu>
          </div>

          <div class="chartParrent">
            <ngx-charts-bar-vertical-2d [results]="comparisonData" [customColors]="colors" [animations]="animations" [xAxis]="true" [yAxis]="true" [showXAxisLabel]="true" [showYAxisLabel]="true" [xAxisLabel]="comparisonXlabel" [yAxisLabel]="comparisonYlabel" [roundEdges]="false"
              [legend]="true" [legendTitle]="Legende" [showDataLabel]="true" [noBarWhenZero]="false">
            </ngx-charts-bar-vertical-2d>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p *ngIf="isLoaded && uniqueYears.length < 2" class="alert alert-info">
    <i class="fas fa-exclamation-circle"></i> &nbsp; Kein Vergleich möglich, da zu wenig Daten über mehrere Jahre vorhanden sind!
  </p>

  <p *ngIf="isLoaded && subscribedTenants.length === 0" class="alert alert-info">
    <i class="fas fa-exclamation-circle"></i> &nbsp; Noch keiner Organisation beigetreten!
  </p>

  <p *ngIf="
      isLoaded &&
      classInstanceDTOs.length === 0 &&
      isLocalRepositoryConnected &&
      uniqueTenants.length > 0
    " class="alert alert-info">
    <i class="fas fa-exclamation-circle"></i> &nbsp; Es befinden sich noch keine Daten in deinem lokalen Freiwilligenpass!
  </p>

  <mat-card *ngIf="isLoaded && percentageFilteredOut > 0">
    {{ percentageFilteredOut | number: "1.0-2":"de-AT" }} % der Daten können nicht visualisiert werden, da essenzielle Felder fehlen (z.B. Datum, Dauer, oder Tasktyp)!
  </mat-card>
</div>