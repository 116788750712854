export const navigation_recruiter = [
  {
    'id': 'jobs',
    'title': 'Jobangebote',
    'type': 'item',
    'icon': 'dashboard',
    'url': '/main/jobs'
  },
  {
    'id': 'recruitment',
    'title': 'Bewerbungen',
    'type': 'item',
    'icon': 'dashboard',
    'url': '/main/recruitment'
  },
];
