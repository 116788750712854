"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var activation_component_1 = require("./activation.component");
var routes = [{ path: ':activationId', component: activation_component_1.ActivationComponent }];
var ActivationModule = /** @class */ (function () {
    function ActivationModule() {
    }
    return ActivationModule;
}());
exports.ActivationModule = ActivationModule;
