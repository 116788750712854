"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var class_instance_details_component_1 = require("./class-instance-details.component");
var routes = [{ path: ":id", component: class_instance_details_component_1.ClassInstanceDetailsComponent }];
var ɵ0 = {}, ɵ1 = {};
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
var ClassInstanceDetailsModule = /** @class */ (function () {
    function ClassInstanceDetailsModule() {
    }
    return ClassInstanceDetailsModule;
}());
exports.ClassInstanceDetailsModule = ClassInstanceDetailsModule;
