"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./configurator.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory");
var i3 = require("@angular/material/progress-spinner");
var i4 = require("@angular/cdk/platform");
var i5 = require("@angular/common");
var i6 = require("@angular/platform-browser/animations");
var i7 = require("./configurator.component");
var i8 = require("../../../../_service/login.service");
var i9 = require("@angular/platform-browser");
var styles_ConfiguratorComponent = [i0.styles];
var RenderType_ConfiguratorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfiguratorComponent, data: {} });
exports.RenderType_ConfiguratorComponent = RenderType_ConfiguratorComponent;
function View_ConfiguratorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "center-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i2.View_MatSpinner_0, i2.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i3.MatSpinner, [i1.ElementRef, i4.Platform, [2, i5.DOCUMENT], [2, i6.ANIMATION_MODULE_TYPE], i3.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ConfiguratorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "iframe", [["class", "configurator-frame"]], [[8, "src", 5]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sanitizedUrl; _ck(_v, 1, 0, currVal_0); }); }
function View_ConfiguratorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "display: block; width: 100%; height: max-content; border: none"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfiguratorComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConfiguratorComponent_2)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loaded; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loaded; _ck(_v, 4, 0, currVal_1); }, null); }
exports.View_ConfiguratorComponent_0 = View_ConfiguratorComponent_0;
function View_ConfiguratorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-configurator", [], null, null, null, View_ConfiguratorComponent_0, RenderType_ConfiguratorComponent)), i1.ɵdid(1, 114688, null, 0, i7.ConfiguratorComponent, [i8.LoginService, i9.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_ConfiguratorComponent_Host_0 = View_ConfiguratorComponent_Host_0;
var ConfiguratorComponentNgFactory = i1.ɵccf("app-configurator", i7.ConfiguratorComponent, View_ConfiguratorComponent_Host_0, {}, {}, []);
exports.ConfiguratorComponentNgFactory = ConfiguratorComponentNgFactory;
