"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var table_1 = require("@angular/material/table");
var router_1 = require("@angular/router");
var core_marketplace_service_1 = require("../../../_service/core-marketplace.service");
var FuseMarketplaceListComponent = /** @class */ (function () {
    function FuseMarketplaceListComponent(router, marketplaceService) {
        this.router = router;
        this.marketplaceService = marketplaceService;
        this.dataSource = new table_1.MatTableDataSource();
        this.displayedColumns = ["name", "shortName", "url", "actions"];
    }
    FuseMarketplaceListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.marketplaceService
            .findAll()
            .toPromise()
            .then(function (marketplaces) { return (_this.dataSource.data = marketplaces); });
    };
    FuseMarketplaceListComponent.prototype.addMarketplace = function () {
        this.router.navigate(["/main/marketplace-form"]);
    };
    return FuseMarketplaceListComponent;
}());
exports.FuseMarketplaceListComponent = FuseMarketplaceListComponent;
