"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var class_1 = require("../../../../_model/meta/class");
var util_1 = require("util");
var rxjs_1 = require("rxjs");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
// import { FormConfigurationPreviewRequest } from "app/main/content/_model/meta/form";
var ClassDefinitionService = /** @class */ (function () {
    function ClassDefinitionService(http) {
        this.http = http;
    }
    ClassDefinitionService.prototype.getAllClassDefinitions = function (marketplace, tenantId) {
        return this.http.get(marketplace.url + "/meta/core/class/definition/all/tenant/" + tenantId);
    };
    ClassDefinitionService.prototype.getClassDefinitionById = function (marketplace, id, tenantId) {
        return this.http.get(marketplace.url + "/meta/core/class/definition/" + id + "/tenant/" + tenantId);
    };
    ClassDefinitionService.prototype.getClassDefinitionsById = function (marketplace, ids, tenantId) {
        if (!util_1.isNullOrUndefined(ids)) {
            return this.http.put(marketplace.url + "/meta/core/class/definition/multiple/tenant/" + tenantId, ids);
        }
        else {
            return rxjs_1.of(null);
        }
    };
    ClassDefinitionService.prototype.getByArchetype = function (marketplace, archetype, tenantId) {
        return this.http.get(marketplace.url + "/meta/core/class/definition/archetype/" + archetype + "/tenant/" + tenantId);
    };
    ClassDefinitionService.prototype.getFormConfigurations = function (marketplace, ids) {
        return this.http.put(marketplace.url + "/meta/core/class/definition/form-configuration", ids);
    };
    ClassDefinitionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClassDefinitionService_Factory() { return new ClassDefinitionService(i0.ɵɵinject(i1.HttpClient)); }, token: ClassDefinitionService, providedIn: "root" });
    return ClassDefinitionService;
}());
exports.ClassDefinitionService = ClassDefinitionService;
