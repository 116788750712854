"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var login_service_1 = require("app/main/content/_service/login.service");
var role_change_service_1 = require("app/main/content/_service/role-change.service");
var user_1 = require("app/main/content/_model/user");
var router_1 = require("@angular/router");
var core_tenant_service_1 = require("app/main/content/_service/core-tenant.service");
var util_1 = require("util");
var core_user_service_1 = require("app/main/content/_service/core-user.service");
var RoleMenuComponent = /** @class */ (function () {
    function RoleMenuComponent(router, loginService, roleChangeService, tenantService, userService) {
        var _this = this;
        this.router = router;
        this.loginService = loginService;
        this.roleChangeService = roleChangeService;
        this.tenantService = tenantService;
        this.userService = userService;
        this.allTenants = [];
        this.roleTenantMappings = [];
        this.possibleRoleTenantMappings = [];
        this.isLoaded = false;
        this.onRoleChanged = this.roleChangeService.onRoleChanged.subscribe(function () {
            _this.ngOnInit();
        });
        this.onUpdate = this.roleChangeService.onUpdate.subscribe(function () {
            _this.ngOnInit();
        });
    }
    RoleMenuComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var globalInfo, _a, _b, _c;
            var _this = this;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0: return [4 /*yield*/, this.loginService.getGlobalInfo().toPromise()];
                    case 1:
                        globalInfo = (_d.sent());
                        this.user = globalInfo.user;
                        this.role = globalInfo.userRole;
                        _b = (_a = Promise).all;
                        _c = this;
                        return [4 /*yield*/, this.tenantService.findAll().toPromise()];
                    case 2: return [4 /*yield*/, _b.apply(_a, [[
                                (_c.allTenants = (_d.sent()))
                            ]])];
                    case 3:
                        _d.sent();
                        this.currentMapping = new user_1.RoleTenantMapping();
                        this.currentMapping.role = this.role;
                        this.currentMapping.tenantIds = globalInfo.tenants.map(function (t) { return t.id; });
                        this.roleTenantMappings = this.roleChangeService.getRoleTenantMappings(this.user);
                        this.possibleRoleTenantMappings = this.roleTenantMappings.filter(function (m) {
                            return !_this.isSameMapping(m, _this.currentMapping);
                        });
                        this.isLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    RoleMenuComponent.prototype.onRoleSelected = function (mapping) {
        var _this = this;
        this.role = mapping.role;
        this.currentMapping = mapping;
        this.possibleRoleTenantMappings = this.roleTenantMappings.filter(function (m) {
            return !_this.isSameMapping(m, _this.currentMapping);
        });
        this.loginService
            .generateGlobalInfo(mapping.role, mapping.tenantIds)
            .then(function () {
            _this.roleChangeService.changeRole(mapping.role);
            _this.router.navigate(["/login"]).then(function () {
                _this.router.navigate(["/main/dashboard"]);
            });
        });
    };
    RoleMenuComponent.prototype.ngOnDestroy = function () {
        this.onRoleChanged.unsubscribe();
        this.onUpdate.unsubscribe();
    };
    RoleMenuComponent.prototype.getCurrentRoleNameString = function () {
        return this.roleChangeService.getRoleNameString(this.currentMapping.role);
    };
    RoleMenuComponent.prototype.getRoleNameString = function (role) {
        return this.roleChangeService.getRoleNameString(role);
    };
    RoleMenuComponent.prototype.getUserImage = function () {
        return this.userService.getUserProfileImage(this.user);
    };
    RoleMenuComponent.prototype.getCurrentTenantImage = function () {
        var _this = this;
        var tenant = this.allTenants.find(function (t) { return t.id === _this.currentMapping.tenantIds[0]; });
        return !util_1.isNullOrUndefined(tenant) ? tenant.imagePath : null;
    };
    RoleMenuComponent.prototype.getImage = function (tenantId) {
        var tenant = this.allTenants.find(function (t) { return t.id === tenantId; });
        if (tenant) {
            return tenant.imagePath;
        }
        return "";
    };
    RoleMenuComponent.prototype.isSameMapping = function (a, b) {
        if (a.role !== b.role) {
            return false;
        }
        else if (a.tenantIds.length !== b.tenantIds.length) {
            return false;
        }
        else if (a.tenantIds.every(function (id) { return b.tenantIds.indexOf(id) != -1; })) {
            return true;
        }
        else {
            return false;
        }
    };
    return RoleMenuComponent;
}());
exports.RoleMenuComponent = RoleMenuComponent;
