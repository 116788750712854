"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var StoredChart = /** @class */ (function () {
    function StoredChart(title, type, data, userId) {
        this.title = title;
        this.type = type;
        this.data = data;
        this.userId = userId;
    }
    return StoredChart;
}());
exports.StoredChart = StoredChart;
