"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var table_1 = require("@angular/material/table");
var class_instance_service_1 = require("app/main/content/_service/meta/core/class/class-instance.service");
var core_marketplace_service_1 = require("app/main/content/_service/core-marketplace.service");
var login_service_1 = require("app/main/content/_service/login.service");
var user_1 = require("app/main/content/_model/user");
var util_1 = require("util");
var class_1 = require("app/main/content/_model/meta/class");
var router_1 = require("@angular/router");
var InboxOverlayComponent = /** @class */ (function () {
    function InboxOverlayComponent(router, marketplaceService, loginService, classInstanceService, element) {
        this.router = router;
        this.marketplaceService = marketplaceService;
        this.loginService = loginService;
        this.classInstanceService = classInstanceService;
        this.element = element;
        this.closeOverlay = new core_1.EventEmitter();
        this.classInstanceDTOs = [];
        this.dataSource = new table_1.MatTableDataSource();
        this.displayedColumns = ["archetype", "label"];
    }
    InboxOverlayComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.marketplaceService
            .findAll()
            .toPromise()
            .then(function (marketplaces) {
            if (!util_1.isNullOrUndefined(marketplaces)) {
                _this.marketplace = marketplaces[0];
            }
            _this.loginService
                .getLoggedInUserRole()
                .toPromise()
                .then(function (role) {
                _this.userRole = role;
                if (_this.userRole === user_1.UserRole.VOLUNTEER) {
                    _this.loginService
                        .getLoggedIn()
                        .toPromise()
                        .then(function (volunteer) {
                        _this.user = volunteer;
                    });
                }
                else if (_this.userRole === user_1.UserRole.HELP_SEEKER) {
                    _this.loginService
                        .getLoggedIn()
                        .toPromise()
                        .then(function (user) {
                        _this.user = user;
                    });
                }
                if (_this.userRole === user_1.UserRole.VOLUNTEER) {
                    _this.user.subscribedTenants.forEach(function (tenantId) {
                        _this.classInstanceService
                            .getClassInstancesInUserInbox(_this.marketplace, _this.user.id, _this.user.subscribedTenants)
                            .toPromise()
                            .then(function (ret) {
                            _this.drawInboxElements(ret);
                            _this.isLoaded = true;
                        });
                    });
                }
                else if (_this.userRole === user_1.UserRole.HELP_SEEKER) {
                    _this.classInstanceService
                        .getClassInstancesInIssuerInbox(_this.marketplace, _this.user.tenantId)
                        .toPromise()
                        .then(function (ret) {
                        _this.drawInboxElements(ret);
                        _this.isLoaded = true;
                    });
                }
            });
        });
    };
    InboxOverlayComponent.prototype.drawInboxElements = function (classInstanceDTOs) {
        if (!util_1.isNullOrUndefined(classInstanceDTOs)) {
            classInstanceDTOs.sort(function (a, b) { return a.blockchainDate.valueOf() - b.blockchainDate.valueOf(); });
            if (classInstanceDTOs.length > 5) {
                classInstanceDTOs = classInstanceDTOs.slice(0, 5);
            }
            this.classInstanceDTOs = classInstanceDTOs;
            this.dataSource.data = classInstanceDTOs;
            this.innerDiv.nativeElement.style.width =
                this.element.nativeElement.parentElement.offsetWidth - 8 + "px";
            this.innerDiv.nativeElement.style.height =
                this.element.nativeElement.parentElement.offsetHeight - 58 + "px";
            this.innerDiv.nativeElement.style.overflow = "hidden";
            this.actionDiv.nativeElement.style.height = "18px";
        }
    };
    InboxOverlayComponent.prototype.findNameProperty = function (entry) {
        if (util_1.isNullOrUndefined(entry.properties)) {
            return "";
        }
        var name = entry.properties.find(function (p) { return p.id === "name"; });
        if (util_1.isNullOrUndefined(name) ||
            util_1.isNullOrUndefined(name.values) ||
            util_1.isNullOrUndefined(name.values[0])) {
            return "";
        }
        else {
            return name.values[0];
        }
    };
    // getDateString(date: number) {
    //   return new Date(date).toLocaleDateString();
    // }
    InboxOverlayComponent.prototype.getArchetypeIcon = function (entry) {
        if (util_1.isNullOrUndefined(entry.imagePath)) {
            if (entry.classArchetype === class_1.ClassArchetype.COMPETENCE) {
                return "/assets/competence.jpg";
            }
            else if (entry.classArchetype === class_1.ClassArchetype.ACHIEVEMENT) {
                return "/assets/icons/achievements_black.png";
            }
            else if (entry.classArchetype === class_1.ClassArchetype.FUNCTION) {
                return "/assets/TODO";
            }
            else if (entry.classArchetype === class_1.ClassArchetype.TASK) {
                return "/assets/cog.png";
            }
            else {
                return "/assets/NONE";
            }
        }
        else {
            return entry.imagePath;
        }
    };
    InboxOverlayComponent.prototype.showInboxClicked = function () {
        this.closeOverlay.emit(true);
        this.router.navigate(["/main/volunteer/asset-inbox"], {
            state: { marketplace: this.marketplace, participant: this.user }
        });
    };
    return InboxOverlayComponent;
}());
exports.InboxOverlayComponent = InboxOverlayComponent;
