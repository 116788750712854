"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var util_1 = require("util");
var login_service_1 = require("../_service/login.service");
var operators_1 = require("rxjs/operators");
var TokenInterceptor = /** @class */ (function () {
    function TokenInterceptor(loginService) {
        this.loginService = loginService;
        this.isRefreshing = false;
        this.refreshTokenSubject = new rxjs_1.BehaviorSubject(null);
    }
    TokenInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        var accessToken = this.loginService.getAccessToken();
        if (this.isLoginRequest(request) || util_1.isNullOrUndefined(accessToken)) {
            return next.handle(request);
        }
        request = this.addToken(request, accessToken);
        return next.handle(request).pipe(operators_1.catchError(function (error) {
            if (error instanceof http_1.HttpErrorResponse && error.status === 401) {
                return _this.handle401Error(request, next);
            }
            else {
                return rxjs_1.throwError(error);
            }
        }));
    };
    TokenInterceptor.prototype.isLoginRequest = function (request) {
        return request.url.endsWith("/login") && request.method === "POST";
    };
    TokenInterceptor.prototype.addToken = function (request, accessToken) {
        return request.clone({ setHeaders: { Authorization: accessToken } });
    };
    TokenInterceptor.prototype.handle401Error = function (request, next) {
        var _this = this;
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            this.refreshTokenSubject.next(null);
            return this.loginService
                .refreshAccessToken(this.loginService.getRefreshToken())
                .pipe(operators_1.switchMap(function (response) {
                _this.isRefreshing = false;
                _this.refreshTokenSubject.next(response.accessToken);
                return next.handle(_this.addToken(request, response.accessToken));
            }));
        }
        else {
            return this.refreshTokenSubject.pipe(operators_1.filter(function (accessToken) { return accessToken != null; }), operators_1.take(1), operators_1.switchMap(function (accessToken) {
                return next.handle(_this.addToken(request, accessToken));
            }));
        }
    };
    return TokenInterceptor;
}());
exports.TokenInterceptor = TokenInterceptor;
