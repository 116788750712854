"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.navigation_helpseeker = [
    {
        id: "welcome",
        title: "Übersicht",
        type: "item",
        icon: "door-open",
        url: "/main/dashboard"
    },
    {
        id: "digi",
        title: "Digitalisieren",
        type: "group"
    },
    {
        id: "tasks",
        title: "Einträge erfassen",
        type: "item",
        icon: "pencil",
        url: "/main/tasks/all"
    },
    {
        id: "tasks",
        title: "Einträge importieren",
        type: "item",
        icon: "import",
        url: "/main/import"
    },
    {
        id: "inbox",
        title: "Einträge bestätigen",
        type: "item",
        icon: "unlock",
        url: "/main/helpseeker/asset-inbox"
    }
];
