"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var user_1 = require("../_model/user");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var RegistrationService = /** @class */ (function () {
    function RegistrationService(http) {
        this.http = http;
    }
    RegistrationService.prototype.registerUser = function (user, accountType) {
        return this.http.post(environment_1.environment.CORE_URL + "/register?type=" + accountType, user, {
            observe: "response"
        });
    };
    RegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationService_Factory() { return new RegistrationService(i0.ɵɵinject(i1.HttpClient)); }, token: RegistrationService, providedIn: "root" });
    return RegistrationService;
}());
exports.RegistrationService = RegistrationService;
