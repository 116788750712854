"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var User = /** @class */ (function () {
    function User() {
        this.timeslots = [];
        this.phoneNumbers = [];
        this.websites = [];
        this.emails = [];
    }
    return User;
}());
exports.User = User;
var Address = /** @class */ (function () {
    function Address() {
    }
    return Address;
}());
exports.Address = Address;
var Timeslot = /** @class */ (function () {
    function Timeslot(formTimeslot) {
        this.weekday = formTimeslot.weekday;
        this.active = formTimeslot.active;
        this.secondActive = formTimeslot.secondActive;
        var from1 = formTimeslot.from1.split(":");
        var from2 = formTimeslot.from2.split(":");
        var to1 = formTimeslot.to1.split(":");
        var to2 = formTimeslot.to2.split(":");
        this.fromHours1 = Number(from1[0]);
        this.fromHours2 = Number(from2[0]);
        this.fromMins1 = Number(from1[1]);
        this.fromMins2 = Number(from2[1]);
        this.toHours1 = Number(to1[0]);
        this.toHours2 = Number(to2[0]);
        this.toMins1 = Number(to1[1]);
        this.toMins2 = Number(to2[1]);
    }
    return Timeslot;
}());
exports.Timeslot = Timeslot;
var Weekday;
(function (Weekday) {
    Weekday["MONDAY"] = "MONDAY";
    Weekday["TUESDAY"] = "TUESDAY";
    Weekday["WEDNESDAY"] = "WEDNESDAY";
    Weekday["THURSDAY"] = "THURSDAY";
    Weekday["FRIDAY"] = "FRIDAY";
    Weekday["SATURDAY"] = "SATURDAY";
    Weekday["SUNDAY"] = "SUNDAY";
})(Weekday = exports.Weekday || (exports.Weekday = {}));
(function (Weekday) {
    var translationLabels = {
        MONDAY: "Montag",
        TUESDAY: "Dienstag",
        WEDNESDAY: "Mittwoch",
        THURSDAY: "Donnerstag",
        FRIDAY: "Freitag",
        SATURDAY: "Samstag",
        SUNDAY: "Sonntag"
    };
    function getWeekdayLabel(weekday) {
        return translationLabels[weekday];
    }
    Weekday.getWeekdayLabel = getWeekdayLabel;
})(Weekday = exports.Weekday || (exports.Weekday = {}));
var UserRole;
(function (UserRole) {
    UserRole["VOLUNTEER"] = "VOLUNTEER";
    UserRole["HELP_SEEKER"] = "HELP_SEEKER";
    UserRole["TENANT_ADMIN"] = "TENANT_ADMIN";
    UserRole["ADMIN"] = "ADMIN";
    UserRole["RECRUITER"] = "RECRUITER";
    UserRole["FLEXPROD"] = "FLEXPROD";
    UserRole["NONE"] = "NONE";
})(UserRole = exports.UserRole || (exports.UserRole = {}));
var LocalRepositoryLocation;
(function (LocalRepositoryLocation) {
    LocalRepositoryLocation["LOCAL"] = "LOCAL";
    LocalRepositoryLocation["DROPBOX"] = "DROPBOX";
    LocalRepositoryLocation["NEXTCLOUD"] = "NEXTCLOUD";
})(LocalRepositoryLocation = exports.LocalRepositoryLocation || (exports.LocalRepositoryLocation = {}));
var TenantSubscription = /** @class */ (function () {
    function TenantSubscription() {
    }
    return TenantSubscription;
}());
exports.TenantSubscription = TenantSubscription;
var RoleTenantMapping = /** @class */ (function () {
    function RoleTenantMapping() {
    }
    return RoleTenantMapping;
}());
exports.RoleTenantMapping = RoleTenantMapping;
var AccountType;
(function (AccountType) {
    AccountType["PERSON"] = "PERSON";
    AccountType["ORGANIZATION"] = "ORGANIZATION";
})(AccountType = exports.AccountType || (exports.AccountType = {}));
