"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var core_2 = require("@ngx-translate/core");
var config_service_1 = require("@fuse/services/config.service");
var sidebar_service_1 = require("@fuse/components/sidebar/sidebar.service");
var navigation_volunteer_1 = require("app/navigation/navigation_volunteer");
var navigation_helpseeker_1 = require("../../navigation/navigation_helpseeker");
var login_service_1 = require("../content/_service/login.service");
var user_1 = require("../content/_model/user");
var FuseToolbarComponent = /** @class */ (function () {
    function FuseToolbarComponent(router, fuseConfig, loginService, sidebarService, translate, changeDetector) {
        var _this = this;
        this.router = router;
        this.fuseConfig = fuseConfig;
        this.loginService = loginService;
        this.sidebarService = sidebarService;
        this.translate = translate;
        this.changeDetector = changeDetector;
        this.userStatusOptions = [
            {
                title: "Online",
                icon: "icon-checkbox-marked-circle",
                color: "#4CAF50",
            },
            {
                title: "Away",
                icon: "icon-clock",
                color: "#FFC107",
            },
            {
                title: "Do not Disturb",
                icon: "icon-minus-circle",
                color: "#F44336",
            },
            {
                title: "Invisible",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#BDBDBD",
            },
            {
                title: "Offline",
                icon: "icon-checkbox-blank-circle-outline",
                color: "#616161",
            },
        ];
        this.languages = [
            {
                id: "en",
                title: "English",
                flag: "us",
            },
            {
                id: "tr",
                title: "Turkish",
                flag: "tr",
            },
        ];
        this.selectedLanguage = this.languages[0];
        router.events.subscribe(function (event) {
            if (event instanceof router_1.NavigationStart) {
                _this.showLoadingBar = true;
                // hack to prevent endless loading bar from showing when changing parameters
                if (event.url.startsWith("/main/properties/all?")) {
                    _this.showLoadingBar = false;
                }
            }
            if (event instanceof router_1.NavigationEnd) {
                _this.showLoadingBar = false;
                _this.changeHeading(event);
            }
        });
        this.fuseConfig.onConfigChanged.subscribe(function (settings) {
            _this.horizontalNav = settings.layout.navigation === "top";
            _this.noNav = settings.layout.navigation === "none";
        });
        this.loginService
            .getLoggedInUserRole()
            .toPromise()
            .then(function (role) {
            switch (role) {
                case user_1.UserRole.HELP_SEEKER:
                    _this.navigation = navigation_helpseeker_1.navigation_helpseeker;
                    _this.icons = "HELP_SEEKER";
                    break;
                case user_1.UserRole.VOLUNTEER:
                    _this.navigation = navigation_volunteer_1.navigation_volunteer;
                    _this.icons = "VOLUNTEER";
                    break;
            }
        })
            .catch(function (e) {
            console.warn(e);
        });
    }
    FuseToolbarComponent.prototype.changeHeading = function (event) {
        switch (event.urlAfterRedirects) {
            case "/main/matching-configurator":
                this.headingText = "Matching-Konfigurator";
                break;
            case "/main/class-configurator":
                this.headingText = "Klassen-Konfigurator";
                break;
            case String(event.urlAfterRedirects.match(/\/main\/configurator\/instance-editor\/[^]*/)):
                this.headingText = "Instanz-Editor";
                break;
            default:
                this.headingText = "";
                break;
        }
    };
    FuseToolbarComponent.prototype.toggleSidebarOpened = function (key) {
        this.sidebarService.getSidebar(key).toggleOpen();
    };
    FuseToolbarComponent.prototype.search = function (value) {
        // Do your search here...
        console.log(value);
    };
    FuseToolbarComponent.prototype.toggleInboxOverlay = function (event, inboxIcon) {
        this.displayInboxOverlay = !this.displayInboxOverlay;
        this.changeDetector.detectChanges();
        if (this.displayInboxOverlay) {
            var _a = inboxIcon._elementRef.nativeElement.getBoundingClientRect(), x = _a.x, y = _a.y;
            this.overlayDiv.nativeElement.style.top = y + 35 + "px";
            this.overlayDiv.nativeElement.style.left = x - 150 + "px";
            this.overlayDiv.nativeElement.style.position = "fixed";
            this.overlayDiv.nativeElement.style.width = "300px";
            this.overlayDiv.nativeElement.style.height = "240px";
            this.overlayArrowDiv.nativeElement.style.top = y + 20 + "px";
            this.overlayArrowDiv.nativeElement.style.left = x - 8 + "px";
            this.overlayArrowDiv.nativeElement.style.position = "fixed";
        }
    };
    FuseToolbarComponent.prototype.closeOverlay = function ($event) {
        this.displayInboxOverlay = false;
    };
    FuseToolbarComponent.prototype.setLanguage = function (lang) {
        // Set the selected language for toolbar
        this.selectedLanguage = lang;
        // Use the selected language for translations
        this.translate.use(lang.id);
    };
    return FuseToolbarComponent;
}());
exports.FuseToolbarComponent = FuseToolbarComponent;
