"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ClassDefinition = /** @class */ (function () {
    function ClassDefinition() {
        this.properties = [];
    }
    return ClassDefinition;
}());
exports.ClassDefinition = ClassDefinition;
var CompetenceClassDefinition = /** @class */ (function (_super) {
    __extends(CompetenceClassDefinition, _super);
    function CompetenceClassDefinition() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return CompetenceClassDefinition;
}(ClassDefinition));
exports.CompetenceClassDefinition = CompetenceClassDefinition;
var ClassInstance = /** @class */ (function () {
    function ClassInstance(classDefinition, properties) {
        this.name = classDefinition.name;
        this.classDefinitionId = classDefinition.id;
        this.properties = properties;
        this.marketplaceId = classDefinition.marketplaceId;
        this.timestamp = new Date();
        this.userId = null;
        this.classArchetype = classDefinition.classArchetype;
        this.visible = classDefinition.visible;
        this.tabId = classDefinition.tabId;
    }
    return ClassInstance;
}());
exports.ClassInstance = ClassInstance;
var ClassInstanceDTO = /** @class */ (function () {
    function ClassInstanceDTO() {
    }
    return ClassInstanceDTO;
}());
exports.ClassInstanceDTO = ClassInstanceDTO;
var CompetenceClassInstance = /** @class */ (function (_super) {
    __extends(CompetenceClassInstance, _super);
    function CompetenceClassInstance(classDefintion, properties) {
        var _this = _super.call(this, classDefintion, properties) || this;
        _this.classArchetype = ClassArchetype.COMPETENCE;
        return _this;
    }
    return CompetenceClassInstance;
}(ClassInstance));
exports.CompetenceClassInstance = CompetenceClassInstance;
var TaskClassInstance = /** @class */ (function (_super) {
    __extends(TaskClassInstance, _super);
    function TaskClassInstance(classDefintion, properties) {
        var _this = _super.call(this, classDefintion, properties) || this;
        _this.classArchetype = ClassArchetype.TASK;
        return _this;
    }
    return TaskClassInstance;
}(ClassInstance));
exports.TaskClassInstance = TaskClassInstance;
var FunctionClassInstance = /** @class */ (function (_super) {
    __extends(FunctionClassInstance, _super);
    function FunctionClassInstance(classDefintion, properties) {
        var _this = _super.call(this, classDefintion, properties) || this;
        _this.classArchetype = ClassArchetype.FUNCTION;
        return _this;
    }
    return FunctionClassInstance;
}(ClassInstance));
exports.FunctionClassInstance = FunctionClassInstance;
var AchievementClassInstance = /** @class */ (function (_super) {
    __extends(AchievementClassInstance, _super);
    function AchievementClassInstance(classDefintion, properties) {
        var _this = _super.call(this, classDefintion, properties) || this;
        _this.classArchetype = ClassArchetype.ACHIEVEMENT;
        return _this;
    }
    return AchievementClassInstance;
}(ClassInstance));
exports.AchievementClassInstance = AchievementClassInstance;
var ClassArchetype;
(function (ClassArchetype) {
    ClassArchetype["COMPETENCE"] = "COMPETENCE";
    ClassArchetype["TASK"] = "TASK";
    ClassArchetype["FUNCTION"] = "FUNCTION";
    ClassArchetype["ACHIEVEMENT"] = "ACHIEVEMENT";
    ClassArchetype["TREE_HEAD"] = "TREE_HEAD";
    ClassArchetype["TREE_ENTRY"] = "TREE_ENTRY";
    ClassArchetype["ROOT"] = "ROOT";
    // COMPETENCE_HEAD = 'COMPETENCE_HEAD',
    // TASK_HEAD = 'TASK_HEAD',
    // FUNCTION_HEAD = 'FUNCTION_HEAD',
    // ACHIEVEMENT_HEAD = 'ACHIEVEMENT_HEAD',
    ClassArchetype["FLEXPROD"] = "FLEXPROD";
})(ClassArchetype = exports.ClassArchetype || (exports.ClassArchetype = {}));
(function (ClassArchetype) {
    function getClassArchetypeLabel(classArchetype) {
        switch (classArchetype) {
            case "COMPETENCE":
                return "Kompetenz";
            case "TASK":
                return "Tätigkeit";
            case "FUNCTION":
                return "Funktion";
            case "ACHIEVEMENT":
                return "Verdienst";
            case "TREE_HEAD":
                return "Tree head";
            case "TREE_ENTRY":
                return "Tree entry";
            case "ROOT":
                return "Root";
            case "FLEXPROD":
                return "Flexprod";
        }
    }
    ClassArchetype.getClassArchetypeLabel = getClassArchetypeLabel;
})(ClassArchetype = exports.ClassArchetype || (exports.ClassArchetype = {}));
