"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var FileService = /** @class */ (function () {
    function FileService(http) {
        this.http = http;
    }
    FileService.prototype.retrieveFile = function (filePath) {
        return this.http.get(filePath);
    };
    FileService.prototype.uploadFile = function (file) {
        var formData = new FormData();
        formData.append("file", file, file.name);
        return this.http.post(environment_1.environment.CORE_URL + "/file", formData);
    };
    FileService.prototype.deleteFile = function (imageId) {
        return this.http.delete(environment_1.environment.CORE_URL + "/image/" + imageId);
    };
    FileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FileService_Factory() { return new FileService(i0.ɵɵinject(i1.HttpClient)); }, token: FileService, providedIn: "root" });
    return FileService;
}());
exports.FileService = FileService;
