"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var util_1 = require("util");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var core_marketplace_service_1 = require("../../../_service/core-marketplace.service");
var FuseMarketplaceFormComponent = /** @class */ (function () {
    function FuseMarketplaceFormComponent(formBuilder, route, router, marketplaceService) {
        this.route = route;
        this.router = router;
        this.marketplaceService = marketplaceService;
        this.marketplaceForm = formBuilder.group({
            id: new forms_1.FormControl(undefined),
            name: new forms_1.FormControl(undefined, forms_1.Validators.required),
            shortName: new forms_1.FormControl(undefined, forms_1.Validators.required),
            url: new forms_1.FormControl(undefined, forms_1.Validators.required),
        });
    }
    FuseMarketplaceFormComponent.prototype.isEditMode = function () {
        return !util_1.isNullOrUndefined(this.marketplaceForm.value.id);
    };
    FuseMarketplaceFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            return _this.findMarketplace(params["marketplaceId"]);
        });
    };
    FuseMarketplaceFormComponent.prototype.findMarketplace = function (marketplaceId) {
        var _this = this;
        if (util_1.isNullOrUndefined(marketplaceId) || marketplaceId.length === 0) {
            return;
        }
        this.marketplaceService
            .findById(marketplaceId)
            .toPromise()
            .then(function (marketplace) {
            _this.marketplaceForm.setValue({
                id: marketplace.id,
                name: marketplace.name,
                shortName: marketplace.shortName,
                url: marketplace.url,
            });
        });
    };
    FuseMarketplaceFormComponent.prototype.save = function () {
        var _this = this;
        if (!this.marketplaceForm.valid) {
            return;
        }
        this.marketplaceService
            .save(this.marketplaceForm.value)
            .toPromise()
            .then(function () { return _this.router.navigate(["/main/marketplace/all"]); });
    };
    return FuseMarketplaceFormComponent;
}());
exports.FuseMarketplaceFormComponent = FuseMarketplaceFormComponent;
