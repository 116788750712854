"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var role_switch_component_1 = require("./role-switch.component");
var routes = [{ path: "", component: role_switch_component_1.RoleSwitchComponent }];
var FuseRoleSwitchModule = /** @class */ (function () {
    function FuseRoleSwitchModule() {
    }
    return FuseRoleSwitchModule;
}());
exports.FuseRoleSwitchModule = FuseRoleSwitchModule;
