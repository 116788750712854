"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var widget_toggle_directive_1 = require("./widget-toggle.directive");
var FuseWidgetComponent = /** @class */ (function () {
    function FuseWidgetComponent(el, renderer) {
        this.el = el;
        this.renderer = renderer;
        this.flipped = false;
    }
    FuseWidgetComponent.prototype.ngAfterContentInit = function () {
        var _this = this;
        setTimeout(function () {
            _this.toggleButtons.forEach(function (flipButton) {
                _this.renderer.listen(flipButton.el.nativeElement, 'click', function (event) {
                    event.preventDefault();
                    event.stopPropagation();
                    _this.toggle();
                });
            });
        });
    };
    FuseWidgetComponent.prototype.toggle = function () {
        this.flipped = !this.flipped;
    };
    return FuseWidgetComponent;
}());
exports.FuseWidgetComponent = FuseWidgetComponent;
