"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".language-button[_ngcontent-%COMP%], .quick-panel-toggle-button[_ngcontent-%COMP%], .user-button[_ngcontent-%COMP%], fuse-search-bar[_ngcontent-%COMP%]{min-width:64px;height:64px}@media (max-width:599px){.language-button[_ngcontent-%COMP%], .quick-panel-toggle-button[_ngcontent-%COMP%], .user-button[_ngcontent-%COMP%], fuse-search-bar[_ngcontent-%COMP%]{height:56px}}.rolename[_ngcontent-%COMP%]{font-size:20px}"];
exports.styles = styles;
