"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var user_1 = require("app/main/content/_model/user");
var login_service_1 = require("app/main/content/_service/login.service");
var forms_1 = require("@angular/forms");
var core_user_service_1 = require("app/main/content/_service/core-user.service");
var util_1 = require("util");
var ProfileFormComponent = /** @class */ (function () {
    function ProfileFormComponent(loginService, formBuilder, coreUserService) {
        this.loginService = loginService;
        this.formBuilder = formBuilder;
        this.coreUserService = coreUserService;
        this.currentRoles = [];
        this.today = new Date();
    }
    ProfileFormComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, timeslotArray, i;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loaded = false;
                        _a = this;
                        return [4 /*yield*/, this.loginService.getGlobalInfo().toPromise()];
                    case 1:
                        _a.globalInfo = (_b.sent());
                        this.profileForm = this.formBuilder.group({
                            formOfAddress: new forms_1.FormControl('', forms_1.Validators.required),
                            titleBefore: new forms_1.FormControl(''),
                            firstname: new forms_1.FormControl('', forms_1.Validators.required),
                            lastname: new forms_1.FormControl('', forms_1.Validators.required),
                            titleAfter: new forms_1.FormControl(''),
                            nickname: new forms_1.FormControl(''),
                            birthday: new forms_1.FormControl('', forms_1.Validators.required),
                            address: this.formBuilder.group({
                                street: new forms_1.FormControl(''),
                                houseNumber: new forms_1.FormControl(''),
                                postcode: new forms_1.FormControl(''),
                                city: new forms_1.FormControl(''),
                                country: new forms_1.FormControl('')
                            }),
                            about: new forms_1.FormControl(''),
                            phoneNumber1: new forms_1.FormControl(''),
                            phoneNumber2: new forms_1.FormControl(''),
                            phoneNumber3: new forms_1.FormControl(''),
                            website1: new forms_1.FormControl(''),
                            website2: new forms_1.FormControl(''),
                            website3: new forms_1.FormControl(''),
                            loginEmail: new forms_1.FormControl('', forms_1.Validators.required),
                            email2: new forms_1.FormControl(''),
                            email3: new forms_1.FormControl(''),
                            timeslots: this.formBuilder.array([])
                        });
                        timeslotArray = this.profileForm.controls['timeslots'];
                        for (i = 0; i < 7; i++) {
                            timeslotArray.push(this.formBuilder.group({
                                active: new forms_1.FormControl(''),
                                secondActive: new forms_1.FormControl(''),
                                weekday: new forms_1.FormControl(''),
                                from1: new forms_1.FormControl('', forms_1.Validators.pattern(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)),
                                to1: new forms_1.FormControl('', forms_1.Validators.pattern(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)),
                                from2: new forms_1.FormControl('', forms_1.Validators.pattern(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)),
                                to2: new forms_1.FormControl('', forms_1.Validators.pattern(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/))
                            }));
                        }
                        this.profileForm.statusChanges.subscribe(function () {
                            _this.saveSuccessful = false;
                        });
                        this.reload();
                        this.loaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    ProfileFormComponent.prototype.reload = function () {
        var e_1, _a;
        var timeslotsConvert = [];
        try {
            for (var _b = __values(this.user.timeslots), _c = _b.next(); !_c.done; _c = _b.next()) {
                var t = _c.value;
                timeslotsConvert.push({
                    active: t.active,
                    secondActive: t.secondActive,
                    weekday: t.weekday,
                    from1: t.fromHours1 + ':' + (t.fromMins1 === 0 ? '00' : t.fromMins1),
                    to1: t.toHours1 + ':' + (t.toMins1 === 0 ? '00' : t.toMins1),
                    from2: t.fromHours2 + ':' + (t.fromMins2 === 0 ? '00' : t.fromMins2),
                    to2: t.toHours2 + ':' + (t.toMins2 === 0 ? '00' : t.toMins2)
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.profileForm.setValue({
            formOfAddress: this.user.formOfAddress,
            titleBefore: this.user.titleBefore,
            firstname: this.user.firstname,
            lastname: this.user.lastname,
            titleAfter: this.user.titleAfter,
            nickname: this.user.nickname,
            birthday: new Date(this.user.birthday),
            address: !util_1.isNullOrUndefined(this.user.address)
                ? this.user.address
                : this.profileForm.value.address,
            timeslots: timeslotsConvert,
            about: this.user.about,
            phoneNumber1: !util_1.isNullOrUndefined(this.user.phoneNumbers[0]) ? this.user.phoneNumbers[0] : null,
            phoneNumber2: !util_1.isNullOrUndefined(this.user.phoneNumbers[1]) ? this.user.phoneNumbers[1] : null,
            phoneNumber3: !util_1.isNullOrUndefined(this.user.phoneNumbers[2]) ? this.user.phoneNumbers[2] : null,
            website1: !util_1.isNullOrUndefined(this.user.websites[0]) ? this.user.websites[0] : null,
            website2: !util_1.isNullOrUndefined(this.user.websites[1]) ? this.user.websites[1] : null,
            website3: !util_1.isNullOrUndefined(this.user.websites[2]) ? this.user.websites[2] : null,
            loginEmail: !util_1.isNullOrUndefined(this.user.emails[0]) ? this.user.emails[0] : null,
            email2: !util_1.isNullOrUndefined(this.user.emails[1]) ? this.user.emails[1] : null,
            email3: !util_1.isNullOrUndefined(this.user.emails[2]) ? this.user.emails[2] : null,
        });
        for (var i = 0; i < 7; i++) {
            this.handleTimeslotCheckboxClicked(i, 'active', this.user.timeslots[i].active);
            this.handleTimeslotCheckboxClicked(i, 'secondActive', this.user.timeslots[i].secondActive);
        }
    };
    ProfileFormComponent.prototype.getWeekdayLabel = function (weekday) {
        return user_1.Weekday.getWeekdayLabel(weekday);
    };
    ProfileFormComponent.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var formTimeslots, convertedTimeslots, i, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.user.formOfAddress = this.profileForm.value.formOfAddress;
                        this.user.titleBefore = this.profileForm.value.titleBefore;
                        this.user.firstname = this.profileForm.value.firstname;
                        this.user.lastname = this.profileForm.value.lastname;
                        this.user.titleAfter = this.profileForm.value.titleAfter;
                        this.user.nickname = this.profileForm.value.nickname;
                        this.user.birthday = this.profileForm.value.birthday;
                        this.user.address = this.profileForm.value.address;
                        this.user.about = this.profileForm.value.about;
                        this.user.phoneNumbers = [];
                        this.user.phoneNumbers.fill(undefined, 0, 2);
                        this.user.phoneNumbers[0] = this.profileForm.value.phoneNumber1;
                        this.user.phoneNumbers[1] = this.profileForm.value.phoneNumber2;
                        this.user.phoneNumbers[2] = this.profileForm.value.phoneNumber3;
                        this.user.websites = [];
                        this.user.websites.fill(undefined, 0, 2);
                        this.user.websites[0] = this.profileForm.value.website1;
                        this.user.websites[1] = this.profileForm.value.website2;
                        this.user.websites[2] = this.profileForm.value.website3;
                        this.user.emails = [];
                        this.user.emails.fill(undefined, 0, 2);
                        this.user.emails[0] = this.profileForm.value.loginEmail;
                        this.user.loginEmail = this.profileForm.value.loginEmail;
                        this.user.emails[1] = this.profileForm.value.email2;
                        this.user.emails[2] = this.profileForm.value.email3;
                        this.profileForm.get('timeslots').enable();
                        formTimeslots = this.profileForm.value.timeslots;
                        convertedTimeslots = [];
                        for (i = 0; i < 7; i++) {
                            convertedTimeslots.push(new user_1.Timeslot(formTimeslots[i]));
                        }
                        this.user.timeslots = convertedTimeslots;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.coreUserService.updateUser(this.user, true).toPromise()];
                    case 2:
                        _a.sent();
                        this.loginService.generateGlobalInfo(this.globalInfo.userRole, this.globalInfo.tenants.map(function (t) { return t.id; }));
                        this.reload();
                        this.saveSuccessful = true;
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.log(error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ProfileFormComponent.prototype.showErrorMessage = function (formControl, formControlName) {
        return formControl.hasError('required')
            ? 'Pflichtfeld'
            : (formControlName === 'from1' ||
                formControlName === 'from2' ||
                formControlName === 'to1' ||
                formControlName === 'to2') &&
                formControl.hasError('pattern')
                ? '(H)H:MM'
                : '';
    };
    ProfileFormComponent.prototype.hasVolunteerRole = function () {
        return this.currentRoles.indexOf(user_1.UserRole.VOLUNTEER) !== -1;
    };
    ProfileFormComponent.prototype.handleTimeslotCheckboxClicked = function (timeslotIndex, formControlName, setTo) {
        var formArray = this.profileForm.controls['timeslots'];
        var timeslot = formArray.get(timeslotIndex + '');
        if (util_1.isNullOrUndefined(setTo)) {
            setTo = !timeslot.get(formControlName).value;
        }
        timeslot.get(formControlName).setValue(setTo);
        if (formControlName === 'active') {
            setTo ? timeslot.enable() : timeslot.disable();
        }
        timeslot.updateValueAndValidity();
    };
    return ProfileFormComponent;
}());
exports.ProfileFormComponent = ProfileFormComponent;
