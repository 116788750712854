"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.navigation_admin = [
    {
        id: "maketplaces",
        title: "Marketplace",
        type: "item",
        url: "/main/marketplace/all"
    }
];
