<div *ngIf="loaded" class="page-layout blank p-24" fusePerfectScrollbar style="overflow: scroll;">
  <mat-card class="page content">

    <h1>Um Ihre Organisation zu erstellen benötigen wir noch ein paar Kleinigkeiten:</h1>

    <tenant-form-content [tenant]="tenant" (tenantSaved)="handleTenantSaved($event)">
    </tenant-form-content>

    <tenant-helpseekers-form *ngIf="tenantSaved" [tenant]="tenant"></tenant-helpseekers-form>


    <div class="mt-16" fxLayout="row wrap" fxLayoutAlign="space-around center">
      <button mat-stroked-button class="secondary-button-dark" type="button"
        (click)="handleLogoutClick()">Ausloggen</button>

      <div></div>

      <button mat-stroked-button class="primary-button-dark" type="button" (click)="handleFinishedClick()"
        [disabled]="!tenantSaved">Fertig</button>
    </div>

  </mat-card>