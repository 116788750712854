"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var TenantTagService = /** @class */ (function () {
    function TenantTagService(http) {
        this.http = http;
    }
    TenantTagService.prototype.findAll = function () {
        return this.http.get(environment_1.environment.CORE_URL + "/tags/all");
    };
    TenantTagService.prototype.findAllAsString = function () {
        return this.http.get(environment_1.environment.CORE_URL + "/tags/all/string");
    };
    TenantTagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TenantTagService_Factory() { return new TenantTagService(i0.ɵɵinject(i1.HttpClient)); }, token: TenantTagService, providedIn: "root" });
    return TenantTagService;
}());
exports.TenantTagService = TenantTagService;
