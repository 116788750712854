"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.navigation_flexprod = [
    {
        id: "dashboard",
        title: "Dashboard",
        type: "item",
        url: "/main/dashboard",
    },
    {
        id: "properties",
        title: "Properties",
        type: "item",
        url: "/main/properties/all",
    },
    {
        id: "configurator",
        title: "Configurator",
        type: "item",
        url: "/main/class-configurator",
    },
];
