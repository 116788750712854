"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var login_service_1 = require("../_service/login.service");
var user_1 = require("../_model/user");
var i0 = require("@angular/core");
var i1 = require("../_service/login.service");
var AdminGuard = /** @class */ (function () {
    function AdminGuard(loginService) {
        this.loginService = loginService;
    }
    AdminGuard.prototype.canActivate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.loginService
                .getLoggedInUserRole()
                .toPromise()
                .then(function (role) { return resolve(role === user_1.UserRole.ADMIN); });
        });
    };
    AdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.ɵɵinject(i1.LoginService)); }, token: AdminGuard, providedIn: "root" });
    return AdminGuard;
}());
exports.AdminGuard = AdminGuard;
