"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var UserProfileImageUploadDialogComponent = /** @class */ (function () {
    function UserProfileImageUploadDialogComponent(dialogRef, data) {
        this.dialogRef = dialogRef;
        this.data = data;
    }
    UserProfileImageUploadDialogComponent.prototype.ngOnInit = function () {
        this.user = this.data.user;
    };
    UserProfileImageUploadDialogComponent.prototype.handleOnSave = function ($event) {
        this.dialogRef.close(this.user);
    };
    return UserProfileImageUploadDialogComponent;
}());
exports.UserProfileImageUploadDialogComponent = UserProfileImageUploadDialogComponent;
