"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var shape = require("d3-shape");
var moment = require("moment");
var ngx_charts_1 = require("@swimlane/ngx-charts");
var TimelineFilterComponent = /** @class */ (function () {
    function TimelineFilterComponent() {
        this.selectedYaxisChange = new core_1.EventEmitter();
        this.timelineFilterChange = new core_1.EventEmitter();
        this.selectedYearChange = new core_1.EventEmitter();
        this.selectedYaxis = "Dauer [Stunden]";
        this.timelineFilter = { from: null, to: null };
        this.selectedYear = "Gesamt";
        this.colorScheme = "cool";
        this.schemeType = "ordinal";
        this.legend = false;
        this.showGridLines = true;
        this.tooltipDisabled = false;
        this.xAxis = true;
        this.yAxis = true;
        this.showXAxisLabel = true;
        this.showYAxisLabel = true;
        this.xAxisLabel = "Datum";
        this.yAxisLabel = "Dauer [Stunden]";
        this.animations = true;
        this.autoScale = true;
        this.timeline = true;
        this.curve = shape.curveStep;
        this.removable = true;
        this.timelineChartData = [{ name: "Tätigkeit", series: [] }];
    }
    TimelineFilterComponent.prototype.ngOnInit = function () {
        this.updateSelectedYaxis();
        this.updateSelectedYear();
        this.updateTimelineFilter();
    };
    TimelineFilterComponent.prototype.ngOnChanges = function (changes) {
        var changed = false;
        // console.error('timeline-filter changes', changes);
        for (var propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case "classInstanceDTOs": {
                        if (typeof changes.classInstanceDTOs.currentValue != "undefined") {
                            this.classInstanceDTOs = changes.classInstanceDTOs.currentValue;
                            var list = this.classInstanceDTOs.map(function (ci) {
                                var date = new Date(ci.dateFrom);
                                return {
                                    year: date.getFullYear().toString(),
                                    tt1: ci.taskType1,
                                    tt2: ci.taskType2,
                                    tt3: ci.taskType3,
                                };
                            });
                            this.uniqueYears = __spread(new Set(list.map(function (item) { return item.year; })));
                            this.uniqueYears.push("Gesamt");
                            this.uniqueYears.sort();
                            this.uniqueTt1 = __spread(new Set(list.map(function (item) { return item.tt1; })));
                            this.uniqueTt2 = __spread(new Set(list.map(function (item) { return item.tt2; })));
                            this.uniqueTt3 = __spread(new Set(list.map(function (item) { return item.tt2; })));
                            changed = true;
                        }
                        break;
                    }
                    case "selectedTaskType": {
                        if (typeof changes.selectedTaskType.currentValue != "undefined") {
                            this.selectedTaskType = changes.selectedTaskType.currentValue;
                            changed = true;
                        }
                        break;
                    }
                }
            }
        }
        if (changed) {
            this.generateTimelineData();
        }
    };
    TimelineFilterComponent.prototype.onYearChange = function (value) {
        this.selectedYear = value.toString();
        this.timelineFilter.from = null;
        this.timelineFilter.to = null;
        this.lineChart.filteredDomain = null;
        this.lineChart.update();
        this.generateTimelineData();
        this.updateSelectedYear();
        this.updateTimelineFilter();
    };
    TimelineFilterComponent.prototype.onYaxisChange = function (val) {
        this.selectedYaxis = val;
        this.yAxisLabel = val;
        this.generateTimelineData();
        this.updateSelectedYaxis();
    };
    TimelineFilterComponent.prototype.filterTimelineApply = function () {
        this.timelineFilter.from = new Date(this.lineChart.xDomain[0]);
        this.timelineFilter.to = new Date(this.lineChart.xDomain[1]);
        this.selectedYear = null;
        this.updateTimelineFilter();
        this.updateSelectedYear();
    };
    TimelineFilterComponent.prototype.generateTimelineData = function () {
        var _this = this;
        if (this.timelineFilter.from == null) {
            if (this.selectedYear === "Gesamt") {
                this.filteredClassInstanceDTOs = __spread(this.classInstanceDTOs);
            }
            else {
                this.filteredClassInstanceDTOs = this.classInstanceDTOs.filter(function (c) {
                    return moment(c.dateFrom).isSame(moment(_this.selectedYear), "year");
                });
            }
        }
        else {
            this.filteredClassInstanceDTOs = __spread(this.classInstanceDTOs);
        }
        if (this.selectedTaskType != null) {
            if (this.uniqueTt1.indexOf(this.selectedTaskType) > -1) {
                this.filteredClassInstanceDTOs = this.filteredClassInstanceDTOs.filter(function (c) {
                    return c.taskType1 === _this.selectedTaskType;
                });
            }
            else if (this.uniqueTt2.indexOf(this.selectedTaskType) > -1) {
                this.filteredClassInstanceDTOs = this.filteredClassInstanceDTOs.filter(function (c) {
                    return c.taskType2 === _this.selectedTaskType;
                });
            }
        }
        var timelineList = this.filteredClassInstanceDTOs.map(function (ci) {
            var value;
            _this.selectedYaxis === "Anzahl" ? (value = 1) : (value = ci.duration);
            return {
                date: new Date(ci.dateFrom).setHours(0, 0, 0, 0),
                value: Number(value),
            };
        });
        var timelineMap = new Map();
        timelineList.forEach(function (t) {
            if (timelineMap.get(t.date)) {
                timelineMap.set(t.date, Number(timelineMap.get(t.date)) + Number(t.value));
            }
            else {
                timelineMap.set(t.date, Number(t.value));
            }
        });
        var data1 = [];
        Array.from(timelineMap.entries()).forEach(function (entry) {
            if (entry[0] != null && entry[1] != null && !isNaN(entry[1])) {
                data1.push({ name: new Date(entry[0]), value: Number(entry[1]) });
            }
        });
        this.timelineChartData[0].series = data1;
        this.timelineChartData = __spread(this.timelineChartData);
    };
    TimelineFilterComponent.prototype.updateSelectedYaxis = function () {
        this.selectedYaxisChange.emit(this.selectedYaxis);
    };
    TimelineFilterComponent.prototype.updateSelectedYear = function () {
        this.selectedYearChange.emit(this.selectedYear);
    };
    TimelineFilterComponent.prototype.updateTimelineFilter = function () {
        this.timelineFilterChange.emit(JSON.parse(JSON.stringify(this.timelineFilter)));
    };
    TimelineFilterComponent.prototype.dateTickFormatting = function (value) {
        var locale = "de-DE";
        var formatOptions;
        if (value.getSeconds() !== 0) {
            formatOptions = { second: "2-digit" };
        }
        else if (value.getMinutes() !== 0) {
            formatOptions = { hour: "2-digit", minute: "2-digit" };
        }
        else if (value.getHours() !== 0) {
            formatOptions = { hour: "2-digit" };
        }
        else if (value.getDate() !== 1) {
            formatOptions =
                value.getDay() === 0
                    ? { month: "short", day: "2-digit" }
                    : { weekday: "short", day: "2-digit" };
        }
        else if (value.getMonth() !== 0) {
            formatOptions = { month: "long" };
        }
        else {
            formatOptions = { year: "numeric" };
        }
        return new Intl.DateTimeFormat(locale, formatOptions).format(value);
    };
    TimelineFilterComponent.prototype.dateTickFormatting2 = function (date) {
        if (date instanceof Date) {
            return date.toLocaleString("de-DE");
        }
    };
    TimelineFilterComponent.prototype.getTooltip = function (data) {
        var model = JSON.parse(data);
        var date = new Date(model.name);
        var dateString = date.toLocaleDateString("de-DE");
        var result = "";
        if (this.selectedYaxis === "Anzahl") {
            result = dateString + "\n" + "Anzahl: " + model.value;
        }
        else {
            result = dateString + "\n" + "Dauer: " + model.value + " Stunden";
        }
        return result;
    };
    TimelineFilterComponent.prototype.getSeriesTooltip = function (data) {
        var model = JSON.parse(data);
        model = model[0];
        var date = new Date(model.name);
        var dateString = date.toLocaleDateString("de-DE");
        var result = "";
        if (this.selectedYaxis === "Anzahl") {
            result = dateString + "\n" + "Anzahl: " + model.value;
        }
        else {
            result = dateString + "\n" + "Dauer: " + model.value + " Stunden";
        }
        return result;
    };
    return TimelineFilterComponent;
}());
exports.TimelineFilterComponent = TimelineFilterComponent;
