<div class="page-layout blank right-sidenav p-24" fusePerfectScrollbar>
  <customizable-header *ngIf="tenant" [tenant]="tenant" [displayNavigateBack]="false" headerText="Einträge importieren">
  </customizable-header>

  <div style="padding-top: 20px;"></div>

  <mat-card class="infoText">Importiere Freiwilligenpass-Einträge. Wähle dazu die entsprechende
    Konfiguration und das dazugehörige JSON-File aus.
  </mat-card>

  <p *ngIf="inputMissingError" class="alert alert-warn mt-10">
    <i class="fas fa-exclamation-circle"></i> &nbsp; Bitte geben Sie Voluntär
    und Export-Datei an.
  </p>

  <form form style="padding-top: 20px; padding-left: 40px;" name="form" fxLayout="row" fxLayoutGap="20px"
    fxLayoutAlign="start" [formGroup]="importForm">
    <mat-form-field style="width: 300px;">
      <mat-label>Voluntär</mat-label>
      <mat-select formControlName="volunteer">
        <mat-option *ngFor="let vol of volunteers" [value]="vol">
          {{ vol.firstname }} {{ vol.lastname }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <ngx-mat-file-input formControlName="file" placeholder="Import-Datei"></ngx-mat-file-input>
      <mat-icon matSuffix>folder</mat-icon>
    </mat-form-field>

    <button mat-raised-button type="submit" color="primary" (click)="save()">
      Speichern
    </button>
  </form>

  <p *ngIf="displaySuccessMessage" class="alert alert-success mt-20">
    <i class="far fa-check-circle"></i> &nbsp; {{ successImportCount }}
    {{ successImportCount <= 1 ? "Eintrag" : "Einträge" }}
    erfolgreich importiert.
  </p>
</div>