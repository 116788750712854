"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var util_1 = require("util");
var class_instance_service_1 = require("../../../../_service/meta/core/class/class-instance.service");
var core_marketplace_service_1 = require("../../../../_service/core-marketplace.service");
var login_service_1 = require("../../../../_service/login.service");
var HelpseekerConfirmationScreenComponent = /** @class */ (function () {
    function HelpseekerConfirmationScreenComponent(route, router, classInstanceService, marketplaceService, loginService) {
        this.route = route;
        this.router = router;
        this.classInstanceService = classInstanceService;
        this.marketplaceService = marketplaceService;
        this.loginService = loginService;
        if (!util_1.isNullOrUndefined(this.router.getCurrentNavigation().extras.state)) {
            this.marketplace = this.router.getCurrentNavigation().extras.state.marketplace;
            this.user = this.router.getCurrentNavigation().extras.state.participant;
            this.classInstanceDTOs = this.router.getCurrentNavigation().extras.state.classInstances;
        }
    }
    HelpseekerConfirmationScreenComponent.prototype.ngOnInit = function () {
        var _this = this;
        console.log(this.marketplace);
        console.log(this.user);
        if (util_1.isNullOrUndefined(this.marketplace) || util_1.isNullOrUndefined(this.user)) {
            Promise.all([
                this.marketplaceService
                    .findAll()
                    .toPromise()
                    .then(function (marketplaces) {
                    if (!util_1.isNullOrUndefined(marketplaces)) {
                        _this.marketplace = marketplaces[0];
                    }
                }),
                this.loginService
                    .getLoggedIn()
                    .toPromise()
                    .then(function (user) {
                    _this.user = user;
                }),
            ]).then(function () { });
        }
    };
    HelpseekerConfirmationScreenComponent.prototype.onBackClickInbox = function () {
        this.router.navigate(["main/helpseeker/asset-inbox"], {
            state: {
                instances: undefined,
                marketplace: this.marketplace,
                participant: this.user,
            },
        });
    };
    HelpseekerConfirmationScreenComponent.prototype.onBackClickDashboard = function () {
        this.router.navigate(["main/dashboard"]);
    };
    HelpseekerConfirmationScreenComponent.prototype.onBackClickNewTask = function () {
        this.router.navigate(["main/tasks/all"]);
    };
    return HelpseekerConfirmationScreenComponent;
}());
exports.HelpseekerConfirmationScreenComponent = HelpseekerConfirmationScreenComponent;
