"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var asset_inbox_helpseeker_component_1 = require("./asset-inbox-helpseeker.component");
var routes = [{ path: "", component: asset_inbox_helpseeker_component_1.AssetInboxHelpseekerComponent }];
var AssetInboxHelpseekerModule = /** @class */ (function () {
    function AssetInboxHelpseekerModule() {
    }
    return AssetInboxHelpseekerModule;
}());
exports.AssetInboxHelpseekerModule = AssetInboxHelpseekerModule;
