"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var user_1 = require("../_model/user");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var router_1 = require("@angular/router");
var local_repository_jsonServer_service_1 = require("./local-repository-jsonServer.service");
var local_repository_dropbox_service_1 = require("./local-repository-dropbox.service");
var local_repository_nextcloud_service_1 = require("./local-repository-nextcloud.service");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("@angular/router");
var i3 = require("./local-repository-dropbox.service");
var i4 = require("./local-repository-jsonServer.service");
var i5 = require("./local-repository-nextcloud.service");
var LoginService = /** @class */ (function () {
    function LoginService(http, httpClient, router, lrDropboxService, lrJsonServerService, lrNextcloudService) {
        this.http = http;
        this.httpClient = httpClient;
        this.router = router;
        this.lrDropboxService = lrDropboxService;
        this.lrJsonServerService = lrJsonServerService;
        this.lrNextcloudService = lrNextcloudService;
    }
    LoginService.prototype.login = function (username, password) {
        return this.http.post(environment_1.environment.CORE_URL + "/auth/login", { username: username, password: password }, { observe: "response" });
    };
    LoginService.prototype.logout = function () {
        localStorage.clear();
        this.router.navigate(["/login"]);
    };
    LoginService.prototype.getActivationStatus = function (username) {
        return this.http.put(environment_1.environment.CORE_URL + "/activation-status", username);
    };
    LoginService.prototype.getLoggedIn = function () {
        var globalInfo = JSON.parse(localStorage.getItem("globalInfo"));
        if (globalInfo) {
            return new rxjs_1.Observable(function (subscriber) {
                subscriber.next(globalInfo.user);
                subscriber.complete();
            });
        }
        else {
            return this.http.get(environment_1.environment.CORE_URL + "/user");
        }
    };
    LoginService.prototype.getLoggedInUserRole = function () {
        var globalInfo = JSON.parse(localStorage.getItem("globalInfo"));
        if (globalInfo) {
            return new rxjs_1.Observable(function (subscriber) {
                subscriber.next(globalInfo.userRole);
                subscriber.complete();
            });
        }
        else {
            return new rxjs_1.Observable(function (subscriber) {
                subscriber.next(user_1.UserRole.NONE);
                subscriber.complete();
            });
        }
    };
    LoginService.prototype.refreshAccessToken = function (refreshToken) {
        var _this = this;
        return this.http
            .post(environment_1.environment.CORE_URL + "/auth/refreshToken", { "refreshToken": refreshToken })
            .pipe(operators_1.tap(function (response) {
            var accessToken = response.accessToken;
            var refreshToken = response.refreshToken;
            if (accessToken == null || refreshToken == null) {
                _this.logout();
            }
            else {
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);
            }
        }, function (error) {
            _this.logout();
        }));
    };
    LoginService.prototype.getRefreshToken = function () {
        return localStorage.getItem("refreshToken");
    };
    LoginService.prototype.getAccessToken = function () {
        return localStorage.getItem("accessToken");
    };
    LoginService.prototype.getGlobalInfo = function () {
        var observable = new rxjs_1.Observable(function (subscriber) {
            var globalInfo = JSON.parse(localStorage.getItem("globalInfo"));
            if (globalInfo) {
                subscriber.next(globalInfo);
                subscriber.complete();
            }
        });
        return observable;
    };
    LoginService.prototype.generateGlobalInfo = function (role, tenantIds) {
        return __awaiter(this, void 0, void 0, function () {
            var globalInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.httpClient
                            .put(environment_1.environment.CORE_URL + "/globalInfo/role/" + role, tenantIds)
                            .toPromise()];
                    case 1:
                        globalInfo = (_a.sent());
                        localStorage.setItem("globalInfo", JSON.stringify(globalInfo));
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginService.prototype.getLocalRepositoryService = function (volunteer) {
        switch (volunteer.localRepositoryLocation) {
            case user_1.LocalRepositoryLocation.LOCAL:
                return this.lrJsonServerService;
            case user_1.LocalRepositoryLocation.DROPBOX:
                return this.lrDropboxService;
            case user_1.LocalRepositoryLocation.NEXTCLOUD:
                return this.lrNextcloudService;
        }
    };
    LoginService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginService_Factory() { return new LoginService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LocalRepositoryDropboxService), i0.ɵɵinject(i4.LocalRepositoryJsonServerService), i0.ɵɵinject(i5.LocalRepositoryNextcloudService)); }, token: LoginService, providedIn: "root" });
    return LoginService;
}());
exports.LoginService = LoginService;
