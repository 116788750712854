<div class="page-layout blank p-24" fusePerfectScrollbar>
  <!-- 
  <div class="header mat-accent-bg p-16 p-sm-24" fxLayout="column" fxLayoutAlign="center center">
    <h1>Tätigkeiten</h1>
  </div> -->

  <customizable-header *ngIf="tenant" [tenant]="tenant" headerText="Einträge erfassen"></customizable-header>

  <div class="p-8 pt-16" fxLayout="row" fxLayoutAlign="end end">
    <button mat-fab class="mat-warn" style="border-radius: 50%; background-color: green;" (click)="addTask()" aria-label="Add rule" *fuseIfOnDom [@animate]="{ value: '*', params: { delay: '300ms', scale: '0.2' } }">
      <mat-icon class="add-btn-icon">add</mat-icon>
    </button>
  </div>

  <div class="table">
    <mat-table #table [dataSource]="tableDataSource" matSort *ngIf="tableDataSource.data.length > 0">
      <ng-container matColumnDef="taskName">
        <mat-header-cell *matHeaderCellDef> Tätigkeit </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="taskType1">
        <mat-header-cell *matHeaderCellDef> Typ </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.taskType1 }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="taskType2">
        <mat-header-cell *matHeaderCellDef> Subtyp </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.taskType2 }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="taskDateFrom">
        <mat-header-cell *matHeaderCellDef> Datum </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.dateFrom | date: "dd.MM.yyyy hh:mm" }}
        </mat-cell>
      </ng-container>
      <!-- 
      <ng-container matColumnDef="taskDateTo">
        <mat-header-cell *matHeaderCellDef> Ende </mat-header-cell>
        <mat-cell matCellDef="let element"> {{element.properties[TASK_DATE_TO].values[0] | date:'dd.MM.yyyy hh:mm'}}
        </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="taskDuration">
        <mat-header-cell *matHeaderCellDef> Dauer </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.duration }}&nbsp;{{ element.duration ? "h" : "N/A" }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="verified">
        <mat-header-cell *matHeaderCellDef> Bestätigt </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <i *ngIf="element.issued" class="fas fa-check-circle" style="color: green;"></i>
          <i *ngIf="!element.issued" class="fas fa-times-circle" style="color: red;"></i>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns" (click)="rowSelected(row)"></mat-row>
    </mat-table>
  </div>
  <mat-paginator [pageSize]="14" showFirstLastButtons></mat-paginator>

  <!-- <div class="no-data" *ngIf="tableDataSource.data.length === 0">
    Keine Einträge gefunden!
  </div> -->
</div>