"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var material_1 = require("@angular/material");
var collections_1 = require("@angular/cdk/collections");
var login_service_1 = require("app/main/content/_service/login.service");
var user_1 = require("app/main/content/_model/user");
var core_user_service_1 = require("app/main/content/_service/core-user.service");
var core_tenant_service_1 = require("app/main/content/_service/core-tenant.service");
var AddHelpseekerDialogComponent = /** @class */ (function () {
    function AddHelpseekerDialogComponent(dialogRef, data, dialog, loginService, coreUserService, tenantService) {
        this.dialogRef = dialogRef;
        this.data = data;
        this.dialog = dialog;
        this.loginService = loginService;
        this.coreUserService = coreUserService;
        this.tenantService = tenantService;
        this.datasource = new material_1.MatTableDataSource();
        this.displayedColumns = ["checkbox", "name"];
        this.selection = new collections_1.SelectionModel(true, []);
    }
    AddHelpseekerDialogComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loginService.getGlobalInfo().toPromise()];
                    case 1:
                        _a.globalInfo = (_b.sent());
                        this.tenant = this.globalInfo.tenants[0];
                        this.coreUserService
                            .findAllByRoles([user_1.UserRole.NONE, user_1.UserRole.VOLUNTEER, user_1.UserRole.HELP_SEEKER], true)
                            .toPromise()
                            .then(function (ret) {
                            console.log(ret);
                            _this.allHelpseekers = ret;
                            _this.allHelpseekers = _this.allHelpseekers.filter(function (h) { return _this.data.helpseekers.findIndex(function (f) { return f.id === h.id; }) === -1; });
                            _this.datasource.data = _this.allHelpseekers;
                            _this.loaded = true;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    AddHelpseekerDialogComponent.prototype.applyFilter = function (event) {
        var filterValue = event.target.value;
        this.datasource.filter = filterValue.trim().toLowerCase();
    };
    AddHelpseekerDialogComponent.prototype.onRowClick = function (row) {
        this.selection.isSelected(row)
            ? this.selection.deselect(row)
            : this.selection.select(row);
    };
    AddHelpseekerDialogComponent.prototype.onSubmit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var addedUsers;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, Promise.all(this.selection.selected.map(function (elem) { return __awaiter(_this, void 0, void 0, function () {
                            var error_1;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        _a.trys.push([0, 2, , 3]);
                                        return [4 /*yield*/, this.coreUserService
                                                .subscribeOtherUserToTenant(this.globalInfo.tenants[0].id, user_1.UserRole.HELP_SEEKER, elem.id)
                                                .toPromise()];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/, elem];
                                    case 2:
                                        error_1 = _a.sent();
                                        return [2 /*return*/, []];
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }))];
                    case 1:
                        addedUsers = _a.sent();
                        this.data.helpseekers = addedUsers;
                        this.dialogRef.close(this.data);
                        return [2 /*return*/];
                }
            });
        });
    };
    return AddHelpseekerDialogComponent;
}());
exports.AddHelpseekerDialogComponent = AddHelpseekerDialogComponent;
