"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var volunteer_guard_1 = require("./_guard/volunteer.guard");
var login_guard_1 = require("./_guard/login.guard");
var flexprod_helpseeker_guard_1 = require("./_guard/flexprod-helpseeker.guard");
var recruiter_guard_1 = require("./_guard/recruiter.guard");
var anonym_guard_1 = require("./_guard/anonym.guard");
var admin_guard_1 = require("./_guard/admin.guard");
var tenant_admin_guard_1 = require("./_guard/tenant-admin.guard");
var helpseeker_tenantAdmin_guard_1 = require("./_guard/helpseeker-tenantAdmin.guard");
var token_guard_1 = require("./_guard/token.guard");
var admin_tenantAdmin_guard_1 = require("./_guard/admin-tenantAdmin.guard");
var ɵ0 = function () {
    return Promise.resolve().then(function () { return require("./_components/common/user_management/login/login.module.ngfactory"); }).then(function (m) { return m.FuseLoginModuleNgFactory; });
}, ɵ1 = function () {
    return Promise.resolve().then(function () { return require("./_components/common/user_management/registration/volunteer/registration.module.ngfactory"); }).then(function (m) { return m.VolunteerRegistrationModuleNgFactory; });
}, ɵ2 = function () {
    return Promise.resolve().then(function () { return require("./_components/common/user_management/registration/organization/registration.module.ngfactory"); }).then(function (m) { return m.OrganizationRegistrationModuleNgFactory; });
}, ɵ3 = function () {
    return Promise.resolve().then(function () { return require("./_components/common/user_management/activation/activation.module.ngfactory"); }).then(function (m) { return m.ActivationModuleNgFactory; });
}, ɵ4 = function () {
    return Promise.resolve().then(function () { return require("./_components/common/user_management/role-switch/role-switch.module.ngfactory"); }).then(function (m) { return m.FuseRoleSwitchModuleNgFactory; });
}, ɵ5 = function () {
    return Promise.resolve().then(function () { return require("./_components/help-seeker/asset-inbox-helpseeker/asset-inbox-helpseeker.module.ngfactory"); }).then(function (m) { return m.AssetInboxHelpseekerModuleNgFactory; });
}, ɵ6 = function () {
    return Promise.resolve().then(function () { return require("./_components/help-seeker/asset-inbox-helpseeker/confirmation-screen/confirmation-screen.module.ngfactory"); }).then(function (m) { return m.HelpseekerConfirmationScreenModuleNgFactory; });
}, ɵ7 = function () {
    return Promise.resolve().then(function () { return require("./_components/common/dashboard/dashboard.module.ngfactory"); }).then(function (m) { return m.FuseDashboardModuleNgFactory; });
}, ɵ8 = function () {
    return Promise.resolve().then(function () { return require("./_components/common/user_management/create-tenant/create-tenant.module.ngfactory"); }).then(function (m) { return m.CreateTenantModuleNgFactory; });
}, ɵ9 = function () {
    return Promise.resolve().then(function () { return require("./_components/volunteer/task-management/achievements/management-summary/management-summary.module.ngfactory"); }).then(function (m) { return m.ManagementSummaryNgFactory; });
}, ɵ10 = function () {
    return Promise.resolve().then(function () { return require("./_components/volunteer/task-management/achievements/achievements.module.ngfactory"); }).then(function (m) { return m.AchievementsModuleNgFactory; });
}, ɵ11 = function () {
    return Promise.resolve().then(function () { return require("./_components/volunteer/social-management/get-connected/get-connected.module.ngfactory"); }).then(function (m) { return m.FuseGetConnectedModuleNgFactory; });
}, ɵ12 = function () {
    return Promise.resolve().then(function () { return require("./_components/help-seeker/task-management/task-select/task-select.module.ngfactory"); }).then(function (m) { return m.FuseTaskSelectModuleNgFactory; });
}, ɵ13 = function () {
    return Promise.resolve().then(function () { return require("./_components/help-seeker/task-management/task-list/task-list.module.ngfactory"); }).then(function (m) { return m.FuseTaskListModuleNgFactory; });
}, ɵ14 = function () {
    return Promise.resolve().then(function () { return require("./_components/help-seeker/configuration/property-configurator/list/property-list.module.ngfactory"); }).then(function (m) { return m.PropertyListModuleNgFactory; });
}, ɵ15 = function () {
    return Promise.resolve().then(function () { return require("./_components/help-seeker/configuration/class-configurator/configurator.module.ngfactory"); }).then(function (m) { return m.ConfiguratorModuleNgFactory; });
}, ɵ16 = function () {
    return Promise.resolve().then(function () { return require("./_components/recruiter/recruit-view/recruit-view.module.ngfactory"); }).then(function (m) { return m.RecruitViewModuleNgFactory; });
}, ɵ17 = function () {
    return Promise.resolve().then(function () { return require("./_components/admin/marketplace-list/marketplace-list.module.ngfactory"); }).then(function (m) { return m.FuseMarketplaceListModuleNgFactory; });
}, ɵ18 = function () {
    return Promise.resolve().then(function () { return require("./_components/admin/marketplace-form/marketplace-form.module.ngfactory"); }).then(function (m) { return m.FuseMarketplaceFormModuleNgFactory; });
}, ɵ19 = function () {
    return Promise.resolve().then(function () { return require("./_components/admin/tenant-form/tenant-form.module.ngfactory"); }).then(function (m) { return m.FuseTenantFormModuleNgFactory; });
}, ɵ20 = function () {
    return Promise.resolve().then(function () { return require("./_components/help-seeker/import/import.module.ngfactory"); }).then(function (m) { return m.ImportModuleNgFactory; });
}, ɵ21 = function () {
    return Promise.resolve().then(function () { return require("./_components/common/profile/profile.module.ngfactory"); }).then(function (m) { return m.ProfileModuleNgFactory; });
}, ɵ22 = function () {
    return Promise.resolve().then(function () { return require("./_components/help-seeker/configuration/matching-configurator/matching-configurator.module.ngfactory"); }).then(function (m) { return m.MatchingConfiguratorModuleNgFactory; });
}, ɵ23 = function () {
    return Promise.resolve().then(function () { return require("./_components/common/class-instance-details/class-instance-details.module.ngfactory"); }).then(function (m) { return m.ClassInstanceDetailsModuleNgFactory; });
};
exports.ɵ0 = ɵ0;
exports.ɵ1 = ɵ1;
exports.ɵ2 = ɵ2;
exports.ɵ3 = ɵ3;
exports.ɵ4 = ɵ4;
exports.ɵ5 = ɵ5;
exports.ɵ6 = ɵ6;
exports.ɵ7 = ɵ7;
exports.ɵ8 = ɵ8;
exports.ɵ9 = ɵ9;
exports.ɵ10 = ɵ10;
exports.ɵ11 = ɵ11;
exports.ɵ12 = ɵ12;
exports.ɵ13 = ɵ13;
exports.ɵ14 = ɵ14;
exports.ɵ15 = ɵ15;
exports.ɵ16 = ɵ16;
exports.ɵ17 = ɵ17;
exports.ɵ18 = ɵ18;
exports.ɵ19 = ɵ19;
exports.ɵ20 = ɵ20;
exports.ɵ21 = ɵ21;
exports.ɵ22 = ɵ22;
exports.ɵ23 = ɵ23;
var routes = [
    {
        path: 'login',
        loadChildren: ɵ0,
    },
    {
        path: 'register/volunteer',
        loadChildren: ɵ1,
        canActivate: [anonym_guard_1.AnonymGuard],
    },
    {
        path: 'register/organization',
        loadChildren: ɵ2,
        canActivate: [anonym_guard_1.AnonymGuard],
    },
    {
        path: 'register/activate',
        loadChildren: ɵ3,
        canActivate: [anonym_guard_1.AnonymGuard],
    },
    {
        path: 'role',
        loadChildren: ɵ4,
        canActivate: [token_guard_1.TokenGuard],
    },
    {
        path: 'main/helpseeker/asset-inbox',
        loadChildren: ɵ5,
        canActivate: [token_guard_1.TokenGuard, helpseeker_tenantAdmin_guard_1.HelpSeekerOrTenantAdminGuard],
    },
    {
        path: 'main/helpseeker/asset-inbox/confirm',
        loadChildren: ɵ6,
        canActivate: [token_guard_1.TokenGuard, helpseeker_tenantAdmin_guard_1.HelpSeekerOrTenantAdminGuard],
    },
    {
        path: 'main/dashboard',
        loadChildren: ɵ7,
        canActivate: [token_guard_1.TokenGuard, login_guard_1.LoginGuard],
    },
    {
        path: 'main/create-tenant',
        loadChildren: ɵ8,
        canActivate: [token_guard_1.TokenGuard, login_guard_1.LoginGuard],
    },
    {
        path: 'main/achievements/overview',
        loadChildren: ɵ9,
        canActivate: [token_guard_1.TokenGuard, volunteer_guard_1.VolunteerGuard],
    },
    {
        path: 'main/achievements/details',
        loadChildren: ɵ10,
        canActivate: [token_guard_1.TokenGuard, volunteer_guard_1.VolunteerGuard],
    },
    {
        path: 'main/get-connected',
        loadChildren: ɵ11,
        canActivate: [token_guard_1.TokenGuard, volunteer_guard_1.VolunteerGuard],
    },
    {
        path: 'main/task-select',
        loadChildren: ɵ12,
        canActivate: [token_guard_1.TokenGuard, helpseeker_tenantAdmin_guard_1.HelpSeekerOrTenantAdminGuard],
    },
    {
        path: 'main/tasks/all',
        loadChildren: ɵ13,
        canActivate: [token_guard_1.TokenGuard, helpseeker_tenantAdmin_guard_1.HelpSeekerOrTenantAdminGuard],
    },
    {
        path: 'main/properties/all',
        loadChildren: ɵ14,
        canActivate: [token_guard_1.TokenGuard, flexprod_helpseeker_guard_1.FlexProdOrTenantAdminGuard],
    },
    {
        path: 'main/class-configurator',
        loadChildren: ɵ15,
        canActivate: [token_guard_1.TokenGuard, flexprod_helpseeker_guard_1.FlexProdOrTenantAdminGuard],
    },
    {
        path: 'main/recruitment',
        loadChildren: ɵ16,
        canActivate: [token_guard_1.TokenGuard, recruiter_guard_1.RecruiterGuard],
    },
    {
        path: 'main/marketplace/all',
        loadChildren: ɵ17,
        canActivate: [token_guard_1.TokenGuard, admin_guard_1.AdminGuard],
    },
    {
        path: 'main/marketplace-form',
        loadChildren: ɵ18,
        canActivate: [token_guard_1.TokenGuard, admin_guard_1.AdminGuard],
    },
    {
        path: 'main/edit-tenant',
        loadChildren: ɵ19,
        canActivate: [token_guard_1.TokenGuard, admin_tenantAdmin_guard_1.AdminOrTenantAdminGuard],
    },
    {
        path: 'main/import',
        loadChildren: ɵ20,
        canActivate: [token_guard_1.TokenGuard, helpseeker_tenantAdmin_guard_1.HelpSeekerOrTenantAdminGuard],
    },
    {
        path: 'main/profile',
        loadChildren: ɵ21,
        canActivate: [token_guard_1.TokenGuard, login_guard_1.LoginGuard],
    },
    {
        path: 'main/matching-configurator',
        loadChildren: ɵ22,
        canActivate: [token_guard_1.TokenGuard, tenant_admin_guard_1.TenantAdminGuard],
    },
    {
        path: 'main/details',
        loadChildren: ɵ23,
        canActivate: [token_guard_1.TokenGuard, login_guard_1.LoginGuard],
    },
];
var FuseContentModule = /** @class */ (function () {
    function FuseContentModule() {
    }
    return FuseContentModule;
}());
exports.FuseContentModule = FuseContentModule;
