"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var recruit_view_component_1 = require("./recruit-view.component");
var routes = [
    {
        path: '',
        component: recruit_view_component_1.RecruitViewComponent
    }
];
var RecruitViewModule = /** @class */ (function () {
    function RecruitViewModule() {
    }
    return RecruitViewModule;
}());
exports.RecruitViewModule = RecruitViewModule;
