"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var confirm_dialog_component_1 = require("../confirm-dialog/confirm-dialog.component");
var add_helpseeker_dialog_component_1 = require("../../../admin/tenant-form/tenant-form-content/helpseekers-form/add-helpseeker-dialog/add-helpseeker-dialog.component");
var user_profile_image_upload_dialog_component_1 = require("../user-profile-image-upload-dialog/user-profile-image-upload-dialog.component");
var DialogFactoryDirective = /** @class */ (function () {
    function DialogFactoryDirective(dialog) {
        this.dialog = dialog;
    }
    DialogFactoryDirective.prototype.confirmationDialog = function (title, description) {
        var dialogRef = this.dialog.open(confirm_dialog_component_1.ConfirmDialogComponent, {
            width: '500px',
            data: { title: title, description: description }
        });
        var ret = false;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            if (result) {
                ret = result;
            }
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return ret;
        });
    };
    /**
     * ******CLASS CONFIGURATION******
     */
    /**
     * ******INSTANTIATION******
     */
    /*
     *  Matching-Configurator Dialogs
     */
    DialogFactoryDirective.prototype.openAddHelpseekerDialog = function (helpseekers) {
        var dialogRef = this.dialog.open(add_helpseeker_dialog_component_1.AddHelpseekerDialogComponent, {
            width: '500px',
            minWidth: '500px',
            height: '418px',
            minHeight: '418px',
            data: {
                helpseekers: helpseekers
            }
        });
        var returnValue;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnValue = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnValue;
        });
    };
    DialogFactoryDirective.prototype.openProfileImageUploadDialog = function (user) {
        var dialogRef = this.dialog.open(user_profile_image_upload_dialog_component_1.UserProfileImageUploadDialogComponent, {
            width: '800px',
            minWidth: '800px',
            height: '34px',
            minHeight: '340px',
            data: {
                user: user
            }
        });
        var returnValue;
        dialogRef
            .beforeClose()
            .toPromise()
            .then(function (result) {
            returnValue = result;
        });
        return dialogRef
            .afterClosed()
            .toPromise()
            .then(function () {
            return returnValue;
        });
    };
    return DialogFactoryDirective;
}());
exports.DialogFactoryDirective = DialogFactoryDirective;
