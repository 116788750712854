"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var local_repository_1 = require("../_model/local-repository");
var web_1 = require("webdav/web");
var local_repository_service_1 = require("./local-repository.service");
var i0 = require("@angular/core");
var LocalRepositoryNextcloudService = /** @class */ (function (_super) {
    __extends(LocalRepositoryNextcloudService, _super);
    function LocalRepositoryNextcloudService() {
        var _this = _super.call(this) || this;
        _this.FILE_PATH1 = "/Apps";
        _this.FILE_PATH2 = "/Apps/iVolunteer/";
        _this.FILE_NAME = "db.json";
        _this.FULL_FILE_NAME = _this.FILE_PATH2 + _this.FILE_NAME;
        _this.localRepositories = [];
        return _this;
    }
    LocalRepositoryNextcloudService.prototype.isConnected = function (credentials) {
        return __awaiter(this, void 0, void 0, function () {
            var nextcloud, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (credentials == null ||
                            credentials.domain == null ||
                            credentials.username == null ||
                            credentials.password == null) {
                            return [2 /*return*/, false];
                        }
                        nextcloud = web_1.createClient(credentials.domain, {
                            username: credentials.username,
                            password: credentials.password,
                        });
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, nextcloud.getDirectoryContents("/")];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, true];
                    case 3:
                        error_1 = _a.sent();
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    LocalRepositoryNextcloudService.prototype.findByVolunteer = function (volunteer) {
        var _this = this;
        var nextcloud;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var successFunction = function (localRepository) {
                subscriber.next(localRepository);
                subscriber.complete();
            };
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            (function () { return __awaiter(_this, void 0, void 0, function () {
                var error_2, newRepo, content, parsedJSON, repository, newRepo, error_3;
                var _this = this;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            try {
                                nextcloud = web_1.createClient(volunteer.nextcloudCredentials.domain, {
                                    username: volunteer.nextcloudCredentials.username,
                                    password: volunteer.nextcloudCredentials.password,
                                });
                            }
                            catch (error) {
                                console.error("nextcloud client creation failed");
                                failureFunction(error);
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 7]);
                            return [4 /*yield*/, nextcloud.getDirectoryContents(this.FILE_PATH2)];
                        case 2:
                            _a.sent();
                            return [3 /*break*/, 7];
                        case 3:
                            error_2 = _a.sent();
                            // create path and db.json
                            return [4 /*yield*/, nextcloud.createDirectory(this.FILE_PATH1)];
                        case 4:
                            // create path and db.json
                            _a.sent();
                            return [4 /*yield*/, nextcloud.createDirectory(this.FILE_PATH2)];
                        case 5:
                            _a.sent();
                            newRepo = new local_repository_1.LocalRepository(volunteer.id, volunteer.username);
                            this.localRepositories.push(newRepo);
                            content = { repository: this.localRepositories };
                            return [4 /*yield*/, nextcloud.putFileContents(this.FULL_FILE_NAME, JSON.stringify(content, null, 2))];
                        case 6:
                            _a.sent();
                            successFunction(newRepo);
                            return [3 /*break*/, 7];
                        case 7:
                            _a.trys.push([7, 9, , 10]);
                            return [4 /*yield*/, nextcloud.getFileContents(this.FULL_FILE_NAME, {
                                    format: "text",
                                })];
                        case 8:
                            parsedJSON = _a.sent();
                            this.localRepositories = [];
                            parsedJSON.repository.forEach(function (lr) {
                                _this.localRepositories.push(lr);
                            });
                            repository = this.localRepositories.find(function (localRepository) {
                                return localRepository.id === volunteer.id;
                            });
                            if (repository) {
                                successFunction(repository);
                            }
                            else {
                                newRepo = new local_repository_1.LocalRepository(volunteer.id, volunteer.username);
                                this.localRepositories.push(newRepo);
                                this.saveToNextcloud(volunteer);
                                successFunction(newRepo);
                            }
                            return [3 /*break*/, 10];
                        case 9:
                            error_3 = _a.sent();
                            failureFunction(error_3);
                            return [3 /*break*/, 10];
                        case 10: return [2 /*return*/];
                    }
                });
            }); })().then(null, subscriber.error);
        });
        return observable;
    };
    LocalRepositoryNextcloudService.prototype.findClassInstancesByVolunteer = function (volunteer) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            var successFunction = function (localRepository) {
                if (!localRepository) {
                    subscriber.next([]);
                }
                else {
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                return successFunction(localRepository);
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryNextcloudService.prototype.synchronizeSingleClassInstance = function (volunteer, classInstance) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                try {
                    localRepository.classInstances.push(classInstance);
                    _this.localRepositories.forEach(function (lr, index) {
                        if (lr.id == localRepository.id) {
                            _this.localRepositories[index] = localRepository;
                        }
                    });
                    _this.saveToNextcloud(volunteer);
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
                catch (error) {
                    failureFunction(error);
                }
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryNextcloudService.prototype.getSingleClassInstance = function (volunteer, classInstanceId) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                var classInstance = localRepository.classInstances.find(function (ci) {
                    return ci.id === classInstanceId;
                });
                subscriber.next(classInstance);
                subscriber.complete();
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryNextcloudService.prototype.synchronizeClassInstances = function (volunteer, classInstances) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                try {
                    localRepository.classInstances = __spread(localRepository.classInstances, classInstances);
                    _this.localRepositories.forEach(function (lr, index) {
                        if (lr.id == localRepository.id) {
                            _this.localRepositories[index] = localRepository;
                        }
                    });
                    _this.saveToNextcloud(volunteer);
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
                catch (error) {
                    failureFunction(error);
                }
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryNextcloudService.prototype.overrideClassInstances = function (volunteer, classInstances) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                try {
                    localRepository.classInstances = classInstances;
                    _this.localRepositories.forEach(function (lr, index) {
                        if (lr.id == localRepository.id) {
                            _this.localRepositories[index] = localRepository;
                        }
                    });
                    _this.saveToNextcloud(volunteer);
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
                catch (error) {
                    failureFunction(error);
                }
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryNextcloudService.prototype.removeSingleClassInstance = function (volunteer, classInstanceId) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                try {
                    localRepository.classInstances.forEach(function (ci, index, object) {
                        if (ci.id === classInstanceId) {
                            object.splice(index, 1);
                        }
                    });
                    _this.localRepositories.forEach(function (lr, index) {
                        if (lr.id == localRepository.id) {
                            _this.localRepositories[index] = localRepository;
                        }
                    });
                    _this.saveToNextcloud(volunteer);
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
                catch (error) {
                    failureFunction(error);
                }
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryNextcloudService.prototype.removeClassInstances = function (volunteer, classInstanceIds) {
        var _this = this;
        var observable = new rxjs_1.Observable(function (subscriber) {
            var failureFunction = function (error) {
                subscriber.error(error);
                subscriber.complete();
            };
            _this.findByVolunteer(volunteer)
                .toPromise()
                .then(function (localRepository) {
                try {
                    localRepository.classInstances = localRepository.classInstances.filter(function (c) { return classInstanceIds.indexOf(c.id) < 0; });
                    _this.localRepositories.forEach(function (lr, index) {
                        if (lr.id == localRepository.id) {
                            _this.localRepositories[index] = localRepository;
                        }
                    });
                    _this.saveToNextcloud(volunteer);
                    subscriber.next(localRepository.classInstances);
                    subscriber.complete();
                }
                catch (error) {
                    failureFunction(error);
                }
            })
                .catch(function (error) { return failureFunction(error); });
        });
        return observable;
    };
    LocalRepositoryNextcloudService.prototype.saveToNextcloud = function (volunteer) {
        return __awaiter(this, void 0, void 0, function () {
            var nextcloud, content;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        nextcloud = web_1.createClient(volunteer.nextcloudCredentials.domain, {
                            username: volunteer.nextcloudCredentials.username,
                            password: volunteer.nextcloudCredentials.password,
                        });
                        content = { repository: this.localRepositories };
                        return [4 /*yield*/, nextcloud.putFileContents(this.FULL_FILE_NAME, JSON.stringify(content, null, 2), {
                                overwrite: true,
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LocalRepositoryNextcloudService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocalRepositoryNextcloudService_Factory() { return new LocalRepositoryNextcloudService(); }, token: LocalRepositoryNextcloudService, providedIn: "root" });
    return LocalRepositoryNextcloudService;
}(local_repository_service_1.LocalRepositoryService));
exports.LocalRepositoryNextcloudService = LocalRepositoryNextcloudService;
