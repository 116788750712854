"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var activation_service_1 = require("app/main/content/_service/activation.service");
var activation_texts_1 = require("../activation-texts");
var user_1 = require("app/main/content/_model/user");
var ResendLinkComponent = /** @class */ (function () {
    function ResendLinkComponent(activationService) {
        this.activationService = activationService;
        this.loading = false;
        this.showResultMessage = false;
    }
    ResendLinkComponent.prototype.ngOnInit = function () {
        this.activationTexts = this.accountType === user_1.AccountType.ORGANIZATION ? activation_texts_1.organizationTexts : activation_texts_1.personTexts;
    };
    ResendLinkComponent.prototype.handleBackClick = function () {
        window.history.back();
    };
    ResendLinkComponent.prototype.onSubmit = function () {
        this.handleResendLinkSubmit(this.emailAddress);
    };
    ResendLinkComponent.prototype.handleResendLinkSubmit = function (email) {
        var _this = this;
        this.loading = true;
        this.emailAddress = email;
        this.activationService.createActivationLinkViaEmail(email, this.accountType).toPromise().then(function (ret) {
            _this.loading = false;
            _this.showResultMessage = true;
        });
    };
    return ResendLinkComponent;
}());
exports.ResendLinkComponent = ResendLinkComponent;
