"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var file_service_1 = require("./file.service");
var environment_1 = require("environments/environment");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var i2 = require("./file.service");
var TenantService = /** @class */ (function () {
    function TenantService(http, fileService) {
        this.http = http;
        this.fileService = fileService;
    }
    TenantService.prototype.findAll = function () {
        return this.http.get(environment_1.environment.CORE_URL + "/tenant");
    };
    TenantService.prototype.findByName = function (tenantName) {
        return this.http.get(environment_1.environment.CORE_URL + "/tenant/name/" + tenantName, {
            responseType: "text"
        });
    };
    TenantService.prototype.findById = function (tenantId) {
        return this.http.get(environment_1.environment.CORE_URL + "/tenant/id/" + tenantId);
    };
    TenantService.prototype.findByUserId = function (userId) {
        return this.http.get(environment_1.environment.CORE_URL + "/tenant/user/" + userId);
    };
    TenantService.prototype.findByMarketplace = function (marketplaceId) {
        return this.http.get(environment_1.environment.CORE_URL + "/tenant/marketplace/" + marketplaceId);
    };
    TenantService.prototype.createTenant = function (tenant) {
        return this.http.post(environment_1.environment.CORE_URL + "/tenant/new/not-x", tenant);
    };
    TenantService.prototype.updateTenant = function (tenant) {
        return this.http.put(environment_1.environment.CORE_URL + "/tenant/update", tenant);
    };
    TenantService.prototype.initHeader = function (tenant) {
        document.querySelector(".header").style.background =
            tenant.primaryColor;
    };
    TenantService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TenantService_Factory() { return new TenantService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.FileService)); }, token: TenantService, providedIn: "root" });
    return TenantService;
}());
exports.TenantService = TenantService;
