"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var forms_1 = require("@angular/forms");
var router_1 = require("@angular/router");
var config_service_1 = require("@fuse/services/config.service");
var login_service_1 = require("../../../../_service/login.service");
var FuseLoginComponent = /** @class */ (function () {
    function FuseLoginComponent(fuseConfig, formBuilder, router, loginService) {
        this.fuseConfig = fuseConfig;
        this.formBuilder = formBuilder;
        this.router = router;
        this.loginService = loginService;
    }
    FuseLoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loginForm = this.formBuilder.group({
            username: new forms_1.FormControl("", forms_1.Validators.required),
            password: new forms_1.FormControl("", forms_1.Validators.required),
        });
        this.loginForm.valueChanges.subscribe(function () {
            _this.onLoginFormValuesChanged();
        });
        this.error = false;
        this.displayLoginForm = true;
        this.resendActivationFlow = false;
        this.layout = {
            navigation: "none",
            toolbar: "none",
            footer: "none",
        };
        this.fuseConfig.setConfig({ layout: this.layout });
        this.loginFormErrors = {
            username: {},
            password: {},
        };
    };
    FuseLoginComponent.prototype.onLoginFormValuesChanged = function () {
        for (var field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }
            // Clear previous errors
            this.loginFormErrors[field] = {};
            // Get the control
            var control = this.loginForm.get(field);
            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    };
    FuseLoginComponent.prototype.login = function () {
        var _this = this;
        if (this.loginForm.valid) {
            // TODO Philipp
            // TODO sp
            // activation status check disabled for now
            // this.loginService
            //   .getActivationStatus(this.loginForm.value.username)
            //   .toPromise()
            //   .then((cont: boolean) => {
            //     if (cont) {
            this.loginService
                .login(this.loginForm.value.username, this.loginForm.value.password)
                .toPromise()
                .then(function (response) {
                var accessToken = response.body.accessToken;
                var refreshToken = response.body.refreshToken;
                if (accessToken == null || refreshToken == null) {
                    _this.error = true;
                    _this.loginService.logout();
                }
                else {
                    localStorage.setItem("accessToken", accessToken);
                    localStorage.setItem("refreshToken", refreshToken);
                    _this.router.navigate(["/role"]);
                }
            })
                .catch(function (e) {
                _this.loginService.logout();
                _this.error = true;
                _this.loginService.logout();
            });
            //   } else {
            //     this.displayLoginForm = false;
            //   }
            // });
        }
    };
    return FuseLoginComponent;
}());
exports.FuseLoginComponent = FuseLoginComponent;
