"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var login_service_1 = require("app/main/content/_service/login.service");
var user_1 = require("app/main/content/_model/user");
var core_tenant_service_1 = require("app/main/content/_service/core-tenant.service");
var router_1 = require("@angular/router");
var role_change_service_1 = require("app/main/content/_service/role-change.service");
var RoleSwitchComponent = /** @class */ (function () {
    function RoleSwitchComponent(router, loginService, tenantService, roleChangeService) {
        this.router = router;
        this.loginService = loginService;
        this.tenantService = tenantService;
        this.roleChangeService = roleChangeService;
        this.allTenants = [];
        this.roleTenantMappings = [];
        this.isLoaded = false;
    }
    RoleSwitchComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loginService.getLoggedIn().toPromise()];
                    case 1:
                        _a.user = (_c.sent());
                        this.roleTenantMappings = this.roleChangeService.getRoleTenantMappings(this.user);
                        if (this.roleTenantMappings.length === 0 &&
                            this.user.accountType === user_1.AccountType.PERSON) {
                            this.loginService.generateGlobalInfo(user_1.UserRole.NONE, []).then(function () {
                                _this.router.navigate(["/main/dashboard/tenants"]);
                            });
                        }
                        else if (this.roleTenantMappings.length === 0 &&
                            this.user.accountType === user_1.AccountType.ORGANIZATION) {
                            this.loginService
                                .generateGlobalInfo(user_1.UserRole.TENANT_ADMIN, [])
                                .then(function () {
                                _this.router.navigate(["/main/create-tenant"]);
                            });
                        }
                        else if (this.roleTenantMappings.length === 1) {
                            this.onRoleSelected(this.roleTenantMappings[0]);
                        }
                        _b = this;
                        return [4 /*yield*/, this.tenantService.findAll().toPromise()];
                    case 2:
                        _b.allTenants = (_c.sent());
                        this.isLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    RoleSwitchComponent.prototype.onRoleSelected = function (mapping) {
        var _this = this;
        this.loginService
            .generateGlobalInfo(mapping.role, mapping.tenantIds)
            .then(function () {
            _this.router.navigate(["/main/dashboard"]).then(function () {
                _this.roleChangeService.changeRole(mapping.role);
            });
        });
    };
    RoleSwitchComponent.prototype.getTenantImageByTenantId = function (tenantId) {
        var tenant = this.allTenants.find(function (t) { return t.id === tenantId; });
        return tenant.imagePath;
    };
    RoleSwitchComponent.prototype.getTenant = function (tenantId) {
        return this.allTenants.filter(function (t) { return t.id === tenantId; });
    };
    RoleSwitchComponent.prototype.getTenantNameString = function (tenantId) {
        var tenant = this.allTenants.find(function (t) { return t.id === tenantId; });
        return tenant.name;
    };
    RoleSwitchComponent.prototype.getRoleNameString = function (role) {
        return this.roleChangeService.getRoleNameString(role);
    };
    return RoleSwitchComponent;
}());
exports.RoleSwitchComponent = RoleSwitchComponent;
