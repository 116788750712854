"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var get_connected_component_1 = require("./get-connected.component");
var ɵ0 = function () { return Promise.resolve().then(function () { return require("../group-detail/group-detail.module"); }).then(function (m) { return m.FuseGroupDetailModule; }); };
exports.ɵ0 = ɵ0;
var routes = [
    { path: '', component: get_connected_component_1.FuseGetConnectedComponent },
    { path: 'group', loadChildren: ɵ0 }
];
var FuseGetConnectedModule = /** @class */ (function () {
    function FuseGetConnectedModule() {
    }
    return FuseGetConnectedModule;
}());
exports.FuseGetConnectedModule = FuseGetConnectedModule;
