<div id="get-connected" class="page-layout blank p-24" fusePerfectScrollbar>

  <!-- HEADER -->
  <div class="header-social p-16 p-sm-24" fxLayout="row" fxLayoutAlign="center center">
    <h1>Mein FreiwilligenNETZWERK</h1>
    <img class="header-icon" src="assets/icons/get-connected.svg">
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content">
    <div class="layout1" fxLayout="row wrap" fxLayoutAlign="center">

      <!-- worked with -->
      <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                   fxFlex.gt-sm="50" fusePerfectScrollbar>
        <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
          <div class="px-16 py-8 border-bottom" fxLayout="row"
               fxLayoutAlign="space-between center">
            <div class="h1">Zusammengearbeitet mit</div>
          </div>

          <div *ngFor="let project of about.workedWith">
            <div fxLayout="row" class="workedWith-header" fxLayoutAlign="space-between start">
              <div class="workedWith-title">
                {{project.project}}
                <span style="font-size:16px; color:gray;">({{project.date}})</span>
              </div>
              <div>
                <button mat-icon-button title="Project Details">
                  <mat-icon>more_vert</mat-icon>
                </button>
              </div>
            </div>

            <div class="workedWith-data" fxLayout="row">
              <div *ngFor="let vol of project.volunteers" [routerLink]="'/main/get-connected/profile/1'">
                <img class="avatar big cursor" [title]="vol.name" [src]="vol.avatar">
              </div>
            </div>

          </div>


        </div>
      </fuse-widget>
      <!-- worked with end -->

      <!-- follower -->
      <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column" fxFlex="100"
                   fxFlex.gt-sm="50">
        <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
          <div class="px-16 py-8 border-bottom" fxLayout="row"
               fxLayoutAlign="space-between center">

            <mat-tab-group>
              <mat-tab label="Following">
                <div class="follow" fxLayout="row">
                  <div *ngFor="let friend of friends.following" [routerLink]="'/main/get-connected/profile/1'">
                    <img class="avatar big cursor" [title]="friend.name" [src]="friend.avatar">
                  </div>
                </div>
              </mat-tab>

              <mat-tab label="Follower">
                <div class="follow" fxLayout="row">
                  <div *ngFor="let friend of friends.follower" [routerLink]="'/main/get-connected/profile/1'">
                    <img class="avatar big cursor" [title]="friend.name" [src]="friend.avatar">
                  </div>
                </div>
              </mat-tab>

            </mat-tab-group>

          </div>
        </div>
      </fuse-widget>
      <!-- follower end -->
    </div>

    <div class="layout2" fxLayout="row wrap" fxLayoutAlign="center">
      <!-- Groups -->
      <fuse-widget [@animate]="{value:'*',params:{y:'100%'}}" class="widget" fxLayout="column"
                   fxFlex="100">
        <div class="fuse-widget-front mat-white-bg mat-elevation-z2">
          <div class="px-16 py-8 border-bottom" fxLayout="row"
               fxLayoutAlign="space-between center">
            <div class="wrap margin">
              <div class="h1">Gruppen</div>
              <div>
                <button mat-raised-button color="accent" class="newGroup-button">
                  <mat-icon>playlist_add</mat-icon>
                  Neue Gruppe anlegen
                </button>
              </div>
            </div>
          </div>

          <mat-tab-group dynamicHeight="true">

            <mat-tab label="Finde Gruppen">
              <div *ngFor="let group of groups.discover">
                <div fxLayout="row" class="workedWith-header" fxLayoutAlign="space-between start"
                     style="cursor: pointer;" routerLink="/main/get-connected/group/ad4e47e80b80e9ba">
                  <div>
                    <img class="avatar" [src]="group.icon">
                  </div>
                  <div class="group-data" fxLayout="column" fxLayoutAlign="">
                    <div class="group-name">{{group.name}}</div>
                    <div fxLayout="row">
                      <div class="group-follower">{{group.follower}} Follower</div>
                      <div class="group-member">{{group.member}} Member</div>
                    </div>
                  </div>

                  <div class="actions" fxLayout="row" fxLayoutAlign="end center">
                    <button mat-raised-button color="primary">Beitreten</button>
                    <button mat-raised-button color="primary">Ausblenden</button>
                  </div>

                </div>
              </div>
            </mat-tab>

            <mat-tab label="Deine Gruppen">
              <div *ngFor="let group of groups.yourGroups">
                <div fxLayout="row" class="workedWith-header" fxLayoutAlign="space-between start"
                     style="cursor: pointer" routerLink="/main/get-connected/group/a9f04fec753a4577">
                  <div>
                    <img class="avatar" [src]="group.icon">
                  </div>
                  <div class="group-data" fxLayout="column">
                    <div class="group-name">{{group.name}}</div>
                    <div fxLayout="row">
                      <div class="group-follower">{{group.follower}} Follower</div>
                      <div class="group-member">{{group.member}} Member</div>
                    </div>
                  </div>

                  <div class="actions" fxLayout="row" fxLayoutAlign="end center">
                    <button mat-raised-button color="primary">Leave</button>
                  </div>

                </div>
              </div>
            </mat-tab>


          </mat-tab-group>


        </div>
      </fuse-widget>
      <!-- Groups end -->
    </div>


  </div>
  <!-- / CONTENT -->
</div>
