"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var login_component_1 = require("./login.component");
var routes = [{ path: "", component: login_component_1.FuseLoginComponent }];
var FuseLoginModule = /** @class */ (function () {
    function FuseLoginModule() {
    }
    return FuseLoginModule;
}());
exports.FuseLoginModule = FuseLoginModule;
