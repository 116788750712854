<div *ngIf="isLoaded && role !== 'NONE'">
  <button mat-button [matMenuTriggerFor]="roleMenu" class="user-button" [matTooltip]="
      possibleRoleTenantMappings.length > 0 ? 'Rolle wechseln' : null
    ">
    <div fxLayout="row" fxLayoutAlign="center center">
      <img
        *ngIf="role != 'VOLUNTEER'"
        class="avatar"
        [src]="getCurrentTenantImage()"
      />
      <span class="rolename mr-12" fxHide fxShow.gt-sm>{{
        getCurrentRoleNameString()
      }}</span>
      <mat-icon
        class="s-16"
        *ngIf="possibleRoleTenantMappings.length > 0"
        fxHide.xs
        >keyboard_arrow_down</mat-icon
      >
    </div>
  </button>

  <mat-menu #roleMenu="matMenu" [overlapTrigger]="false">
    <button mat-menu-item *ngFor="let mapping of possibleRoleTenantMappings" (click)="onRoleSelected(mapping)">
      <div fxLayout="row" fxLayoutAlign="start center">
        <img
          *ngIf="mapping.role == 'VOLUNTEER'"
          class="avatar" [src]="getUserImage()"
        />
        <img  *ngIf="mapping.role != 'VOLUNTEER'" class="avatar" [src]="getImage(mapping.tenantIds[0])" />
        <span>{{ getRoleNameString(mapping.role) }}</span>
      </div>
    </button>
  </mat-menu>
</div>