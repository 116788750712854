"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var registration_component_1 = require("./registration.component");
var routes = [{ path: "", component: registration_component_1.VolunteerRegistrationComponent }];
var VolunteerRegistrationModule = /** @class */ (function () {
    function VolunteerRegistrationModule() {
    }
    return VolunteerRegistrationModule;
}());
exports.VolunteerRegistrationModule = VolunteerRegistrationModule;
