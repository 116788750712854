"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".card[_ngcontent-%COMP%]{padding-bottom:80px}.chartParent[_ngcontent-%COMP%]{height:300px;margin-bottom:80px}.chartParent2[_ngcontent-%COMP%]{height:500px}.title[_ngcontent-%COMP%]{margin-left:50px}.half[_ngcontent-%COMP%]{width:50%}table.paleBlueRows[_ngcontent-%COMP%]{margin-top:150px;border:1px solid #000;width:100%;height:200px;text-align:center;border-collapse:collapse}table.paleBlueRows[_ngcontent-%COMP%]   td[_ngcontent-%COMP%], table.paleBlueRows[_ngcontent-%COMP%]   th[_ngcontent-%COMP%]{border:1px solid #000;padding:6px 10px}table.paleBlueRows[_ngcontent-%COMP%]   tbody[_ngcontent-%COMP%]   td[_ngcontent-%COMP%]{font-size:16px;color:#000}table.paleBlueRows[_ngcontent-%COMP%]   thead[_ngcontent-%COMP%]{background:#7aa3e5;border-bottom:2px solid #000}table.paleBlueRows[_ngcontent-%COMP%]   thead[_ngcontent-%COMP%]   th[_ngcontent-%COMP%]{font-size:18px;font-weight:700;color:#fff;text-align:center;border-left:2px solid #000}table.paleBlueRows[_ngcontent-%COMP%]   thead[_ngcontent-%COMP%]   th[_ngcontent-%COMP%]:first-child{border-left:none}table.paleBlueRows[_ngcontent-%COMP%]   tfoot[_ngcontent-%COMP%]   td[_ngcontent-%COMP%]{font-size:16px}mat-panel-title[_ngcontent-%COMP%]{font-size:25px}mat-card-title[_ngcontent-%COMP%]{font-size:22px}.icon[_ngcontent-%COMP%]{margin-right:10px;height:40px}.iconSquare[_ngcontent-%COMP%]{margin-right:14px;margin-left:14px} .legend-label-text{color:#4c4c4c}"];
exports.styles = styles;
