<link
  type="text/css"
  rel="stylesheet"
  href="https://fonts.googleapis.com/css?family=Muli|Roboto:300,400,500,700|Google+Sans"
/>

<div class="page-layout blank p-24" fusePerfectScrollbar>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div></div>
    <div fxLayout="column" fxLayoutAlign="start center">
      <div style="text-align: center; font-size: 28px;">
        Einträge in der Blockchain gespeichert und an Freiwillige
        weitergeleitet!
      </div>
      <img class="pt-24 pb-24" src="/assets/images/blockchainify.gif" />
      <div fxLayout="row" fxLayoutAlign="start center">
        <!-- <button mat-raised-button type="button" color="primary" (click)="onBackClickInbox()">Zurück zur Inbox</button>
                <div class="pr-8"></div> -->
        <button
          mat-raised-button
          type="button"
          color="primary"
          (click)="onBackClickDashboard()"
        >
          Zurück
        </button>
        <!-- <div class="pr-8"></div>
                <button mat-raised-button type="button" color="primary" (click)="onBackClickNewTask()">Neue Tätigkeit erstellen</button> -->
      </div>
    </div>

    <div></div>
  </div>
</div>
