"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var forms_1 = require("@angular/forms");
var tenant_tag_service_1 = require("app/main/content/_service/tenant-tag.service");
var util_1 = require("util");
var operators_1 = require("rxjs/operators");
var TenantTagFormComponent = /** @class */ (function () {
    function TenantTagFormComponent(formBuilder, route, tenantTagService) {
        this.formBuilder = formBuilder;
        this.route = route;
        this.tenantTagService = tenantTagService;
        this.possibleTags = [];
        this.addedTags = [];
        this.tags = new core_1.EventEmitter();
    }
    TenantTagFormComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loaded = false;
                        _a = this;
                        return [4 /*yield*/, this.tenantTagService.findAllAsString().toPromise()];
                    case 1:
                        _a.possibleTags = (_b.sent());
                        this.tagForm = this.formBuilder.group({
                            inputText: new forms_1.FormControl(''),
                        });
                        this.resetFilter();
                        this.loaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    TenantTagFormComponent.prototype.handleTagAdd = function () {
        var inputText = this.tagForm.value.inputText;
        if (util_1.isNullOrUndefined(inputText)) {
            return;
        }
        var i = this.possibleTags.findIndex(function (t) { return t === inputText; });
        var j = this.addedTags.findIndex(function (t) { return t === inputText; });
        if (i <= -1 || j >= 0) {
            return;
        }
        this.addedTags.push(inputText);
        this.possibleTags = this.possibleTags.filter(function (t) { return t !== inputText; });
        this.resetFilter();
        this.inputTextField.nativeElement.blur();
        this.tagForm.reset();
        this.emitChanges();
    };
    TenantTagFormComponent.prototype.handleTagRemove = function (tag) {
        this.addedTags = this.addedTags.filter(function (t) { return t !== tag; });
        this.possibleTags.push(tag);
        this.resetFilter();
        this.emitChanges();
    };
    TenantTagFormComponent.prototype.resetFilter = function () {
        var _this = this;
        this.filteredTags = this.tagForm.controls.inputText.valueChanges
            .pipe(operators_1.startWith(''), operators_1.map(function (value) { return _this._filter(value); }));
    };
    TenantTagFormComponent.prototype._filter = function (value) {
        if (util_1.isNullOrUndefined(value)) {
            return;
        }
        var filterValue = value.toLowerCase();
        return this.possibleTags.filter(function (option) { return option.toLowerCase().includes(filterValue); });
    };
    TenantTagFormComponent.prototype.emitChanges = function () {
        this.tags.emit(this.addedTags);
    };
    return TenantTagFormComponent;
}());
exports.TenantTagFormComponent = TenantTagFormComponent;
