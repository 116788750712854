"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tenant_form_component_1 = require("./tenant-form.component");
var router_1 = require("@angular/router");
var routes = [
    { path: "", component: tenant_form_component_1.FuseTenantFormComponent },
    { path: ":tenantId", component: tenant_form_component_1.FuseTenantFormComponent },
];
var FuseTenantFormModule = /** @class */ (function () {
    function FuseTenantFormModule() {
    }
    return FuseTenantFormModule;
}());
exports.FuseTenantFormModule = FuseTenantFormModule;
