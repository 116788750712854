"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var dialog_1 = require("@angular/material/dialog");
var table_1 = require("@angular/material/table");
var share_dialog_component_1 = require("./share-dialog/share-dialog.component");
var util_1 = require("util");
var class_instance_service_1 = require("../../../../_service/meta/core/class/class-instance.service");
var material_1 = require("@angular/material");
var core_tenant_service_1 = require("../../../../_service/core-tenant.service");
var platform_browser_1 = require("@angular/platform-browser");
var router_1 = require("@angular/router");
var venn_1 = require("highcharts/modules/venn");
var Highcharts = require("highcharts");
var core_marketplace_service_1 = require("app/main/content/_service/core-marketplace.service");
var dialog_factory_component_1 = require("app/main/content/_components/_shared/dialogs/_dialog-factory/dialog-factory.component");
var login_service_1 = require("app/main/content/_service/login.service");
var core_user_service_1 = require("app/main/content/_service/core-user.service");
venn_1.default(Highcharts);
var DashboardVolunteerComponent = /** @class */ (function () {
    function DashboardVolunteerComponent(dialog, classInstanceService, marketplaceService, tenantService, sanitizer, router, iconRegistry, dialogFactory, loginService, userService) {
        this.dialog = dialog;
        this.classInstanceService = classInstanceService;
        this.marketplaceService = marketplaceService;
        this.tenantService = tenantService;
        this.sanitizer = sanitizer;
        this.router = router;
        this.iconRegistry = iconRegistry;
        this.dialogFactory = dialogFactory;
        this.loginService = loginService;
        this.userService = userService;
        this.isLoaded = false;
        this.dataSource = new table_1.MatTableDataSource();
        this.displayedColumnsRepository = [
            "issuer",
            "taskName",
            "taskType1",
            "date",
            "action",
            "share"
        ];
        this.selectedTenants = [];
        this.subscribedTenants = [];
        this.allTenants = [];
        this.marketplaceClassInstanceDTOs = [];
        this.localClassInstanceDTOs = [];
        this.filteredClassInstanceDTOs = [];
        this.sharedClassInstanceDTOs = [];
        this.mpAndLocalClassInstanceDTOs = [];
        // <original CI.id, Tenants>
        this.sharedWithMap = new Map();
        this.nrMpUnionLr = 0;
        this.isLocalRepositoryConnected = true;
        this.vennData = [];
        this.chartOptions = {
            title: {
                text: undefined
            }
        };
        this.colors = new Map([
            ["marketplace", "#50B3EF"],
            ["localRepository", "#EF8C50"]
        ]);
        this.colorsOpac = new Map([
            ["marketplace", this.colors.get("marketplace") + "66"],
            ["localRepository", this.colors.get("localRepository") + "66"]
        ]);
        this.isAllSyncing = false;
        this.isAllDesyncing = false;
        iconRegistry.addSvgIcon("info", sanitizer.bypassSecurityTrustResourceUrl("assets/icons/info.svg"));
        iconRegistry.addSvgIcon("share", sanitizer.bypassSecurityTrustResourceUrl("assets/icons/share.svg"));
        iconRegistry.addSvgIcon("plus", sanitizer.bypassSecurityTrustResourceUrl("assets/icons/plus.svg"));
        iconRegistry.addSvgIcon("minus", sanitizer.bypassSecurityTrustResourceUrl("assets/icons/minus.svg"));
        iconRegistry.addSvgIcon("storeSlash", sanitizer.bypassSecurityTrustResourceUrl("assets/icons/storeSlash.svg"));
    }
    DashboardVolunteerComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var globalInfo, _a, mpAndSharedClassInstanceDTOs, localClassInstances, _b, e_1;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.loginService.getGlobalInfo().toPromise()];
                    case 1:
                        globalInfo = (_c.sent());
                        this.volunteer = globalInfo.user;
                        this.marketplace = globalInfo.marketplace;
                        this.subscribedTenants = globalInfo.tenants;
                        this.image = this.userService.getUserProfileImage(this.volunteer);
                        _a = this;
                        return [4 /*yield*/, this.tenantService.findAll().toPromise()];
                    case 2:
                        _a.allTenants = (_c.sent());
                        this.localRepositoryService = this.loginService.getLocalRepositoryService(this.volunteer);
                        return [4 /*yield*/, this.classInstanceService
                                .getUserClassInstancesByArcheType(this.marketplace, "TASK", this.volunteer.id, this.volunteer.subscribedTenants.map(function (s) { return s.tenantId; }))
                                .toPromise()];
                    case 3:
                        mpAndSharedClassInstanceDTOs = (_c.sent());
                        mpAndSharedClassInstanceDTOs.forEach(function (ci) {
                            if (ci.tenantId != ci.issuerId) {
                                _this.sharedClassInstanceDTOs.push(ci);
                            }
                            else {
                                _this.marketplaceClassInstanceDTOs.push(ci);
                            }
                        });
                        _c.label = 4;
                    case 4:
                        _c.trys.push([4, 7, , 8]);
                        return [4 /*yield*/, this.localRepositoryService
                                .findClassInstancesByVolunteer(this.volunteer)
                                .toPromise()];
                    case 5:
                        localClassInstances = (_c.sent());
                        // TODO
                        // get unique marketplaceIds of CIs
                        // perform once per marketplaceId
                        _b = this;
                        return [4 /*yield*/, this.classInstanceService
                                .mapClassInstancesToDTOs(this.marketplace, localClassInstances)
                                .toPromise()];
                    case 6:
                        // TODO
                        // get unique marketplaceIds of CIs
                        // perform once per marketplaceId
                        _b.localClassInstanceDTOs = (_c.sent());
                        this.generateVennData();
                        // concat local and mp and remove dublicates (union)
                        this.filteredClassInstanceDTOs = this.localClassInstanceDTOs.concat(this.marketplaceClassInstanceDTOs.filter(function (mp) { return _this.localClassInstanceDTOs.map(function (lo) { return lo.id; }).indexOf(mp.id) < 0; }));
                        this.filteredClassInstanceDTOs = this.filteredClassInstanceDTOs.sort(function (a, b) {
                            if (a.dateFrom && b.dateFrom) {
                                return b.dateFrom.valueOf() - a.dateFrom.valueOf();
                            }
                        });
                        this.paginator.length = this.filteredClassInstanceDTOs.length;
                        this.dataSource.paginator = this.paginator;
                        this.dataSource.sort = this.sort;
                        this.dataSource.data = this.filteredClassInstanceDTOs;
                        this.generateSharedTenantsMap();
                        this.isLocalRepositoryConnected = true;
                        return [3 /*break*/, 8];
                    case 7:
                        e_1 = _c.sent();
                        this.isLocalRepositoryConnected = false;
                        return [3 /*break*/, 8];
                    case 8:
                        this.isLoaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardVolunteerComponent.prototype.getTenantImageById = function (tenantId) {
        var tenant = this.allTenants.find(function (t) { return t.id === tenantId; });
        return !util_1.isNullOrUndefined(tenant) ? tenant.imagePath : null;
    };
    DashboardVolunteerComponent.prototype.navigateToClassInstanceDetails = function (row) {
        this.router.navigate(["main/details/" + row.id]);
    };
    DashboardVolunteerComponent.prototype.getTenantName = function (tenantId) {
        var tenant = this.allTenants.find(function (t) { return t.id === tenantId; });
        return tenant.name;
    };
    DashboardVolunteerComponent.prototype.getIssuerName = function (tenantId) {
        var tenant = this.allTenants.find(function (t) { return t.id === tenantId; });
        if (!util_1.isNullOrUndefined(tenant)) {
            return tenant.name;
        }
        else {
            return "Unbekannt";
        }
    };
    DashboardVolunteerComponent.prototype.triggerShareDialog = function () {
        var dialogRef = this.dialog.open(share_dialog_component_1.ShareDialog, {
            width: "700px",
            height: "255px",
            data: { name: "share" }
        });
        dialogRef.afterClosed().subscribe(function (result) { });
    };
    DashboardVolunteerComponent.prototype.tenantSelectionChanged = function (selectedTenants) {
        var _this = this;
        this.selectedTenants = selectedTenants;
        // concat local and mp and remove dublicates
        this.filteredClassInstanceDTOs = this.localClassInstanceDTOs.concat(this.marketplaceClassInstanceDTOs.filter(function (mp) { return _this.localClassInstanceDTOs.map(function (lo) { return lo.id; }).indexOf(mp.id) < 0; }));
        this.filteredClassInstanceDTOs = this.filteredClassInstanceDTOs.filter(function (ci) {
            return _this.selectedTenants.findIndex(function (t) { return t.id === ci.tenantId; }) >= 0;
        });
        this.filteredClassInstanceDTOs.sort(function (a, b) {
            if (a.dateFrom && b.dateFrom) {
                return b.dateFrom.valueOf() - a.dateFrom.valueOf();
            }
        });
        this.paginator.length = this.filteredClassInstanceDTOs.length;
        this.dataSource.data = this.filteredClassInstanceDTOs;
    };
    DashboardVolunteerComponent.prototype.generateSharedTenantsMap = function () {
        var _this = this;
        this.marketplaceClassInstanceDTOs.forEach(function (ci) {
            var sharedCis = _this.sharedClassInstanceDTOs.filter(function (s) {
                return ci.timestamp === s.timestamp;
            });
            var sharedTenants = [];
            if (_this.sharedWithMap.get(ci.id)) {
                sharedTenants = _this.sharedWithMap.get(ci.id);
            }
            sharedCis.forEach(function (s) {
                sharedTenants.push(_this.allTenants.find(function (t) { return t.id === s.tenantId; }));
            });
            _this.sharedWithMap.set(ci.id, sharedTenants);
        });
    };
    // ---- Local Repository functions -----//
    DashboardVolunteerComponent.prototype.isInLocalRepository = function (classInstance) {
        return (this.localClassInstanceDTOs.findIndex(function (t) { return t.id === classInstance.id; }) >= 0);
    };
    DashboardVolunteerComponent.prototype.syncOneToLocalRepository = function (ciDTO) {
        return __awaiter(this, void 0, void 0, function () {
            var marketplace, ci;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.marketplaceService.findById(ciDTO.marketplaceId).toPromise()];
                    case 1:
                        marketplace = (_a.sent());
                        return [4 /*yield*/, this.classInstanceService
                                .getClassInstanceById(marketplace, ciDTO.id)
                                .toPromise()];
                    case 2:
                        ci = (_a.sent());
                        // remove allowedValues, since too large for localRepository... 
                        ci.properties.forEach(function (p) { return p.allowedValues = []; });
                        this.localRepositoryService
                            .synchronizeSingleClassInstance(this.volunteer, ci)
                            .toPromise()
                            .then(function () {
                            _this.localClassInstanceDTOs.push(ciDTO);
                            _this.generateVennData();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardVolunteerComponent.prototype.removeOneFromLocalRepository = function (ciDTO) {
        var _this = this;
        if (this.marketplaceClassInstanceDTOs.findIndex(function (c) { return c.id === ciDTO.id; }) === -1) {
            this.dialogFactory
                .confirmationDialog("Wirklich entfernen?", "Der Eintrag befindet sich nur mehr in Ihrem lokalen Freiwilligenpass, löschen Sie den Eintrag würde er unwiderruflich verloren gehen.")
                .then(function (ret) {
                if (ret) {
                    _this.localRepositoryService
                        .removeSingleClassInstance(_this.volunteer, ciDTO.id)
                        .toPromise()
                        .then(function () {
                        _this.localClassInstanceDTOs.splice(_this.localClassInstanceDTOs.findIndex(function (c) { return c.id === ciDTO.id; }), 1);
                        // remove row
                        _this.dataSource.data.splice(_this.dataSource.data.indexOf(ciDTO), 1);
                        _this.dataSource._updateChangeSubscription();
                        _this.generateVennData();
                    });
                }
                else {
                }
            });
        }
        else {
            this.localRepositoryService
                .removeSingleClassInstance(this.volunteer, ciDTO.id)
                .toPromise()
                .then(function () {
                _this.localClassInstanceDTOs.splice(_this.localClassInstanceDTOs.findIndex(function (c) { return c.id === ciDTO.id; }), 1);
                _this.generateVennData();
            });
        }
    };
    DashboardVolunteerComponent.prototype.syncAllToLocalRepository = function () {
        return __awaiter(this, void 0, void 0, function () {
            var missingCiDTOs, missingCis;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isAllSyncing = true;
                        missingCiDTOs = [];
                        this.filteredClassInstanceDTOs.forEach(function (ci) {
                            if (!(_this.localClassInstanceDTOs.findIndex(function (t) { return t.id === ci.id; }) >= 0)) {
                                missingCiDTOs.push(ci);
                            }
                        });
                        return [4 /*yield*/, this.classInstanceService
                                .getClassInstancesById(this.marketplace, missingCiDTOs.map(function (c) { return c.id; }))
                                .toPromise()];
                    case 1:
                        missingCis = _a.sent();
                        // remove allowedValues, since too large for localRepository... 
                        missingCis.forEach(function (ci) {
                            ci.properties.forEach(function (p) { return p.allowedValues = []; });
                        });
                        this.localRepositoryService
                            .synchronizeClassInstances(this.volunteer, missingCis)
                            .toPromise()
                            .then(function () {
                            _this.localClassInstanceDTOs = _this.localClassInstanceDTOs.concat(missingCiDTOs.filter(function (mp) {
                                return _this.localClassInstanceDTOs.map(function (lo) { return lo.id; }).indexOf(mp.id) < 0;
                            }));
                            _this.generateVennData();
                            _this.isAllSyncing = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardVolunteerComponent.prototype.removeAllFromLocalRepository = function () {
        return __awaiter(this, void 0, void 0, function () {
            var filteredClassInstances;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isAllDesyncing = true;
                        return [4 /*yield*/, this.classInstanceService
                                .getClassInstancesById(this.marketplace, this.filteredClassInstanceDTOs.map(function (c) { return c.id; }))
                                .toPromise()];
                    case 1:
                        filteredClassInstances = (_a.sent());
                        filteredClassInstances = filteredClassInstances.filter(function (c) {
                            return c !== null && _this.isSynced(c);
                        });
                        this.localRepositoryService
                            .removeClassInstances(this.volunteer, filteredClassInstances.map(function (ci) { return ci.id; }))
                            .toPromise()
                            .then(function () {
                            _this.localClassInstanceDTOs = _this.localClassInstanceDTOs.filter(function (a) { return filteredClassInstances.map(function (b) { return b.id; }).indexOf(a.id) < 0; });
                            _this.generateVennData();
                            _this.isAllDesyncing = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    // ---- Local Repository functions end -----//
    // ---- Share to marketplace functions -----//
    DashboardVolunteerComponent.prototype.getShareableTenants = function (ci) {
        // subscribedTenants - sharedWithTenats - ci.issuerId
        var tenants = [];
        var sharedWith = [];
        if (this.sharedWithMap.get(ci.id)) {
            sharedWith = this.sharedWithMap.get(ci.id);
        }
        var ownTenant = this.allTenants.find(function (t) { return t.id === ci.issuerId; });
        tenants = this.subscribedTenants.filter(function (s) {
            return sharedWith.map(function (t) { return t.id; }).indexOf(s.id) < 0;
        });
        tenants = tenants.filter(function (t) { return t.id !== ownTenant.id; });
        return tenants;
    };
    DashboardVolunteerComponent.prototype.getSharedWithTenants = function (ci) {
        return this.sharedWithMap.get(ci.id);
    };
    DashboardVolunteerComponent.prototype.shareClassInstanceToOthers = function (ci, tenant) {
        return __awaiter(this, void 0, void 0, function () {
            var marketplace, sharedCi, sharedTenants;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.marketplaceService.findById(ci.marketplaceId).toPromise()];
                    case 1:
                        marketplace = (_a.sent());
                        return [4 /*yield*/, this.classInstanceService
                                .createSharedClassInstances(marketplace, tenant.id, ci.id)
                                .toPromise()];
                    case 2:
                        sharedCi = (_a.sent());
                        this.sharedClassInstanceDTOs.push(sharedCi);
                        sharedTenants = [];
                        if (this.sharedWithMap.get(ci.id)) {
                            sharedTenants = this.sharedWithMap.get(ci.id);
                        }
                        sharedTenants.push(tenant);
                        this.sharedWithMap.set(ci.id, sharedTenants);
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardVolunteerComponent.prototype.shareClassInstanceToIssuer = function (ci) {
        return __awaiter(this, void 0, void 0, function () {
            var marketplace, share, list;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.marketplaceService.findById(ci.marketplaceId).toPromise()];
                    case 1:
                        marketplace = (_a.sent());
                        return [4 /*yield*/, this.localRepositoryService
                                .getSingleClassInstance(this.volunteer, ci.id)
                                .toPromise()];
                    case 2:
                        share = (_a.sent());
                        list = [share];
                        this.classInstanceService
                            .createNewClassInstances(marketplace, list)
                            .toPromise()
                            .then(function () {
                            _this.marketplaceClassInstanceDTOs.push(ci);
                            _this.generateVennData();
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardVolunteerComponent.prototype.revokeSharedClassInstance = function (ci, tenant) {
        return __awaiter(this, void 0, void 0, function () {
            var marketplace;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.marketplaceService.findById(ci.marketplaceId).toPromise()];
                    case 1:
                        marketplace = (_a.sent());
                        this.sharedClassInstanceDTOs.forEach(function (shared, index, array) { return __awaiter(_this, void 0, void 0, function () {
                            var sharedTenants;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!(ci.timestamp === shared.timestamp && shared.tenantId === tenant.id)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, this.classInstanceService
                                                .deleteClassInstance(marketplace, shared.id)
                                                .toPromise()];
                                    case 1:
                                        _a.sent();
                                        sharedTenants = this.sharedWithMap.get(ci.id);
                                        sharedTenants = sharedTenants.filter(function (t) { return t.id !== tenant.id; });
                                        this.sharedWithMap.set(ci.id, sharedTenants);
                                        array.splice(index, 1);
                                        _a.label = 2;
                                    case 2: return [2 /*return*/];
                                }
                            });
                        }); });
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardVolunteerComponent.prototype.removeOneFromMarketplace = function (ci) {
        return __awaiter(this, void 0, void 0, function () {
            var marketplace, toDeleteSharedCis;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.marketplaceService.findById(ci.marketplaceId).toPromise()];
                    case 1:
                        marketplace = (_a.sent());
                        return [4 /*yield*/, this.classInstanceService
                                .deleteClassInstance(marketplace, ci.id)
                                .toPromise()];
                    case 2:
                        _a.sent();
                        this.marketplaceClassInstanceDTOs = this.marketplaceClassInstanceDTOs.filter(function (mp) {
                            return mp.id != ci.id;
                        });
                        toDeleteSharedCis = this.sharedClassInstanceDTOs.filter(function (s) {
                            return s.timestamp === ci.timestamp;
                        });
                        toDeleteSharedCis.forEach(function (shared) {
                            var sharedTenants = _this.sharedWithMap.get(ci.id);
                            sharedTenants = sharedTenants.filter(function (t) { return t.id != shared.tenantId; });
                            _this.sharedWithMap.set(ci.id, sharedTenants);
                        });
                        toDeleteSharedCis.forEach(function (ci) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.classInstanceService
                                            .deleteClassInstance(marketplace, ci.id)
                                            .toPromise()];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); });
                        this.sharedClassInstanceDTOs = this.sharedClassInstanceDTOs.filter(function (s) {
                            return s.timestamp !== ci.timestamp;
                        });
                        this.generateVennData();
                        return [2 /*return*/];
                }
            });
        });
    };
    // ---- Share to marketplace functions end -----//
    // ---- table functions -----//
    DashboardVolunteerComponent.prototype.sortData = function (sort) {
        var _this = this;
        this.dataSource.data = this.dataSource.data.sort(function (a, b) {
            var isAsc = sort.direction === "asc";
            switch (sort.active) {
                case "issuer":
                    return _this.compare(_this.getIssuerName(a.issuerId), _this.getIssuerName(b.issuerId), isAsc);
                case "taskName":
                    return _this.compare(a.name, b.name, isAsc);
                case "taskType1":
                    return _this.compare(a.taskType1, b.taskType1, isAsc);
                case "date":
                    return _this.compare(a.dateFrom, b.dateFrom, isAsc);
                case "action":
                    return _this.compare(_this.isInLocalRepository(a).toString(), _this.isInLocalRepository(b).toString(), isAsc);
                default:
                    return 0;
            }
        });
    };
    DashboardVolunteerComponent.prototype.compare = function (a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    DashboardVolunteerComponent.prototype.getTableRowColor = function (ci) {
        if (this.isSynced(ci)) {
            return {
                "background-image": "repeating-linear-gradient(to right," +
                    this.colorsOpac.get("marketplace") +
                    " 0%, " +
                    this.colorsOpac.get("localRepository") +
                    " 33%, " +
                    this.colorsOpac.get("localRepository") +
                    " 33%, " +
                    this.colorsOpac.get("marketplace") +
                    " 66%, " +
                    this.colorsOpac.get("marketplace") +
                    " 66%, " +
                    this.colorsOpac.get("localRepository") +
                    " 100%)"
            };
        }
        else if (this.isLocalRepositoryOnly(ci)) {
            return {
                "background-color": this.colorsOpac.get("localRepository")
            };
        }
        else if (this.isMarketplaceOnly(ci)) {
            return {
                "background-color": this.colorsOpac.get("marketplace")
            };
        }
    };
    DashboardVolunteerComponent.prototype.isMarketplaceOnly = function (ci) {
        return (this.marketplaceClassInstanceDTOs.findIndex(function (c) { return c.id === ci.id; }) >= 0 &&
            this.localClassInstanceDTOs.findIndex(function (c) { return c.id === ci.id; }) === -1);
    };
    DashboardVolunteerComponent.prototype.isLocalRepositoryOnly = function (ci) {
        return (this.localClassInstanceDTOs.findIndex(function (c) { return c.id === ci.id; }) >= 0 &&
            this.marketplaceClassInstanceDTOs.findIndex(function (c) { return c.id === ci.id; }) === -1);
    };
    DashboardVolunteerComponent.prototype.isSynced = function (ci) {
        return (this.marketplaceClassInstanceDTOs.findIndex(function (c) { return c.id === ci.id; }) >= 0 &&
            this.localClassInstanceDTOs.findIndex(function (c) { return c.id === ci.id; }) >= 0);
    };
    // ---- table functions end -----//
    DashboardVolunteerComponent.prototype.generateVennData = function () {
        var _this = this;
        // intersection of CIs on mp and local repo
        this.mpAndLocalClassInstanceDTOs = this.localClassInstanceDTOs.filter(function (a) { return _this.marketplaceClassInstanceDTOs.map(function (b) { return b.id; }).indexOf(a.id) !== -1; });
        this.nrMpUnionLr = this.mpAndLocalClassInstanceDTOs.length;
        var data = [];
        data.push({
            sets: ["Freiwilligenpass"],
            value: this.localClassInstanceDTOs.length,
            displayValue: this.localClassInstanceDTOs.length,
            color: this.colors.get("localRepository"),
            dataLabels: {
                y: -15
            }
        }, {
            sets: ["Marktplatz"],
            value: this.marketplaceClassInstanceDTOs.length,
            displayValue: this.marketplaceClassInstanceDTOs.length,
            color: this.colors.get("marketplace")
        }, {
            sets: ["Freiwilligenpass", "Marktplatz"],
            value: this.nrMpUnionLr,
            displayValue: this.nrMpUnionLr,
            name: "Synchronisiert",
            dataLabels: {
                y: 15
            },
            color: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                    [0, this.colorsOpac.get("marketplace")],
                    [1, this.colorsOpac.get("localRepository")] // end
                ]
            }
        });
        this.vennData = __spread(data);
        this.chartOptions.series = [
            {
                name: "Anzahl Einträge",
                type: "venn",
                data: this.vennData,
                tooltip: {
                    pointFormat: "{point.name}: {point.displayValue}"
                },
                // cursor: "pointer",
                events: {
                    click: function (event) {
                        _this.onVennClicked(event);
                    }
                },
                dataLabels: {
                    align: "center",
                    allowOverlap: false
                }
            }
        ];
        Highcharts.chart("container", this.chartOptions);
    };
    DashboardVolunteerComponent.prototype.onVennClicked = function (event) { };
    return DashboardVolunteerComponent;
}());
exports.DashboardVolunteerComponent = DashboardVolunteerComponent;
