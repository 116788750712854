"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var TasksComponent = /** @class */ (function () {
    function TasksComponent() {
    }
    TasksComponent.prototype.ngOnInit = function () { };
    TasksComponent.prototype.ngOnChanges = function (changes) {
        // console.error('tasks', changes);
        for (var propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case "classInstanceDTOs": {
                        if (typeof changes.classInstanceDTOs.currentValue != "undefined") {
                            this.classInstanceDTOs = changes.classInstanceDTOs.currentValue;
                        }
                        break;
                    }
                    case "selectedTenants": {
                        if (typeof changes.selectedTenants.currentValue != "undefined") {
                            this.selectedTenants = changes.selectedTenants.currentValue;
                        }
                    }
                }
            }
        }
    };
    TasksComponent.prototype.selectedYaxisChange = function (selectedYaxis) {
        this.selectedYaxis = selectedYaxis;
    };
    TasksComponent.prototype.timelineFilterChange = function (timelineFilter) {
        this.timelineFilter = timelineFilter;
    };
    TasksComponent.prototype.selectedYearChange = function (selectedYear) {
        this.selectedYear = selectedYear;
    };
    TasksComponent.prototype.selectedTaskTypeChange = function (selectedTaskType) {
        this.selectedTaskType = selectedTaskType;
    };
    return TasksComponent;
}());
exports.TasksComponent = TasksComponent;
