"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("@angular/core");
var i1 = require("../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory");
var i2 = require("@angular/material/icon");
var i3 = require("@angular/flex-layout/flex");
var i4 = require("@angular/flex-layout/core");
var i5 = require("@angular/common");
var i6 = require("./header.component");
var i7 = require("../../../_service/core-tenant.service");
var styles_HeaderComponent = [];
var RenderType_HeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HeaderComponent, data: {} });
exports.RenderType_HeaderComponent = RenderType_HeaderComponent;
function View_HeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "mat-icon", [["class", "iconButton mat-icon notranslate"], ["role", "img"], ["style", "margin-left: 20px; color: white;"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_MatIcon_0, i1.RenderType_MatIcon)), i0.ɵdid(1, 9158656, null, 0, i2.MatIcon, [i0.ElementRef, i2.MatIconRegistry, [8, null], [2, i2.MAT_ICON_LOCATION], [2, i0.ErrorHandler]], null, null), (_l()(), i0.ɵted(-1, 0, ["keyboard_return "]))], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).inline; var currVal_1 = (((i0.ɵnov(_v, 1).color !== "primary") && (i0.ɵnov(_v, 1).color !== "accent")) && (i0.ɵnov(_v, 1).color !== "warn")); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_HeaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "header-logo"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tenant.imagePath; _ck(_v, 0, 0, currVal_0); }); }
function View_HeaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "header p-16 p-sm-24"], ["fxLayout", "row"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i0.ɵdid(1, 671744, null, 0, i3.DefaultLayoutDirective, [i0.ElementRef, i4.StyleUtils, [2, i3.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i0.ɵdid(2, 671744, null, 0, i3.DefaultLayoutAlignDirective, [i0.ElementRef, i4.StyleUtils, [2, i3.LayoutAlignStyleBuilder], i4.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h1", [["class", "l-heading"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "row"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.displayNavigateBack; _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.tenant && !_co.displayNavigateBack); _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.headerText; _ck(_v, 8, 0, currVal_4); }); }
exports.View_HeaderComponent_0 = View_HeaderComponent_0;
function View_HeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "customizable-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i0.ɵdid(1, 114688, null, 0, i6.HeaderComponent, [i7.TenantService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_HeaderComponent_Host_0 = View_HeaderComponent_Host_0;
var HeaderComponentNgFactory = i0.ɵccf("customizable-header", i6.HeaderComponent, View_HeaderComponent_Host_0, { headerText: "headerText", tenant: "tenant", displayNavigateBack: "displayNavigateBack" }, {}, []);
exports.HeaderComponentNgFactory = HeaderComponentNgFactory;
