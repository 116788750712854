"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var FuseGroupDetailComponent = /** @class */ (function () {
    function FuseGroupDetailComponent(route) {
        this.route = route;
        this.group = {
            posts: [
                {
                    'user': {
                        'name': 'Danielle Jackson',
                        'avatar': 'assets/images/avatars/danielle.jpg'
                    },
                    'message': 'Hey Guys, does somebody want to join me in the marketplace "Marketplace X"? "Marketplace Description"',
                    'time': '32 minutes ago',
                    'type': 'invitation',
                    'like': 2,
                    'share': 1
                },
                {
                    'user': {
                        'name': 'Garry Newman',
                        'avatar': 'assets/images/avatars/garry.jpg'
                    },
                    'message': 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
                    'time': '3 hours ago',
                    'type': 'something',
                    'like': 5,
                    'share': 0
                }
            ]
        };
    }
    FuseGroupDetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) { return _this.loadGroup(params['groupId']); });
    };
    FuseGroupDetailComponent.prototype.loadGroup = function (groupId) {
    };
    return FuseGroupDetailComponent;
}());
exports.FuseGroupDetailComponent = FuseGroupDetailComponent;
