"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./create-tenant.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("../../../admin/tenant-form/tenant-form-content/helpseekers-form/helpseekers-form.component.ngfactory");
var i3 = require("../../../_shared/dialogs/_dialog-factory/dialog-factory.component");
var i4 = require("@angular/material/dialog");
var i5 = require("../../../admin/tenant-form/tenant-form-content/helpseekers-form/helpseekers-form.component");
var i6 = require("../../../../_service/core-user.service");
var i7 = require("../../../../_service/login.service");
var i8 = require("../../../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive");
var i9 = require("../../../../../../../@fuse/services/config.service");
var i10 = require("@angular/cdk/platform");
var i11 = require("../../../../../../../../node_modules/@angular/material/card/typings/index.ngfactory");
var i12 = require("@angular/material/card");
var i13 = require("@angular/platform-browser/animations");
var i14 = require("../../../admin/tenant-form/tenant-form-content/tenant-form-content.component.ngfactory");
var i15 = require("../../../admin/tenant-form/tenant-form-content/tenant-form-content.component");
var i16 = require("@angular/forms");
var i17 = require("../../../../_service/core-tenant.service");
var i18 = require("../../../../_service/file.service");
var i19 = require("../../../../_service/role-change.service");
var i20 = require("@angular/common");
var i21 = require("@angular/flex-layout/flex");
var i22 = require("@angular/flex-layout/core");
var i23 = require("../../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i24 = require("@angular/material/button");
var i25 = require("@angular/cdk/a11y");
var i26 = require("./create-tenant.component");
var i27 = require("@angular/router");
var styles_CreateTenantComponent = [i0.styles];
var RenderType_CreateTenantComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CreateTenantComponent, data: {} });
exports.RenderType_CreateTenantComponent = RenderType_CreateTenantComponent;
function View_CreateTenantComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tenant-helpseekers-form", [], null, null, null, i2.View_TenantHelpseekersFormComponent_0, i2.RenderType_TenantHelpseekersFormComponent)), i1.ɵprd(512, null, i3.DialogFactoryDirective, i3.DialogFactoryDirective, [i4.MatDialog]), i1.ɵdid(2, 114688, null, 0, i5.TenantHelpseekersFormComponent, [i6.CoreUserService, i3.DialogFactoryDirective, i7.LoginService], { tenant: [0, "tenant"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tenant; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CreateTenantComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "div", [["class", "page-layout blank p-24"], ["fusePerfectScrollbar", ""], ["style", "overflow: scroll;"]], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).documentClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4407296, null, 0, i8.FusePerfectScrollbarDirective, [i1.ElementRef, i9.FuseConfigService, i10.Platform], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 17, "mat-card", [["class", "page content mat-card"]], [[2, "_mat-animation-noopable", null]], null, null, i11.View_MatCard_0, i11.RenderType_MatCard)), i1.ɵdid(3, 49152, null, 0, i12.MatCard, [[2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Um Ihre Organisation zu erstellen ben\u00F6tigen wir noch ein paar Kleinigkeiten:"])), (_l()(), i1.ɵeld(6, 0, null, 0, 1, "tenant-form-content", [], null, [[null, "tenantSaved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tenantSaved" === en)) {
        var pd_0 = (_co.handleTenantSaved($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_TenantFormContentComponent_0, i14.RenderType_TenantFormContentComponent)), i1.ɵdid(7, 114688, null, 0, i15.TenantFormContentComponent, [i16.FormBuilder, i17.TenantService, i7.LoginService, i18.FileService, i19.RoleChangeService], { tenant: [0, "tenant"] }, { tenantSaved: "tenantSaved" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_CreateTenantComponent_2)), i1.ɵdid(9, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 9, "div", [["class", "mt-16"], ["fxLayout", "row wrap"], ["fxLayoutAlign", "space-around center"]], null, null, null, null, null)), i1.ɵdid(11, 671744, null, 0, i21.DefaultLayoutDirective, [i1.ElementRef, i22.StyleUtils, [2, i21.LayoutStyleBuilder], i22.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(12, 671744, null, 0, i21.DefaultLayoutAlignDirective, [i1.ElementRef, i22.StyleUtils, [2, i21.LayoutAlignStyleBuilder], i22.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "button", [["class", "secondary-button-dark"], ["mat-stroked-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleLogoutClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i23.View_MatButton_0, i23.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i24.MatButton, [i1.ElementRef, i25.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Ausloggen"])), (_l()(), i1.ɵeld(16, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["class", "primary-button-dark"], ["mat-stroked-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleFinishedClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i23.View_MatButton_0, i23.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i24.MatButton, [i1.ElementRef, i25.FocusMonitor, [2, i13.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(-1, 0, ["Fertig"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_1 = _co.tenant; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.tenantSaved; _ck(_v, 9, 0, currVal_2); var currVal_3 = "row wrap"; _ck(_v, 11, 0, currVal_3); var currVal_4 = "space-around center"; _ck(_v, 12, 0, currVal_4); var currVal_9 = !_co.tenantSaved; _ck(_v, 18, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 3)._animationMode === "NoopAnimations"); _ck(_v, 2, 0, currVal_0); var currVal_5 = (i1.ɵnov(_v, 14).disabled || null); var currVal_6 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_5, currVal_6); var currVal_7 = (i1.ɵnov(_v, 18).disabled || null); var currVal_8 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_7, currVal_8); }); }
function View_CreateTenantComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CreateTenantComponent_1)), i1.ɵdid(1, 16384, null, 0, i20.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaded; _ck(_v, 1, 0, currVal_0); }, null); }
exports.View_CreateTenantComponent_0 = View_CreateTenantComponent_0;
function View_CreateTenantComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "create-tenant", [], null, null, null, View_CreateTenantComponent_0, RenderType_CreateTenantComponent)), i1.ɵdid(1, 114688, null, 0, i26.CreateTenantComponent, [i27.Router, i9.FuseConfigService, i7.LoginService, i6.CoreUserService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_CreateTenantComponent_Host_0 = View_CreateTenantComponent_Host_0;
var CreateTenantComponentNgFactory = i1.ɵccf("create-tenant", i26.CreateTenantComponent, View_CreateTenantComponent_Host_0, {}, {}, []);
exports.CreateTenantComponentNgFactory = CreateTenantComponentNgFactory;
