"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Tenant = /** @class */ (function () {
    function Tenant(init) {
        this.tags = [];
        Object.assign(this, init);
    }
    return Tenant;
}());
exports.Tenant = Tenant;
