"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ClassInstanceService = /** @class */ (function () {
    function ClassInstanceService(http) {
        this.http = http;
    }
    ClassInstanceService.prototype.getUserClassInstancesByArcheType = function (marketplace, archetype, userId, tenantIds) {
        return this.http.post(marketplace.url + "/meta/core/class/instance/all/by-archetype/" + archetype + "/user/" + userId, tenantIds);
    };
    ClassInstanceService.prototype.getClassInstanceById = function (marketplace, classInstanceId) {
        return this.http.get(marketplace.url + "/meta/core/class/instance/" + classInstanceId);
    };
    ClassInstanceService.prototype.getClassInstancesById = function (marketplace, classInstanceIds) {
        return this.http.post(marketplace.url + "/meta/core/class/instances", classInstanceIds);
    };
    ClassInstanceService.prototype.mapClassInstancesToDTOs = function (marketplace, classInstances) {
        return this.http.post(marketplace.url + "/meta/core/class/instanceDTOs", classInstances);
    };
    ClassInstanceService.prototype.getClassInstancesInUserInbox = function (marketplace, issuerId, tenantIds) {
        return this.http.post(marketplace.url + "/meta/core/class/instance/in-user-inbox/" + issuerId, tenantIds);
    };
    ClassInstanceService.prototype.getClassInstancesInIssuerInbox = function (marketplace, tenantId) {
        return this.http.get(marketplace.url + "/meta/core/class/instance/in-issuer-inbox?tId=" + tenantId);
    };
    ClassInstanceService.prototype.createNewClassInstances = function (marketplace, classInstances) {
        return this.http.post(marketplace.url + "/meta/core/class/instance/new", classInstances);
    };
    ClassInstanceService.prototype.createSharedClassInstances = function (marketplace, tenantId, classInstanceId) {
        return this.http.post(marketplace.url + "/meta/core/class/instance/newShared?tId=" + tenantId, classInstanceId);
    };
    ClassInstanceService.prototype.createClassInstanceByClassDefinitionId = function (marketplace, classDefinitionId, volunteerId, tenantId, properties) {
        return this.http.post(marketplace.url + "/meta/core/class/instance/from-definition/" + classDefinitionId + "/user/" + volunteerId + "?tId=" + tenantId, properties);
    };
    ClassInstanceService.prototype.issueClassInstance = function (marketplace, classInstanceIds) {
        return this.http.put(marketplace.url + "/meta/core/class/instance/issue", classInstanceIds);
    };
    ClassInstanceService.prototype.getAllClassInstances = function (marketplace, tenantId) {
        return this.http.get(marketplace.url + "/meta/core/class/instance/all?tId=" + tenantId);
    };
    ClassInstanceService.prototype.getClassInstancesByArcheType = function (marketplace, archetype, tenantId) {
        return this.http.get(marketplace.url + "/meta/core/class/instance/all/by-archetype/" + archetype + "?tId=" + tenantId);
    };
    ClassInstanceService.prototype.deleteClassInstance = function (marketplace, classInstanceId) {
        return this.http.delete(marketplace.url + "/meta/core/class/instance/" + classInstanceId + "/delete");
    };
    ClassInstanceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClassInstanceService_Factory() { return new ClassInstanceService(i0.ɵɵinject(i1.HttpClient)); }, token: ClassInstanceService, providedIn: "root" });
    return ClassInstanceService;
}());
exports.ClassInstanceService = ClassInstanceService;
