"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var user_1 = require("../../../_model/user");
var class_1 = require("../../../_model/meta/class");
var class_instance_service_1 = require("../../../_service/meta/core/class/class-instance.service");
var util_1 = require("util");
var login_service_1 = require("../../../_service/login.service");
var collections_1 = require("@angular/cdk/collections");
var user_service_1 = require("app/main/content/_service/user.service");
var material_1 = require("@angular/material");
var AssetInboxHelpseekerComponent = /** @class */ (function () {
    function AssetInboxHelpseekerComponent(loginService, router, classInstanceService, userService) {
        this.loginService = loginService;
        this.router = router;
        this.classInstanceService = classInstanceService;
        this.userService = userService;
        this.marketplaces = new Array();
        this.selection = new collections_1.SelectionModel(true, []);
        this.datasource = new material_1.MatTableDataSource();
        this.issuers = [];
        this.volunteers = [];
        this.displayedColumns = ["checkboxes", "label", "archetype", "user", "date"];
    }
    AssetInboxHelpseekerComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var globalInfo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.loginService.getGlobalInfo().toPromise()];
                    case 1:
                        globalInfo = (_a.sent());
                        this.user = globalInfo.user;
                        this.marketplace = globalInfo.marketplace;
                        this.tenant = globalInfo.tenants[0];
                        this.loadInboxEntries();
                        return [2 /*return*/];
                }
            });
        });
    };
    AssetInboxHelpseekerComponent.prototype.loadInboxEntries = function () {
        var _this = this;
        this.classInstanceService
            .getClassInstancesInIssuerInbox(this.marketplace, this.tenant.id)
            .toPromise()
            .then(function (ret) {
            _this.classInstanceDTOs = ret;
            _this.isLoaded = true;
            console.error(_this.classInstanceDTOs);
            _this.sortClassInstances();
            _this.loadUsers();
        });
    };
    AssetInboxHelpseekerComponent.prototype.sortClassInstances = function () {
        this.classInstanceDTOs.sort(function (a, b) { return a.blockchainDate.valueOf() - b.blockchainDate.valueOf(); });
    };
    AssetInboxHelpseekerComponent.prototype.loadUsers = function () {
        var _this = this;
        if (!util_1.isNullOrUndefined(this.classInstanceDTOs)) {
            Promise.all([
                this.userService
                    .findAllByRole(this.marketplace, user_1.UserRole.HELP_SEEKER)
                    .toPromise()
                    .then(function (issuers) {
                    _this.issuers = issuers;
                }),
                this.userService
                    .findAllByRole(this.marketplace, user_1.UserRole.VOLUNTEER)
                    .toPromise()
                    .then(function (volunteers) {
                    _this.volunteers = volunteers;
                })
            ]);
        }
        else {
            this.classInstanceDTOs = [];
        }
        this.datasource.data = this.classInstanceDTOs;
    };
    AssetInboxHelpseekerComponent.prototype.onAssetInboxSubmit = function () {
        var _this = this;
        this.classInstanceService
            .issueClassInstance(this.marketplace, this.classInstanceDTOs.map(function (c) { return c.id; }))
            .toPromise()
            .then(function () {
            _this.router.navigate(["main/helpseeker/asset-inbox/confirm"], {
                state: {
                    instances: _this.classInstanceDTOs,
                    marketplace: _this.marketplace,
                    participant: _this.user
                }
            });
        });
    };
    AssetInboxHelpseekerComponent.prototype.getDateString = function (dateNumber) {
        var date = new Date(dateNumber);
        return date.toLocaleDateString() + " " + date.toLocaleTimeString();
    };
    AssetInboxHelpseekerComponent.prototype.getNameForEntry = function (personId, type) {
        var person;
        if (type === "issuer") {
            person = this.issuers.find(function (i) { return i.id === personId; });
        }
        else {
            person = this.volunteers.find(function (i) { return i.id === personId; });
        }
        if (util_1.isNullOrUndefined(person)) {
            return "";
        }
        return person.firstname + " " + person.lastname;
    };
    AssetInboxHelpseekerComponent.prototype.getIssuerPositionForEntry = function (personId) {
        var user = this.issuers.find(function (p) { return p.id === personId; });
        if (util_1.isNullOrUndefined(user) ||
            util_1.isNullOrUndefined(user.organizationPosition)) {
            return "";
        }
        else {
            return "(" + user.organizationPosition + ")";
        }
    };
    AssetInboxHelpseekerComponent.prototype.findNameProperty = function (entry) {
        if (util_1.isNullOrUndefined(entry.name)) {
            return "";
        }
        else {
            return entry.name;
        }
    };
    AssetInboxHelpseekerComponent.prototype.getImagePathById = function (id) {
        if (util_1.isNullOrUndefined(this.userImagePaths)) {
            return "/assets/images/avatars/profile.jpg";
        }
        var ret = this.userImagePaths.find(function (userImagePath) {
            return userImagePath.userId === id;
        });
        if (util_1.isNullOrUndefined(ret)) {
            return "/assets/images/avatars/profile.jpg";
        }
        else {
            return ret.imagePath;
        }
    };
    AssetInboxHelpseekerComponent.prototype.getArchetypeIcon = function (entry) {
        if (util_1.isNullOrUndefined(entry.imagePath)) {
            if (entry.classArchetype === class_1.ClassArchetype.COMPETENCE) {
                return "/assets/competence.jpg";
            }
            else if (entry.classArchetype === class_1.ClassArchetype.ACHIEVEMENT) {
                return "/assets/icons/award.svg";
            }
            else if (entry.classArchetype === class_1.ClassArchetype.FUNCTION) {
                return "/assets/TODO";
            }
            else if (entry.classArchetype === class_1.ClassArchetype.TASK) {
                return "/assets/cog.png";
            }
            else {
                return "/assets/NONE";
            }
        }
        else {
            return entry.imagePath;
        }
    };
    AssetInboxHelpseekerComponent.prototype.getArchetypeName = function (entry) {
        if (entry.classArchetype === class_1.ClassArchetype.COMPETENCE) {
            return "Kompetenz";
        }
        else if (entry.classArchetype === class_1.ClassArchetype.ACHIEVEMENT) {
            return "Verdienst";
        }
        else if (entry.classArchetype === class_1.ClassArchetype.FUNCTION) {
            return "Funktion";
        }
        else if (entry.classArchetype === class_1.ClassArchetype.TASK) {
            return "Tätigkeit";
        }
        else {
            return "";
        }
    };
    /** Whether the number of selected elements matches the total number of rows. */
    AssetInboxHelpseekerComponent.prototype.isAllSelected = function () {
        var numSelected = this.selection.selected.length;
        var numRows = this.datasource.data.length;
        return numSelected === numRows;
    };
    /** Selects all rows if they are not all selected; otherwise clear selection. */
    AssetInboxHelpseekerComponent.prototype.masterToggle = function () {
        var _this = this;
        this.isAllSelected()
            ? this.selection.clear()
            : this.datasource.data.forEach(function (row) { return _this.selection.select(row); });
    };
    AssetInboxHelpseekerComponent.prototype.navigateBack = function () {
        window.history.back();
    };
    return AssetInboxHelpseekerComponent;
}());
exports.AssetInboxHelpseekerComponent = AssetInboxHelpseekerComponent;
