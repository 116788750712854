"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = [".page-layout[_ngcontent-%COMP%]   .content[_ngcontent-%COMP%]{flex:1}.page-layout[_ngcontent-%COMP%]   .wrap[_ngcontent-%COMP%]{display:flex;width:100%;justify-content:space-between}.page-layout[_ngcontent-%COMP%]   .margin[_ngcontent-%COMP%]   div[_ngcontent-%COMP%]:last-child{margin-left:auto}.page-layout[_ngcontent-%COMP%]   .mat-tab-label[_ngcontent-%COMP%]{flex:1 auto}.page-layout[_ngcontent-%COMP%]   .workedWith-title[_ngcontent-%COMP%]{font-size:24px}.page-layout[_ngcontent-%COMP%]   .workedWith-header[_ngcontent-%COMP%]{padding-left:10px;padding-top:15px}.page-layout[_ngcontent-%COMP%]   .workedWith-data[_ngcontent-%COMP%]{margin-left:15px;margin-bottom:10px;display:flex;flex-wrap:wrap}.page-layout[_ngcontent-%COMP%]   td[_ngcontent-%COMP%]{padding:8px}.page-layout[_ngcontent-%COMP%]   .group-name[_ngcontent-%COMP%]{font-size:20px}.page-layout[_ngcontent-%COMP%]   .group-follower[_ngcontent-%COMP%], .page-layout[_ngcontent-%COMP%]   .group-member[_ngcontent-%COMP%]{margin-left:10px;color:grey}.page-layout[_ngcontent-%COMP%]   .mat-raised-button[_ngcontent-%COMP%]{margin-right:20px}.page-layout[_ngcontent-%COMP%]   .follow[_ngcontent-%COMP%]{margin-left:15px;margin-bottom:10px;margin-top:15px;display:flex;flex-wrap:wrap}.page-layout[_ngcontent-%COMP%]   .actions[_ngcontent-%COMP%]{margin-left:auto}.page-layout[_ngcontent-%COMP%]   .cursor[_ngcontent-%COMP%]{cursor:pointer}.page-layout[_ngcontent-%COMP%]   div.layout1[_ngcontent-%COMP%]     .mat-tab-labels{margin-top:-6px}.page-layout[_ngcontent-%COMP%]   div.layout1[_ngcontent-%COMP%]     .mat-tab-label{font-size:24px;font-weight:400;color:#000}"];
exports.styles = styles;
