"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var LocalRepository = /** @class */ (function () {
    function LocalRepository(id, volunteerUsername) {
        this.id = id;
        this.volunteerUsername = volunteerUsername;
        this.classInstances = [];
    }
    return LocalRepository;
}());
exports.LocalRepository = LocalRepository;
