"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var login_service_1 = require("../_service/login.service");
var user_1 = require("../_model/user");
var i0 = require("@angular/core");
var i1 = require("../_service/login.service");
var i2 = require("@angular/router");
var HelpSeekerOrTenantAdminGuard = /** @class */ (function () {
    function HelpSeekerOrTenantAdminGuard(loginService, router) {
        this.loginService = loginService;
        this.router = router;
    }
    HelpSeekerOrTenantAdminGuard.prototype.canActivate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.loginService
                .getLoggedInUserRole()
                .toPromise()
                .then(function (role) {
                resolve(role === user_1.UserRole.TENANT_ADMIN || role === user_1.UserRole.HELP_SEEKER);
            });
        });
    };
    HelpSeekerOrTenantAdminGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HelpSeekerOrTenantAdminGuard_Factory() { return new HelpSeekerOrTenantAdminGuard(i0.ɵɵinject(i1.LoginService), i0.ɵɵinject(i2.Router)); }, token: HelpSeekerOrTenantAdminGuard, providedIn: "root" });
    return HelpSeekerOrTenantAdminGuard;
}());
exports.HelpSeekerOrTenantAdminGuard = HelpSeekerOrTenantAdminGuard;
