"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var router_1 = require("@angular/router");
var login_service_1 = require("../_service/login.service");
var user_1 = require("../_model/user");
var i0 = require("@angular/core");
var i1 = require("../_service/login.service");
var LoginGuard = /** @class */ (function () {
    function LoginGuard(loginService) {
        this.loginService = loginService;
    }
    LoginGuard.prototype.canActivate = function () {
        var _this = this;
        return new Promise(function (resolve) {
            var globalInfo = JSON.parse(localStorage.getItem("globalInfo"));
            if (globalInfo == null || globalInfo.user == null) {
                _this.loginService.logout();
            }
            _this.loginService
                .getLoggedInUserRole()
                .toPromise()
                .then(function (role) {
                resolve(role == user_1.UserRole.HELP_SEEKER ||
                    role == user_1.UserRole.VOLUNTEER ||
                    role == user_1.UserRole.RECRUITER ||
                    role == user_1.UserRole.FLEXPROD ||
                    role == user_1.UserRole.ADMIN ||
                    role == user_1.UserRole.TENANT_ADMIN ||
                    role == user_1.UserRole.NONE);
            });
        });
    };
    LoginGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoginGuard_Factory() { return new LoginGuard(i0.ɵɵinject(i1.LoginService)); }, token: LoginGuard, providedIn: "root" });
    return LoginGuard;
}());
exports.LoginGuard = LoginGuard;
