"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./tenant-form.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./tenant-form-content/tenant-form-content.component.ngfactory");
var i3 = require("../../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive");
var i4 = require("../../../../../../@fuse/services/config.service");
var i5 = require("@angular/cdk/platform");
var i6 = require("./tenant-form-content/tenant-form-content.component");
var i7 = require("@angular/forms");
var i8 = require("../../../_service/core-tenant.service");
var i9 = require("../../../_service/login.service");
var i10 = require("../../../_service/file.service");
var i11 = require("../../../_service/role-change.service");
var i12 = require("./tenant-form-content/helpseekers-form/helpseekers-form.component.ngfactory");
var i13 = require("../../_shared/dialogs/_dialog-factory/dialog-factory.component");
var i14 = require("@angular/material/dialog");
var i15 = require("./tenant-form-content/helpseekers-form/helpseekers-form.component");
var i16 = require("../../../_service/core-user.service");
var i17 = require("../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory");
var i18 = require("@angular/material/button");
var i19 = require("@angular/cdk/a11y");
var i20 = require("@angular/platform-browser/animations");
var i21 = require("@angular/flex-layout/flex");
var i22 = require("@angular/flex-layout/core");
var i23 = require("@angular/common");
var i24 = require("./tenant-form.component");
var i25 = require("@angular/router");
var i26 = require("../../../_service/core-marketplace.service");
var styles_FuseTenantFormComponent = [i0.styles];
var RenderType_FuseTenantFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FuseTenantFormComponent, data: {} });
exports.RenderType_FuseTenantFormComponent = RenderType_FuseTenantFormComponent;
function View_FuseTenantFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "content p-24 mat-white-bg mt-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "tenant-form-content", [["fusePerfectScrollbar", ""]], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).documentClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_TenantFormContentComponent_0, i2.RenderType_TenantFormContentComponent)), i1.ɵdid(2, 4407296, null, 0, i3.FusePerfectScrollbarDirective, [i1.ElementRef, i4.FuseConfigService, i5.Platform], null, null), i1.ɵdid(3, 114688, null, 0, i6.TenantFormContentComponent, [i7.FormBuilder, i8.TenantService, i9.LoginService, i10.FileService, i11.RoleChangeService], { tenant: [0, "tenant"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "tenant-helpseekers-form", [], null, [[null, "tenantSaved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tenantSaved" === en)) {
        var pd_0 = (_co.handleTenantSaved($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i12.View_TenantHelpseekersFormComponent_0, i12.RenderType_TenantHelpseekersFormComponent)), i1.ɵprd(512, null, i13.DialogFactoryDirective, i13.DialogFactoryDirective, [i14.MatDialog]), i1.ɵdid(6, 114688, null, 0, i15.TenantHelpseekersFormComponent, [i16.CoreUserService, i13.DialogFactoryDirective, i9.LoginService], { tenant: [0, "tenant"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "secondary-button-dark mt-12"], ["mat-stroked-button", ""], ["type", "button"]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.handleToDashboardClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_MatButton_0, i17.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i18.MatButton, [i1.ElementRef, i19.FocusMonitor, [2, i20.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Zur Landing Page"]))], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = _co.tenant; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.tenant; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 8).disabled || null); var currVal_3 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_2, currVal_3); }); }
function View_FuseTenantFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "page-layout blank p-24"], ["fusePerfectScrollbar", ""]], null, [["document", "click"]], function (_v, en, $event) { var ad = true; if (("document:click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).documentClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4407296, null, 0, i3.FusePerfectScrollbarDirective, [i1.ElementRef, i4.FuseConfigService, i5.Platform], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "header mat-accent-bg p-16 p-sm-24"], ["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i21.DefaultLayoutDirective, [i1.ElementRef, i22.StyleUtils, [2, i21.LayoutStyleBuilder], i22.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(4, 671744, null, 0, i21.DefaultLayoutAlignDirective, [i1.ElementRef, i22.StyleUtils, [2, i21.LayoutAlignStyleBuilder], i22.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Organisation bearbeiten"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FuseTenantFormComponent_1)), i1.ɵdid(8, 16384, null, 0, i23.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "column"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.loaded; _ck(_v, 8, 0, currVal_2); }, null); }
exports.View_FuseTenantFormComponent_0 = View_FuseTenantFormComponent_0;
function View_FuseTenantFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tenant-form", [], null, null, null, View_FuseTenantFormComponent_0, RenderType_FuseTenantFormComponent)), i1.ɵdid(1, 114688, null, 0, i24.FuseTenantFormComponent, [i25.ActivatedRoute, i25.Router, i8.TenantService, i26.MarketplaceService, i9.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
exports.View_FuseTenantFormComponent_Host_0 = View_FuseTenantFormComponent_Host_0;
var FuseTenantFormComponentNgFactory = i1.ɵccf("tenant-form", i24.FuseTenantFormComponent, View_FuseTenantFormComponent_Host_0, {}, {}, []);
exports.FuseTenantFormComponentNgFactory = FuseTenantFormComponentNgFactory;
