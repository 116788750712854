<ng-container *ngIf="layout == 'vertical'">
  <div class="navbar-vertical">
    <div class="navbar-header">
      <div class="logo">
        <img class="logo-icon" src="assets/logo.png" />
        <span class="logo-text">iVolunteer</span>
      </div>

      <button
        mat-icon-button
        class="toggle-button-navbar"
        (click)="toggleSidebarFolded()"
        fxHide.lt-lg
      >
        <mat-icon>menu</mat-icon>
      </button>

      <button
        mat-icon-button
        class="toggle-button-navbar"
        (click)="toggleSidebarOpened()"
        fxHide.gt-md
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
    </div>

    <div class="navbar-content" fusePerfectScrollbar>
      <fuse-navigation
        [navigation]="navigation"
        layout="vertical"
      ></fuse-navigation>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="layout == 'horizontal'">
  <div class="navbar-horizontal">
    <fuse-navigation
      [navigation]="navigation"
      layout="horizontal"
    ></fuse-navigation>
  </div>
</ng-container>
