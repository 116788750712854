"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var styles = ["[_nghost-%COMP%]{position:relative;display:flex;flex:0 0 auto;z-index:4}.below[_nghost-%COMP%]{z-index:2}[_nghost-%COMP%]   .mat-toolbar[_ngcontent-%COMP%]{background:inherit;color:inherit;position:relative}[_nghost-%COMP%]   .mat-toolbar[_ngcontent-%COMP%]   .loading-bar[_ngcontent-%COMP%]{position:absolute;top:0;left:0;right:0;width:100%}[_nghost-%COMP%]   .logo[_ngcontent-%COMP%]{display:flex;align-items:center}[_nghost-%COMP%]   .logo[_ngcontent-%COMP%]   .logo-icon[_ngcontent-%COMP%]{width:38px}[_nghost-%COMP%]   .language-button[_ngcontent-%COMP%], [_nghost-%COMP%]   .quick-panel-toggle-button[_ngcontent-%COMP%], [_nghost-%COMP%]   .user-button[_ngcontent-%COMP%], [_nghost-%COMP%]   fuse-search-bar[_ngcontent-%COMP%]{min-width:64px;height:64px}@media (max-width:599px){[_nghost-%COMP%]   .language-button[_ngcontent-%COMP%], [_nghost-%COMP%]   .quick-panel-toggle-button[_ngcontent-%COMP%], [_nghost-%COMP%]   .user-button[_ngcontent-%COMP%], [_nghost-%COMP%]   fuse-search-bar[_ngcontent-%COMP%]{height:56px}}[_nghost-%COMP%]   .toggle-button-navbar[_ngcontent-%COMP%]{min-width:56px;height:56px}[_nghost-%COMP%]   .toolbar-separator[_ngcontent-%COMP%]{height:64px;width:1px;background:rgba(0,0,0,.12)}@media (max-width:599px){[_nghost-%COMP%]   .toolbar-separator[_ngcontent-%COMP%]{height:56px}}.username[_ngcontent-%COMP%]{font-size:20px}"];
exports.styles = styles;
