<div id="login" fxLayout="row" fxLayoutAlign="start">
  <div id="login-intro" fxFlex fxHide fxShow.gt-xs>
    <div class="logo" *fuseIfOnDom [@animate]="{ value: '*', params: { scale: '0.2' } }">
      <img src="assets/logo.png" />
    </div>

    <div class="title" *fuseIfOnDom [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }">
      Willkommen bei iVolunteer!
    </div>
  </div>

  <div id="login-form-wrapper" fusePerfectScrollbar *fuseIfOnDom
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }">
    <div id="login-form">
      <div *ngIf="displayLoginForm">
        <div class="title">Anmeldung</div>

        <form name="loginForm" (submit)="login()" [formGroup]="loginForm" novalidate>
          <mat-form-field>
            <input matInput placeholder="Benutzername" formControlName="username" />
            <mat-error *ngIf="
              loginFormErrors.username && loginFormErrors.username.required
            ">
              Username is required
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <input matInput type="password" placeholder="Passwort" formControlName="password" />
            <mat-error *ngIf="
              loginFormErrors.password && loginFormErrors.password.required
            ">
              Password is required
            </mat-error>
          </mat-form-field>
          <p *ngIf="error" class="text-align-center error">
            Username oder Passwort falsch!
          </p>

          <button type="submit" mat-raised-button color="accent" class="submit-button" aria-label="LOGIN"
            [disabled]="loginForm.invalid">
            LOGIN
          </button>
        </form>

        <div class="register" fxLayout="column" fxLayoutAlign="center center">
          <div class="text">
            <div>Noch nicht registriert?</div>
            <div class="pl-8">Hier neues Konto erstellen.</div>
          </div>
          <div fxLayout="column" class="pt-12">
            <a class="link" routerLink="/register/volunteer">- Als Freiwilliger registrieren</a>
            <a class="link" routerLink="/register/organization">- Als Organisation registrieren</a>
          </div>
        </div>
      </div>
      <div *ngIf="!displayLoginForm">
        <h1>Login fehlgeschlagen</h1>
        <div>
          <p>Der angegebene Benutzer wurde noch nicht aktiviert.</p>
          <p>Bitte den Anweisungen in der bei der Anmeldung verschickten Aktivierungs-E-Mail folgen. <br>
            Alternativ kann auch eine neue E-Mail angefordert werden.</p>
        </div>
        <div class="mt-32">
          <button [disabled]="resendActivationFlow" mat-stroked-button class="primary-button-dark"
            (click)="resendActivationFlow = true">
            Aktivierung erneut senden
          </button>
        </div>
        <div *ngIf="resendActivationFlow">
          <resend-link class="mt-20">
          </resend-link>

          <button mat-stroked-button class="secondary-button-light mt-8 full-width" (click)="ngOnInit()">
            Zurück zum Login
          </button>
        </div>
      </div>



    </div>
  </div>
</div>