"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var achievement_component_1 = require("./achievement.component");
var routes = [{ path: "", component: achievement_component_1.AchievementsComponent }];
var AchievementsModule = /** @class */ (function () {
    function AchievementsModule() {
    }
    return AchievementsModule;
}());
exports.AchievementsModule = AchievementsModule;
