"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var table_1 = require("@angular/material/table");
var stored_chart_service_1 = require("../../../_service/stored-chart.service");
var login_service_1 = require("../../../_service/login.service");
var core_marketplace_service_1 = require("../../../_service/core-marketplace.service");
var util_1 = require("util");
var class_instance_service_1 = require("../../../_service/meta/core/class/class-instance.service");
var material_1 = require("@angular/material");
var core_tenant_service_1 = require("../../../_service/core-tenant.service");
var user_service_1 = require("app/main/content/_service/user.service");
var RecruitViewComponent = /** @class */ (function () {
    function RecruitViewComponent(storedChartService, loginService, marketplaceService, classInstanceService, coreTenantService, userService) {
        this.storedChartService = storedChartService;
        this.loginService = loginService;
        this.marketplaceService = marketplaceService;
        this.classInstanceService = classInstanceService;
        this.coreTenantService = coreTenantService;
        this.userService = userService;
        this.tableDataSource = new table_1.MatTableDataSource([]);
        this.displayedColumns = [
            "taskName",
            "taskType1",
            "taskDateFrom",
            "taskDuration",
            "hash",
            "verificationStatus"
        ];
        this.classInstanceDTOs = [];
        // chart options
        this.colorScheme = "cool";
        this.showXAxis = true;
        this.showYAxis = true;
        this.gradient = false;
        this.showLegend = true;
        this.legendTitle = " ";
        this.showXAxisLabel = false;
        this.showYAxisLabel = true;
        this.xAxisLabel = "Datum";
        this.yAxisLabel1 = "Stunden";
        this.yAxisLabel2 = "Anzahl";
        this.animations = true;
        this.tenantName = "FF Eidenberg";
        this.tenantId = [];
        this.verify1 = false;
        this.verify2 = false;
        this.verify3 = false;
        this.verify5 = false;
    }
    RecruitViewComponent.prototype.ngOnInit = function () { };
    RecruitViewComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        Promise.all([
            this.marketplaceService
                .findAll()
                .toPromise()
                .then(function (marketplaces) {
                if (!util_1.isNullOrUndefined(marketplaces)) {
                    _this.marketplace = marketplaces[0];
                }
            }),
            this.loginService
                .getLoggedIn()
                .toPromise()
                .then(function (user) {
                _this.user = user;
            })
        ]).then(function () {
            _this.loadStoredCharts();
            _this.loadTasks();
        });
    };
    RecruitViewComponent.prototype.loadTasks = function () {
        var _this = this;
        this.coreTenantService
            .findByName(this.tenantName)
            .toPromise()
            .then(function (tenantId) {
            _this.tenantId.push(tenantId);
            _this.userService
                .findByName(_this.marketplace, "mweixlbaumer")
                .toPromise()
                .then(function (volunteer) {
                _this.volunteer = volunteer;
                _this.classInstanceService
                    .getUserClassInstancesByArcheType(_this.marketplace, "TASK", _this.volunteer.id, _this.tenantId)
                    .toPromise()
                    .then(function (ret) {
                    if (!util_1.isNullOrUndefined(ret)) {
                        _this.classInstanceDTOs = ret.sort(function (a, b) {
                            return b.blockchainDate.valueOf() - a.blockchainDate.valueOf();
                        });
                        _this.tableDataSource.paginator = _this.paginator;
                        _this.paginator.length = _this.classInstanceDTOs.length;
                        _this.tableDataSource.data = _this.classInstanceDTOs;
                    }
                });
            });
        });
    };
    RecruitViewComponent.prototype.loadStoredCharts = function () {
        var _this = this;
        this.storedChartService
            .findAll(this.marketplace)
            .toPromise()
            .then(function (storedCharts) {
            _this.charts = storedCharts;
            if (_this.charts.findIndex(function (c) { return c.title === "Wochentag"; }) >= 0) {
                _this.weekdayData = JSON.parse(_this.charts.find(function (c) { return c.title === "Wochentag"; }).data);
            }
            if (_this.charts.findIndex(function (c) { return c.title == "Tageszeit"; }) >= 0) {
                _this.dayNightData = JSON.parse(_this.charts.find(function (c) { return c.title === "Tageszeit"; }).data);
            }
            if (_this.charts.findIndex(function (c) { return c.title == "STUNDEN absolvierter Ausbildungen"; }) >= 0) {
                _this.trainingData = JSON.parse(_this.charts.find(function (c) { return c.title == "STUNDEN absolvierter Ausbildungen"; }).data);
            }
            if (_this.charts.findIndex(function (c) {
                return c.title ==
                    "Engagement in verschiedenen Tätigkeitsarten im Zeitverlauf";
            }) >= 0) {
                _this.taskData = JSON.parse(_this.charts.find(function (c) {
                    return c.title ==
                        "Engagement in verschiedenen Tätigkeitsarten im Zeitverlauf";
                }).data);
            }
            // Engagement in verschiedenen Tätigkeitsarten im Zeitverlauf
        });
    };
    RecruitViewComponent.prototype.pressedVerifyAll = function () {
        var outer = this;
        setTimeout(function () {
            outer.verify2 = true;
        }, 3000);
        setTimeout(function () {
            outer.verify3 = true;
        }, 5000);
        setTimeout(function () {
            outer.verify5 = true;
        }, 1000);
        setTimeout(function () {
            outer.verify1 = true;
        }, 2000);
    };
    RecruitViewComponent.prototype.getVerifyState = function (index) {
        if (index % 2 === 0) {
            return this.verify2;
        }
        else if (index % 3 === 0) {
            return this.verify3;
        }
        else if (index % 5 === 0) {
            return this.verify5;
        }
        else {
            return this.verify1;
        }
    };
    return RecruitViewComponent;
}());
exports.RecruitViewComponent = RecruitViewComponent;
