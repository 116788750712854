<link type="text/css" rel="stylesheet" href="https://fonts.googleapis.com/css?family=Muli|Roboto:300,400,500,700|Google+Sans">


<div class="page-layout blank p-24" fusePerfectScrollbar>

  <div style="padding-top: 12px"></div>

  <div class="header p-16 p-sm-24" fxLayout="row" fxLayoutAlign="space-between center" style="margin-left: 0px">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div style="padding-top: 0px; z-index: 255; position: relative; width: 85px; height: 85px;">
        <img style="object-fit: contain; max-width: 100%; max-height: 100%" src="../../../../assets//images/logos/recruiter.jpg">
      </div>

      <h1 style="padding-left: 5px; font-size: 22px; color: white; padding-top: 50px">
        Personaldienstleister: 'Best Jobs'</h1>
    </div>
    <!-- <div style="padding-left: 80px"></div> -->

    <div></div>

    <h1 style="text-align: end">Bewerbungen</h1>
    <!-- <div></div> -->
  </div>

  <div style="padding-top:15px"></div>

  <br>

  <div class="mat-card">

    <div style="padding-top: 20px" div>
      <div style="padding-top: 12px; padding-left: 50px; padding-right: 50px; padding-bottom: 12px">
        <mat-accordion style="padding: 10px 0;">
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title fxLayout=row fxLayoutAlign="space-between center">
                <div fxLayout=row fxLayoutAlign="start start">
                  <img class="avatar" src="../../../../assets//images/avatars/Blair.jpg">
                  <div style="padding-left: 10px"></div>
                  <div style="padding-top: 7px; font-size: 19px">Toni Helfer</div>
                </div>
                <div fxLayout=row fxLayoutAlign="end center">
                  <!-- <div>Verified Status </div> -->
                  <div fxLayout=column fxLayoutAlign="center center">
                    <mat-progress-bar style="width:250px" class="green-yellow-progress" value=100>
                    </mat-progress-bar>
                    <div>vollständig verifiziert</div>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>

          <mat-expansion-panel expanded=true>
            <mat-expansion-panel-header>
              <mat-panel-title fxLayout=row fxLayoutAlign="space-between center">
                <div fxLayout=row fxLayoutAlign="start start">
                  <img class="avatar" src="../../../../assets/images/avatars/weixlbaumer_small.png">
                  <div style="padding-left: 10px"></div>
                  <div style="padding-top: 7px; font-size: 19px">Markus Weixlbaumer</div>
                </div>

                <div fxLayout=row fxLayoutAlign="end center">
                  <!-- <div>Verified Status </div> -->
                  <div fxLayout=column fxLayoutAlign="center center">
                    <mat-progress-bar *ngIf="verifyStage==0" style="width:250px" class="green-yellow-progress" value=83>
                    </mat-progress-bar>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div style="padding-top: 20px"></div>

            <div fxLayout="row" fxLayoutAlign="start center">
              <div style="padding-right: 100px"></div>
              <img class="profile-img" src="../../../../assets/images/avatars/weixlbaumer.jpg">
              <div style="padding-right: 25px"></div>
              <div fxLayout="column" fxLayoutAlign="start start">
                <div style="font-size: 30px">Markus Weixlbaumer</div>
                <div style="padding-top: 20px"></div>
                <div style="font-size: 18px">Freiwillig seit: 2000</div>
                <div style="font-size: 18px">
                  <strong>FF Eidenberg</strong>, <strong>RK Wilhering</strong> und <strong>Musikverein Schwertberg</strong>
                </div>
                <a style="font-size: 15px; cursor: pointer">mehr</a>
              </div>
            </div>

            <div style="padding-top: 0px pr-20" fxLayout=row fxLayoutAlign="end center">
              <button class="mr-20" mat-raised-button color="primary" (click)=pressedVerifyAll()> Verifizieren
              </button>
            </div>

            <h2 style="font-weight: bold;">Freiwilligenpasseinträge</h2>

            <div class="table">
              <mat-table #table *ngIf="tableDataSource.data.length > 0" [dataSource]="tableDataSource" matSort>
                <ng-container matColumnDef="taskName">
                  <mat-header-cell *matHeaderCellDef> Tätigkeit </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <span>{{element.name}} </span>

                  </mat-cell>

                </ng-container>

                <ng-container matColumnDef="taskType1">
                  <mat-header-cell *matHeaderCellDef> Typ </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <span>{{element.taskType1}} </span>

                  </mat-cell>

                </ng-container>

                <ng-container matColumnDef="taskType2">
                  <mat-header-cell *matHeaderCellDef> Untertyp </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.taskType2}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="taskDescription">
                  <mat-header-cell *matHeaderCellDef> Beschreibung </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
                </ng-container>

                <ng-container matColumnDef="taskDateFrom">
                  <mat-header-cell *matHeaderCellDef> Datum </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <span>{{element.dateFrom | date:'dd.MM.yyyy hh:mm'}} </span>

                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="taskDuration">
                  <mat-header-cell *matHeaderCellDef> Dauer </mat-header-cell>
                  <mat-cell *matCellDef="let element">

                    <span>{{element.duration}} h</span>

                  </mat-cell>
                </ng-container>


                <ng-container matColumnDef="hash">
                  <mat-header-cell *matHeaderCellDef> Hash </mat-header-cell>
                  <mat-cell *matCellDef="let element">
                    <span>{{element.hash}}</span>

                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="verificationStatus">
                  <mat-header-cell *matHeaderCellDef> Verifiziert </mat-header-cell>
                  <mat-cell *matCellDef="let element; let i = index">
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                      <div></div>
                      <div *ngIf="getVerifyState(i)" class="pl-4">
                        <i class="fas fa-check-circle" style="color:green;"></i>
                      </div>
                      <div></div>
                    </div>
                  </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
            </div>
            <mat-paginator [pageSize]="12" id="paginator" showFirstLastButtons></mat-paginator>


            <!-- <div class="no-data" *ngIf="tableDataSource.data.length === 0">
              Keine Einträge freigegeben!
            </div> -->

            <h2 style="font-weight: bold;">Freigegebene Statistiken</h2>

            <div style="display:flex;" flexLayout="row">
              <div *ngIf="weekdayData" style="display:inline-block; width: 600px; text-align: center; " class="mt-20">
                <span style="font-size: 18px;">Wochentag</span>
              </div>
              <div *ngIf="weekdayData" style="display:inline-block; width: 10%;" class="mt-20">
              </div>

              <div *ngIf="dayNightData" style="display:inline-block; width: 600px; text-align: center;" class="mt-20">
                <span style="font-size: 18px;">Tageszeit</span>
              </div>
            </div>
            <div style="display:flex;" flexLayout="row">
              <div *ngIf="weekdayData" class="mt-20" style="display:inline-block; width: 600px; background:#F4F4F4; border-radius: 10px;">
                <div class="donut-parent">
                  <ngx-charts-pie-chart class="chart-container" [view]="[550, 550]" [results]="weekdayData" [animations]="animations" [labels]="true" [tooltipDisabled]="false" [legend]="false" [explodeSlices]="false" [doughnut]="true" arcWidth="0.6" scheme="cool">
                  </ngx-charts-pie-chart>
                </div>
              </div>
              <div style="display:inline-block; width: 10%; background:#fff;">
              </div>

              <div *ngIf="dayNightData" class="mt-20" style="display:inline-block; width: 600px; background:#F4F4F4;  border-radius: 10px;">
                <div class="donut-parent">
                  <ngx-charts-pie-chart class="chart-container" [view]="[550, 550]" [results]="dayNightData" [animations]="animations" [labels]="true" [tooltipDisabled]="false" [legend]="false" [explodeSlices]="false" [doughnut]="true" arcWidth="0.6" scheme="cool">
                  </ngx-charts-pie-chart>
                </div>
              </div>
            </div>

            <div style="display:flex; flex-direction: column;" *ngIf="trainingData">
              <h2 class="title">STUNDEN absolvierter Ausbildungen</h2>
              <ngx-charts-bar-vertical-stacked [scheme]="colorScheme" [results]="trainingData" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [legendTitle]="legendTitle" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel1" [animations]="animations" (select)="onSelect($event)">
              </ngx-charts-bar-vertical-stacked>
            </div>
            <div class="mt-20"></div>
            <div style="display:flex; flex-direction: column;" flexLayout="column">
              <!-- <h2 class="title">Anpassungsfähigkeit</h2>
              <p style="margin-left: 20px; font-size: 18px; font-style: italic; color: #333;">"Fähigkeit, sich Menschen und Verhältnissen anzupassen"</p> -->

              <div class="chartParent2" fxLayout="column">
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <h2 class="title">Engagement in verschiedenen Tätigkeitsarten im Zeitverlauf</h2>
                </div>
                <ngx-charts-area-chart-stacked [scheme]="colorScheme" [results]="taskData" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [legendTitle]="legendTitle" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
                  [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel1" [animations]="animations">
                </ngx-charts-area-chart-stacked>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title fxLayout=row fxLayoutAlign="space-between center">
                <div fxLayout=row fxLayoutAlign="start start">
                  <img class="avatar" src="../../../../assets//images/avatars/Arnold.jpg">
                  <div style="padding-left: 10px"></div>
                  <div style="padding-top: 7px; font-size: 19px">Max Müller</div>
                </div>
                <div fxLayout=row fxLayoutAlign="end center">
                  <div fxLayout=column fxLayoutAlign="center center">
                    <mat-progress-bar style="width:250px" class="green-red-progress" value=85>
                    </mat-progress-bar>
                    <div>teilweise verifiziert</div>
                  </div>
                </div>

              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title fxLayout=row fxLayoutAlign="space-between center">
                <div fxLayout=row fxLayoutAlign="start start">
                  <img class="avatar" src="../../../../assets//images/avatars/Henderson.jpg">
                  <div style="padding-left: 10px"></div>
                  <div style="padding-top: 7px; font-size: 19px">Heinz Fissler</div>
                </div>
                <div fxLayout=row fxLayoutAlign="end center">
                  <!-- <div>Verified Status </div> -->
                  <div fxLayout=column fxLayoutAlign="center center">
                    <mat-progress-bar style="width:250px" class="green-red-progress" value=100>
                    </mat-progress-bar>
                    <div>vollständig verifiziert</div>
                  </div>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>