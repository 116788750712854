<div fxLayout="row" fxLayoutAlign="space-between start">
  <div class="sunburstParent" id="sunburstChart"></div>

  <div *ngIf="tableDataSource" class="tasksTable" fxLayout="column" fxLayoutAlign="none">
    <mat-table class="mat-elevation-z8" [dataSource]="tableDataSource" matSort>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Tätigkeit
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [matTooltip]="element.name.length > 25 ? 
        element.name : null">
          {{ element.name | truncate: [25] }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="taskType1">
        <mat-header-cell *matHeaderCellDef> Typ </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.taskType1 }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="taskType2">
        <mat-header-cell *matHeaderCellDef> Untertyp </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.taskType2 }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="taskDescription">
        <mat-header-cell *matHeaderCellDef> Beschreibung </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.description }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateFrom">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Datum
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.dateFrom | date: "dd.MM.yyyy hh:mm" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateTo">
        <mat-header-cell *matHeaderCellDef> Ende </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.dateTo | date: "dd.MM.yyyy hh:mm" }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="duration">
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          Dauer
        </mat-header-cell>
        <mat-cell class="right" mat-cell *matCellDef="let element">
          {{ element.duration | number:'1.2-2':'de-AT' }} h
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row (click)="openDialog(row)" *matRowDef="let element; let row; columns: displayedColumns" [ngStyle]="getStyle(element.taskType1)"></mat-row>
    </mat-table>
    <mat-paginator [pageSize]="16" showFirstLastButtons></mat-paginator>
  </div>
</div>

<div fxLayout="row">
  <app-share-menu [label]="'Zweck'">
  </app-share-menu>
</div>