"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var common_1 = require("@angular/common");
var dropbox_utils_1 = require("./dropbox-utils");
var dropbox_1 = require("dropbox");
var login_service_1 = require("app/main/content/_service/login.service");
var user_1 = require("app/main/content/_model/user");
var core_user_service_1 = require("app/main/content/_service/core-user.service");
var local_repository_jsonServer_service_1 = require("app/main/content/_service/local-repository-jsonServer.service");
var local_repository_dropbox_service_1 = require("app/main/content/_service/local-repository-dropbox.service");
var forms_1 = require("@angular/forms");
var nextcloud_credentials_1 = require("app/main/content/_model/nextcloud-credentials");
var local_repository_nextcloud_service_1 = require("app/main/content/_service/local-repository-nextcloud.service");
var environment_1 = require("environments/environment");
var dialog_factory_component_1 = require("../../_shared/dialogs/_dialog-factory/dialog-factory.component");
var LocalRepositoryLocationSwitchComponent = /** @class */ (function () {
    function LocalRepositoryLocationSwitchComponent(location, loginService, coreUserService, formBuilder, localRepoJsonServerService, localRepoDropboxService, localRepoNextcloudService, dialogFactory) {
        this.location = location;
        this.loginService = loginService;
        this.coreUserService = coreUserService;
        this.formBuilder = formBuilder;
        this.localRepoJsonServerService = localRepoJsonServerService;
        this.localRepoDropboxService = localRepoDropboxService;
        this.localRepoNextcloudService = localRepoNextcloudService;
        this.dialogFactory = dialogFactory;
        this.DROPBOX_CLIENT_ID = "ky4bainncqhjjn8";
        this.REDIRECT_URI = environment_1.environment.REDIRECT_URI;
        this.FILE_NAME = "db.json";
        this.isJsonServerConnected = false;
        this.isDropboxConnected = false;
        this.isNextcloudConnected = false;
        this.isLoaded = false;
    }
    LocalRepositoryLocationSwitchComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, dbx, dropboxToken, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loginService.getGlobalInfo().toPromise()];
                    case 1:
                        _a.globalInfo = (_d.sent());
                        this.user = this.globalInfo.user;
                        dbx = new dropbox_1.Dropbox({ clientId: this.DROPBOX_CLIENT_ID });
                        this.dropboxAuthUrl = dbx.getAuthenticationUrl(this.REDIRECT_URI);
                        if (this.user.dropboxToken && this.user.dropboxToken !== "undefined") {
                            this.isDropboxConnected = true;
                        }
                        else {
                            dropboxToken = dropbox_utils_1.DropboxUtils.getAccessTokenFromUrl();
                            this.isDropboxConnected = dropboxToken && dropboxToken !== "undefined";
                            if (this.isDropboxConnected && dropboxToken != this.user.dropboxToken) {
                                this.user.dropboxToken = dropboxToken;
                                this.updateUserAndGlobalInfo();
                            }
                        }
                        _b = this;
                        return [4 /*yield*/, this.localRepoNextcloudService.isConnected(this.user.nextcloudCredentials)];
                    case 2:
                        _b.isNextcloudConnected = _d.sent();
                        this.nextcloudLoginForm = this.formBuilder.group({
                            domain: new forms_1.FormControl("", forms_1.Validators.required),
                            username: new forms_1.FormControl("", forms_1.Validators.required),
                            password: new forms_1.FormControl("", forms_1.Validators.required),
                        });
                        this.nextcloudLoginError = false;
                        this.radioButtonForm = this.formBuilder.group({
                            radioButtonValue: new forms_1.FormControl(null, {
                                validators: forms_1.Validators.required,
                            }),
                        });
                        this.location.replaceState("/main/profile");
                        this.isLoaded = true;
                        _c = this;
                        return [4 /*yield*/, this.localRepoJsonServerService.isConnected()];
                    case 3:
                        _c.isJsonServerConnected = _d.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LocalRepositoryLocationSwitchComponent.prototype.localRepositoryLocationChange = function (newLocation) {
        var _this = this;
        if (this.user.localRepositoryLocation != newLocation) {
            var sourceService_1 = this.getService(this.user.localRepositoryLocation);
            var destService_1 = this.getService(newLocation);
            if (sourceService_1 == null) {
                // no location set yet
                this.user.localRepositoryLocation = newLocation;
                this.updateUserAndGlobalInfo();
            }
            else {
                this.dialogFactory
                    .confirmationDialog("Änderung bestätigen", "Soll der Speicherort wirklich gewechselt werden?")
                    .then(function (ret) { return __awaiter(_this, void 0, void 0, function () {
                    var classInstances, error_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!ret) return [3 /*break*/, 8];
                                if (!(sourceService_1 && destService_1)) return [3 /*break*/, 6];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 4, , 5]);
                                return [4 /*yield*/, sourceService_1
                                        .findClassInstancesByVolunteer(this.user)
                                        .toPromise()];
                            case 2:
                                classInstances = (_a.sent());
                                return [4 /*yield*/, destService_1
                                        .overrideClassInstances(this.user, classInstances)
                                        .toPromise()];
                            case 3:
                                _a.sent();
                                this.user.localRepositoryLocation = newLocation;
                                this.updateUserAndGlobalInfo();
                                return [3 /*break*/, 5];
                            case 4:
                                error_1 = _a.sent();
                                alert("Fehler bei der Datenübertragung!");
                                this.radioButtonForm.patchValue({
                                    radioButtonValue: this.user.localRepositoryLocation,
                                });
                                return [3 /*break*/, 5];
                            case 5: return [3 /*break*/, 7];
                            case 6:
                                alert("Fehler: Service nicht verfügbar!");
                                this.radioButtonForm.patchValue({
                                    radioButtonValue: this.user.localRepositoryLocation,
                                });
                                _a.label = 7;
                            case 7: return [3 /*break*/, 9];
                            case 8:
                                this.radioButtonForm.patchValue({
                                    radioButtonValue: this.user.localRepositoryLocation,
                                });
                                _a.label = 9;
                            case 9: return [2 /*return*/];
                        }
                    });
                }); });
            }
        }
    };
    LocalRepositoryLocationSwitchComponent.prototype.getService = function (localRepositoryLocation) {
        switch (localRepositoryLocation) {
            case user_1.LocalRepositoryLocation.LOCAL:
                return this.isJsonServerConnected
                    ? this.localRepoJsonServerService
                    : null;
            case user_1.LocalRepositoryLocation.DROPBOX:
                return this.isDropboxConnected ? this.localRepoDropboxService : null;
            case user_1.LocalRepositoryLocation.NEXTCLOUD:
                return this.isNextcloudConnected
                    ? this.localRepoNextcloudService
                    : null;
        }
    };
    LocalRepositoryLocationSwitchComponent.prototype.loginNextcloud = function () {
        return __awaiter(this, void 0, void 0, function () {
            var nextcloudCredentials, valid;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isNextcloudLoginInProgress = true;
                        if (!this.nextcloudLoginForm.valid) return [3 /*break*/, 2];
                        nextcloudCredentials = new nextcloud_credentials_1.NextcloudCredentials(this.nextcloudLoginForm.value.domain, this.nextcloudLoginForm.value.username, this.nextcloudLoginForm.value.password);
                        return [4 /*yield*/, this.localRepoNextcloudService.isConnected(nextcloudCredentials)];
                    case 1:
                        valid = _a.sent();
                        if (valid) {
                            this.user.nextcloudCredentials = nextcloudCredentials;
                            this.updateUserAndGlobalInfo();
                            this.isNextcloudConnected = true;
                        }
                        else {
                            this.nextcloudLoginError = true;
                        }
                        _a.label = 2;
                    case 2:
                        this.isNextcloudLoginInProgress = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LocalRepositoryLocationSwitchComponent.prototype.removeNextcloud = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.user.nextcloudCredentials = null;
                this.updateUserAndGlobalInfo();
                this.isNextcloudConnected = false;
                return [2 /*return*/];
            });
        });
    };
    LocalRepositoryLocationSwitchComponent.prototype.removeDropbox = function () {
        this.user.dropboxToken = null;
        this.updateUserAndGlobalInfo();
        this.isDropboxConnected = false;
    };
    LocalRepositoryLocationSwitchComponent.prototype.getChecked = function (localRepositoryLocation) {
        return this.user.localRepositoryLocation == localRepositoryLocation
            ? true
            : false;
    };
    LocalRepositoryLocationSwitchComponent.prototype.updateUserAndGlobalInfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.coreUserService.updateUser(this.user, true).toPromise()];
                    case 1:
                        _a.sent();
                        this.loginService.generateGlobalInfo(this.globalInfo.userRole, this.globalInfo.tenants.map(function (t) { return t.id; }));
                        return [2 /*return*/];
                }
            });
        });
    };
    return LocalRepositoryLocationSwitchComponent;
}());
exports.LocalRepositoryLocationSwitchComponent = LocalRepositoryLocationSwitchComponent;
