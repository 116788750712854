"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var confirmation_screen_component_1 = require("./confirmation-screen.component");
var routes = [{ path: "", component: confirmation_screen_component_1.HelpseekerConfirmationScreenComponent }];
var HelpseekerConfirmationScreenModule = /** @class */ (function () {
    function HelpseekerConfirmationScreenModule() {
    }
    return HelpseekerConfirmationScreenModule;
}());
exports.HelpseekerConfirmationScreenModule = HelpseekerConfirmationScreenModule;
