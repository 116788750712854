<div *ngIf="role === 'VOLUNTEER'" style="overflow: scroll; width: 100%;">
  <dashboard-volunteer></dashboard-volunteer>
</div>

<div
  *ngIf="role === 'HELP_SEEKER' || role === 'TENANT_ADMIN'"
  style="overflow: scroll; width: 100%;"
>
  <dashboard-helpseeker-tenantAdmin></dashboard-helpseeker-tenantAdmin>
</div>
