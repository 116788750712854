"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var login_service_1 = require("app/main/content/_service/login.service");
var stored_chart_service_1 = require("app/main/content/_service/stored-chart.service");
var stored_chart_1 = require("app/main/content/_model/stored-chart");
var CompetenciesComponent = /** @class */ (function () {
    function CompetenciesComponent(loginService, storedChartService) {
        this.loginService = loginService;
        this.storedChartService = storedChartService;
        // allData: any[];
        // currYearData: any[];
        // lastYearData: any[];
        // meanYearData: any[];
        // chart options
        this.colorScheme = "cool";
        this.showXAxis = true;
        this.showYAxis = true;
        this.gradient = false;
        this.showLegend = true;
        this.legendTitle = " ";
        this.showXAxisLabel = false;
        this.showYAxisLabel = true;
        this.yAxisLabel1 = "Stunden";
        this.yAxisLabel2 = "Anzahl";
        this.animations = true;
        this.tt1Lernbereitschaft = ["Ausbildung (aktiv)", "Probe"];
        this.dateLocale = new Intl.DateTimeFormat("de-AT", { month: "short" });
    }
    CompetenciesComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.loginService.getLoggedIn().toPromise()];
                    case 1:
                        _a.volunteer = (_b.sent());
                        return [2 /*return*/];
                }
            });
        });
    };
    CompetenciesComponent.prototype.ngOnChanges = function (changes) {
        // console.error('competencies', changes);
        for (var propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case "classInstanceDTOs": {
                        if (typeof changes.classInstanceDTOs.currentValue != "undefined") {
                            this.classInstanceDTOs = changes.classInstanceDTOs.currentValue;
                            this.classInstanceDTOs = this.classInstanceDTOs.sort(function (a, b) { return a.dateFrom.valueOf() - b.dateFrom.valueOf(); });
                            this.generateChartData();
                            // this.calcMean();
                            // this.currYearData = [... this.getYearData('2019')];
                            // this.lastYearData = this.getYearData('2018');
                            // this.meanYearData = this.getYearData('mean');
                        }
                        break;
                    }
                    case "selectedTenants": {
                        if (typeof changes.selectedTenants.currentValue != "undefined") {
                            this.selectedTenants = changes.selectedTenants.currentValue;
                        }
                    }
                }
            }
        }
    };
    CompetenciesComponent.prototype.onSelect = function (event) {
        console.log(event);
    };
    CompetenciesComponent.prototype.generateChartData = function () {
        var _this = this;
        var list = this.classInstanceDTOs.map(function (ci) {
            return {
                year: new Date(ci.dateFrom).getFullYear().toString(),
                month: _this.dateLocale.format(new Date(ci.dateFrom)),
                duration: ci.duration,
                tt1: ci.taskType1,
                tt2: ci.taskType2,
                tt3: ci.taskType3,
            };
        });
        // Ausbildung
        var filteredList = list.filter(function (entry) {
            return _this.tt1Lernbereitschaft.indexOf(entry.tt1) >= 0;
        });
        var uniqueYears = __spread(new Set(filteredList.map(function (item) { return item.year; })));
        var dataA1 = [];
        if (uniqueYears.length === 1) {
            // sort by month
            var uniqueMonths = [];
            for (var month = 0; month < 12; month++) {
                var d = new Date(Date.UTC(2000, month, 1, 0, 0, 0));
                uniqueMonths.push(this.dateLocale.format(d));
            }
            uniqueMonths.forEach(function (month) {
                var currentMonthList = filteredList.filter(function (entry) {
                    return entry.month == month;
                });
                var map = new Map(); // 0: number, 1: duration
                var data2 = [];
                currentMonthList.forEach(function (t) {
                    if (t.duration != null) {
                        if (map.get(t.tt2)) {
                            map.set(t.tt2, [
                                Number(map.get(t.tt2)[0]) + 1,
                                Number(map.get(t.tt2)[1]) + Number(t.duration),
                            ]);
                        }
                        else {
                            map.set(t.tt2, [1, Number(t.duration)]);
                        }
                    }
                });
                data2 = [];
                Array.from(map.entries()).forEach(function (entry) {
                    data2.push({
                        name: entry[0],
                        value: Number(entry[1][1]),
                        extra: { number: Number(entry[1][0]) },
                    });
                });
                dataA1.push({ name: month, series: data2 });
            });
        }
        else {
            uniqueYears.forEach(function (year) {
                var currentYearList = filteredList.filter(function (entry) {
                    return entry.year == year;
                });
                var map = new Map(); // 0: number, 1: duration
                var data2 = [];
                currentYearList.forEach(function (t) {
                    if (t.duration != null) {
                        if (map.get(t.tt2)) {
                            map.set(t.tt2, [
                                Number(map.get(t.tt2)[0]) + 1,
                                Number(map.get(t.tt2)[1]) + Number(t.duration),
                            ]);
                        }
                        else {
                            map.set(t.tt2, [1, Number(t.duration)]);
                        }
                    }
                });
                data2 = [];
                Array.from(map.entries()).forEach(function (entry) {
                    data2.push({
                        name: entry[0],
                        value: Number(entry[1][1]),
                        extra: { number: Number(entry[1][0]) },
                    });
                });
                dataA1.push({ name: year, series: data2 });
            });
        }
        for (var i = 0; i < dataA1.length; i++) {
            dataA1[i].series.sort(function (a, b) { return b.name.localeCompare(a.name); });
        }
        this.trainingData = __spread(dataA1);
        // /Ausbildung
        // Ausbildung2
        var dataA2 = [];
        var uniqueTt2 = __spread(new Set(filteredList.map(function (item) { return item.tt2; })));
        uniqueTt2.forEach(function (tt2) {
            var oneTt2 = list.filter(function (entry) {
                return entry.tt2 == tt2;
            });
            var map = new Map(); // 0: number, 1: duration
            var data2 = [];
            oneTt2.forEach(function (t) {
                if (t.duration != null) {
                    if (uniqueYears.length === 1) {
                        if (map.get(t.month)) {
                            map.set(t.month, [
                                Number(map.get(t.month)[0]) + 1,
                                map.get(t.month)[1] + Number(t.duration),
                            ]);
                        }
                        else {
                            map.set(t.month, [1, Number(t.duration)]);
                        }
                    }
                    else {
                        if (map.get(t.year)) {
                            map.set(t.year, [
                                Number(map.get(t.year)[0]) + 1,
                                map.get(t.year)[1] + Number(t.duration),
                            ]);
                        }
                        else {
                            map.set(t.year, [1, Number(t.duration)]);
                        }
                    }
                }
            });
            data2 = [];
            Array.from(map.entries()).forEach(function (entry) {
                data2.push({
                    name: entry[0],
                    value: Number(entry[1][0]),
                    extra: { duration: Number(entry[1][1]) },
                });
            });
            dataA2.push({ name: tt2, series: data2 });
        });
        this.trainingData2 = __spread(dataA2);
        // /Ausbildung2
        // Verschiedene Tätigkeiten (TT2)
        uniqueTt2 = __spread(new Set(list.map(function (item) { return item.tt2; })));
        var tt2Data = [];
        uniqueTt2.forEach(function (tt2) {
            var oneTt2 = list.filter(function (entry) {
                return entry.tt2 == tt2;
            });
            var map = new Map(); // 0: number, 1: duration
            var data2 = [];
            oneTt2.forEach(function (t) {
                if (t.duration != null) {
                    if (uniqueYears.length === 1) {
                        if (map.get(t.month)) {
                            map.set(t.month, [
                                Number(map.get(t.month)[0]) + 1,
                                map.get(t.month)[1] + Number(t.duration),
                            ]);
                        }
                        else {
                            map.set(t.month, [1, Number(t.duration)]);
                        }
                    }
                    else {
                        if (map.get(t.year)) {
                            map.set(t.year, [
                                Number(map.get(t.year)[0]) + 1,
                                map.get(t.year)[1] + Number(t.duration),
                            ]);
                        }
                        else {
                            map.set(t.year, [1, Number(t.duration)]);
                        }
                    }
                }
            });
            data2 = [];
            Array.from(map.entries()).forEach(function (entry) {
                data2.push({
                    name: entry[0],
                    value: Number(entry[1][1]),
                    extra: { number: Number(entry[1][0]) },
                });
            });
            tt2Data.push({ name: tt2, series: data2 });
        });
        this.taskData = __spread(tt2Data);
        // /Verschiedene Tätigkeiten (TT2)
    };
    CompetenciesComponent.prototype.exportChart = function (source) {
        var storedChart;
        switch (source) {
            case "Stunden":
                storedChart = new stored_chart_1.StoredChart("STUNDEN absolvierter Ausbildungen", "ngx-charts-bar-vertical-stacked", JSON.stringify(this.trainingData), this.volunteer.id);
                this.storedChartService.save(this.marketplace, storedChart).toPromise();
                break;
            case "Anzahl":
                storedChart = new stored_chart_1.StoredChart("ANZAHL absolvierter Ausbildungen", "ngx-charts-line-chart", JSON.stringify(this.trainingData2), this.volunteer.id);
                this.storedChartService.save(this.marketplace, storedChart).toPromise();
                break;
            case "Taetigkeitsart":
                storedChart = new stored_chart_1.StoredChart("Engagement in verschiedenen Tätigkeitsarten im Zeitverlauf", "ngx-charts-area-chart-stacked", JSON.stringify(this.taskData), this.volunteer.id);
                this.storedChartService.save(this.marketplace, storedChart).toPromise();
                break;
        }
    };
    return CompetenciesComponent;
}());
exports.CompetenciesComponent = CompetenciesComponent;
