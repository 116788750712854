"use strict";
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
Object.defineProperty(exports, "__esModule", { value: true });
var i0 = require("./app.component.scss.shim.ngstyle");
var i1 = require("@angular/core");
var i2 = require("./main/main.component.ngfactory");
var i3 = require("./main/content/_service/login.service");
var i4 = require("@angular/common/http");
var i5 = require("@angular/router");
var i6 = require("./main/content/_service/local-repository-dropbox.service");
var i7 = require("./main/content/_service/local-repository-jsonServer.service");
var i8 = require("./main/content/_service/local-repository-nextcloud.service");
var i9 = require("./main/main.component");
var i10 = require("../@fuse/services/config.service");
var i11 = require("@angular/cdk/platform");
var i12 = require("./main/content/_service/role-change.service");
var i13 = require("@angular/common");
var i14 = require("./app.component");
var i15 = require("@ngx-translate/core");
var i16 = require("../@fuse/components/navigation/navigation.service");
var i17 = require("../@fuse/services/splash-screen.service");
var i18 = require("../@fuse/services/translation-loader.service");
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
exports.RenderType_AppComponent = RenderType_AppComponent;
function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "fuse-main", [], [[1, "fuse-layout-mode", 0]], null, null, i2.View_FuseMainComponent_0, i2.RenderType_FuseMainComponent)), i1.ɵprd(512, null, i3.LoginService, i3.LoginService, [i4.HttpClient, i4.HttpClient, i5.Router, i6.LocalRepositoryDropboxService, i7.LocalRepositoryJsonServerService, i8.LocalRepositoryNextcloudService]), i1.ɵdid(2, 180224, null, 0, i9.FuseMainComponent, [i1.Renderer2, i1.ElementRef, i10.FuseConfigService, i3.LoginService, i11.Platform, i5.Router, i12.RoleChangeService, i13.DOCUMENT], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).layoutMode; _ck(_v, 0, 0, currVal_0); }); }
exports.View_AppComponent_0 = View_AppComponent_0;
function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fuse-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 49152, null, 0, i14.AppComponent, [i15.TranslateService, i16.FuseNavigationService, i17.FuseSplashScreenService, i18.FuseTranslationLoaderService], null, null)], null, null); }
exports.View_AppComponent_Host_0 = View_AppComponent_Host_0;
var AppComponentNgFactory = i1.ɵccf("fuse-root", i14.AppComponent, View_AppComponent_Host_0, {}, {}, []);
exports.AppComponentNgFactory = AppComponentNgFactory;
