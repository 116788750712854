"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var profile_component_1 = require("./profile.component");
var routes = [{ path: '', component: profile_component_1.ProfileComponent }];
var ProfileModule = /** @class */ (function () {
    function ProfileModule() {
    }
    return ProfileModule;
}());
exports.ProfileModule = ProfileModule;
