<mat-toolbar class="p-0 mat-elevation-z1 headingTopConfigurator">
  <mat-progress-bar
    *ngIf="showLoadingBar"
    class="loading-bar"
    color="accent"
    mode="indeterminate"
  ></mat-progress-bar>

  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button
        mat-icon-button
        class="toggle-button-navbar"
        *ngIf="!noNav"
        (click)="toggleSidebarOpened('navbar')"
        fxHide.gt-md
      >
        <mat-icon>menu</mat-icon>
      </button>

      <div class="toolbar-separator" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNav">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/logo.png" />
        </div>
      </div>

      <div class="px-8 px-mat-16" fxHide>
        <fuse-shortcuts
          *ngIf="navigation"
          [navigation]="navigation"
        ></fuse-shortcuts>
      </div>

      <div class="px-8 px-mat-16">
        <!-- <fuse-marketplace-selection></fuse-marketplace-selection> -->
        <div class="logo ml-16">
          <!-- <img class="logo-icon" src="assets/ebnerLogo.jpg">
          <div class="pl-12"></div> -->
          <h1 class="headingText">{{ headingText }}</h1>
        </div>
      </div>
    </div>

    <div #overlayArrow class="arrow-up" *ngIf="displayInboxOverlay"></div>
    <div
      #overlayDiv
      *ngIf="displayInboxOverlay"
      class="mat-elevation-z4"
      style="
        background: white;
        border-color: white;
        border-style: solid;
        border-width: 2px;
        border-radius: 5px;
        z-index: 2;
      "
    >
      <inbox-overlay (closeOverlay)="closeOverlay($event)"> </inbox-overlay>
    </div>

    <!-- DO NOT REMOVE; INBOX-FUNCTIONALITY... -->
    <!-- <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center" *ngIf="icons=='VOLUNTEER'">
        <mat-icon #inboxIcon class="mr-12" matBadge="!" matBadgePosition="before" matBadgeColor="warn"
        style="cursor: pointer" (click)="toggleInboxOverlay($event, inboxIcon)">inbox</mat-icon>
    </div> -->

    <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <app-role-menu></app-role-menu>
      <fuse-user-menu></fuse-user-menu>
    </div>
  </div>
</mat-toolbar>
