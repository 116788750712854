"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_tenant_service_1 = require("app/main/content/_service/core-tenant.service");
var tenant_1 = require("app/main/content/_model/tenant");
var util_1 = require("util");
var platform_browser_1 = require("@angular/platform-browser");
var file_service_1 = require("app/main/content/_service/file.service");
var TenantProfileImageUploadComponent = /** @class */ (function () {
    function TenantProfileImageUploadComponent(tenantService, fileService, sanitizer) {
        this.tenantService = tenantService;
        this.fileService = fileService;
        this.sanitizer = sanitizer;
        this.uploadedImage = new core_1.EventEmitter();
    }
    TenantProfileImageUploadComponent.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.loaded = false;
                        this.uploadingImage = false;
                        this.imageFileInput = undefined;
                        this.oldImage = undefined;
                        this.previewImage = undefined;
                        return [4 /*yield*/, this.initialize(this.tenant)];
                    case 1:
                        _a.sent();
                        this.loaded = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    TenantProfileImageUploadComponent.prototype.initialize = function (tenant) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (util_1.isNullOrUndefined(tenant)) {
                    return [2 /*return*/];
                }
                this.previewImage = this.tenant.imagePath;
                this.oldImage = this.previewImage;
                return [2 /*return*/];
            });
        });
    };
    TenantProfileImageUploadComponent.prototype.uploadImage = function () {
        var _this = this;
        this.uploadingImage = true;
        var fileReader = new FileReader();
        fileReader.onload = function (e) { return __awaiter(_this, void 0, void 0, function () {
            var image, payload;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        image = fileReader.result;
                        this.uploadingImage = false;
                        return [4 /*yield*/, this.fileService
                                .uploadFile(this.imageFileInput.files[0])
                                .toPromise()];
                    case 1:
                        payload = _a.sent();
                        this.previewImage = payload.message;
                        this.uploadedImage.emit({
                            key: "uploaded",
                            url: payload.message
                        });
                        return [2 /*return*/];
                }
            });
        }); };
        fileReader.readAsBinaryString(this.imageFileInput.files[0]);
    };
    TenantProfileImageUploadComponent.prototype.deleteImage = function () {
        this.imageFileInput = undefined;
        this.previewImage = undefined;
        this.uploadedImage.emit({ key: "clear", url: "" });
    };
    TenantProfileImageUploadComponent.prototype.revertImage = function () {
        this.imageFileInput = undefined;
        this.uploadingImage = false;
        this.previewImage = this.oldImage;
        this.uploadedImage.emit({
            key: "reverted",
            url: this.oldImage
        });
    };
    return TenantProfileImageUploadComponent;
}());
exports.TenantProfileImageUploadComponent = TenantProfileImageUploadComponent;
